import { Outlet, Link, router, useParams } from "react-router-dom";
export default function UsedWarning(order) {
  return (
    <>
      <div
        dir="rtl"
        className="row mt-0 text-center0 m-auto "
       
      >
        <div className="col-1 py-1 bg-light ">
          <Link to="../Customer/Setting">
            <i class="fa-solid fa-chevron-right  mt-2 fs-4"></i>
          </Link>
        </div>
        <div className="col-11 py-1 text-center bg-light">
          <h4>{" شروظ الاستخدام "}</h4>
        </div>
        <br />

        <div className="col-12 mt-0">
          <h1>ضوابط وشروط وأحكام المستخدم</h1>
          <b>
            تمثل هذه الضوابط والشروط اتفاق رسمي "عقد" بين مؤسسة  جى سيو اكسبريس  لخدمة
            الاتصالات وتقنية المعلومات المالك للعلامة التجارية  جى سيو اكسبريس  " جى سيو اكسبريس "
            ومقدمي خدمات التوصيل (المندوبين) وتحكم شروط مقدم الخدمة هذه
            استخدامكم لتطبيق  جى سيو اكسبريس  والذي هو عبارة عن منصة إلكترونية تسمح
            للمندوبين بتقديم خدمات الشراء نيابة عن العملاء والتوصيل إلى منازلهم
            أو في المواقع التي يتم تحديدها مسبقاً من العملاء، حيث يقوم  جى سيو اكسبريس {" "}
            بإتاحة الفرصة للعميل لاختيار المندوب المناسب للقيام بعملية التوصيل.
            إن اي استخدام من قبلك للخدمات التي يوفرها تطبيق  جى سيو اكسبريس  يشكل موافقة منك
            على هذا العقد وأحكامه وتبعاً لذلك يجب عليك عدم استخدام التطبيق في
            حال لم تكن موافقاً على الأحكام والشروط الواردة في هذا العقد. تحتفظ
             جى سيو اكسبريس  بحق تعديل او تغيير هذه الأحكام والشروط دون إخطار مسبق , ويكون
            من مسؤوليتك كمستخدم للتطبيق مراجعة ضوابط وشروط الاستخدام بشكل دوري
            لمعرفة التحديثات التي تطرأ على الأحكام والشروط هذه, من خلال الرابط{" "}
            {"https://www.j-sio.com"} كما نأمل مراجعة سياسة الخصوصية لدينا
            لمعرفة المزيد حول كيفية استخدام  جى سيو اكسبريس  للمعومات التي تقدم لنا من قبل
            مستخدمي التطبيق.
          </b>
          <h1>أحقية الحصول على الخدمات</h1>
          <b>
            أنت تقر وتضمن التالي: أنه لم يسبق أن تم تعطيل استخدامك لخدمات
            " جى سيو اكسبريس " أو منعك من استخدامها في اي وقت من الأوقات. أنك لست منافساً
            ل” جى سيو اكسبريس ”, كما أنك لا تقدم اي منتج منافس للخدمات المقدمة من  جى سيو اكسبريس . أنك
            تتمتع بكامل القوة والسلطة للتعاقد وانك بذلك لن تكون منتهكاً لأي
            قانون أو عقد. أنك طرف مستقل ولا تعمل كوكيل أو ممثل أو موزع أو شريك
            للشركة. لا يجوز لك، بشكل مباشر أو غير مباشر، تقديم نفسك كممثل لشركة
             جى سيو اكسبريس  أو التصرف بطريقة قد تحمل أو تنشئ التزامات دون موافقة كتابية
            مسبقة من  جى سيو اكسبريس . إذا تم منح الموافقة، حدود تصرفك تقتصر على الأعمال
            التي تسمح لك بتنفيذ الخدمة المقدمة فقط. كما أنه ليس لك أي حق في
            تقديم أي عروض للمستخدمين نيابة عن  جى سيو اكسبريس ، وأنت تقر بأن خرقك لهذا
            البند سوف يجعلك مسؤولا مسؤولية كاملة عن جميع الالتزامات الناشئة عن
            هذا الخرق ويعرضك للملاحقة الجنائية وحظر استخدامك للمنصة.
          </b>
          <h1>التعهدات والضمانات</h1>
          <b>
            أنت تقر وتضمن بأنك سوف: تمتثل لكافة القوانين واللوائح المعمول بها في
            جمهورية مصر العربية. تقدم معلومات صحيحة ودقيقة إلى  جى سيو اكسبريس  وتقوم
            بتحديثها بشكل دوري. تراجع وتمتثل لأي إشعارات يتم إرسالها من خلال{" "}
             جى سيو اكسبريس  فيما يتعلق باستخدامكم للخدمة المقدمة من تطبيق  جى سيو اكسبريس . لن تقوم
            بتكرار تراخيص من الباطن, أو إصدار,أو نشر, أو نقل, أو توزيع, أو
            تنفيذ, أو عرض, أو بيع, أو إعادة تصنيف خدمات  جى سيو اكسبريس , والا فإنك تكون قد
            نقلت الخدمة أو قمت باستغلالها تجارياً, باستثناء مايسمح به بموجب هذا
            العقد. لن تستخدم المعلومات، أو المحتوى أو أي بيانات تصل إليها أو
            تحصل عليها من خلال خدمات  جى سيو اكسبريس  في أي غرض آخر إلا للاستعمال الشخصي
            وسوف تستخدم التطبيق والخدمة حصراً للأغراض الخاصة بك ولن تبيعها لأي
            طرف ثالث (بما في ذلك على سبيل المثال لا الحصر تقديم أي خدمة إلى أي
            شخص آخر). سوف تستخدم الخدمة أو التطبيق لأغراض مشروعة فقط، ولن تستخدم
            الخدمات لإرسال أو تخزين أي مواد غير قانونية أو بهدف الاحتيال. تلتزم
            باعتماد معايير الحفاظ على سلامة المنتجات التي يتم نقلها عبر مركبتك
            أثناء التوصيل بحيث يتم توصيل الطلبات وهي في حالة صحية سليمة. لن
            تستخدم الخدمة أو التطبيق للتسبب بإيذاء أو مضايقة أو إزعاج أحد ما. لن
            تعرقل التشغيل السليم لتطبيق  جى سيو اكسبريس . لن تحاول إلحاق الضرر بالخدمة أو
            التطبيق بأي شكل من الأشكال. لن تنسخ أو توزع التطبيق أو المحتويات
            الأخرى دون الحصول على إذن كتابي من  جى سيو اكسبريس . سوف تحافظ على كلمة المرور
            لحسابك أو أي وسيلة تعريف نقدمها لك وتتيح الدخول إلى حسابك، بشكلٍ آمن
            وسري. سوف تقدم لنا كافة الدلائل التي تثبت هويتك وفقاً لتقدير  جى سيو اكسبريس {" "}
            الخاص. سوف تستخدم فقط أجهزة الاتصال اللاسلكي بالإنترنت أو حساب
            بيانات الجيل الثالث ( AP ) المصرح لك باستخدامهما . يحق ل” جى سيو اكسبريس ” رفض
            تقديم الخدمة أو استخدام التطبيق دون إبداء أسباب.
          </b>
          <h1>نطاق الترخيص</h1>
          <b>
            تمنحك " جى سيو اكسبريس " ترخيص غير حصري، وغير قابل للتحويل، وغير قابل للتنازل،
            وغير قابل للترخيص من الباطن وغير قابل للإلغاء وذلك للحصول على
            خدماتنا واستخدامها فقط للغرض الشخصي (ليس لأي أغراض تجارية على سبيل
            المثال لا الحصر) وفقاً لهذا العقد، لذا يجب عليك: عدم إتاحة الخدمات
            أوتأجير، أواستئجار أوتخصيص أوإعادة بيع، أو توزيع أو ترخيص من الباطن
            لهذه الخدمات إلى أي طرف ثالث. عدم تعديل، أو اجتزاء، أو ترجمة، أو
            تلخيص، أو إنشاء عمل فرعي يقوم على فك، أو تنفيذ هندسة عكسية لتصميم
            تطبيق  جى سيو اكسبريس  أو خلاف ذلك من تحديد أو محاولة تحديد أو محاولة الوصول إلى
            شفرة المصدر أو التصميم الداخلي للخدمات أو أي نص، أو صور الوسائط
            المتعددة (صور، صوتيات، ملفات فيديو)، أو البيانات أو غيرها من
            المعلومات التي تقدمها  جى سيو اكسبريس  أو الطرف الثالث من مقدمي الخدمة. عدم حذف،
            أو تغيير، أو غير ذلك من التعديل على أي حق من حقوق النشر أو غيرها من
            الإخطارات الرسمية الواردة في الخدمات. عدم تعمد نقل، أو توزيع
            الخدمات، أو السماح بتحميل الخدمات للاستخدام بخلاف ما هو محدد هنا.
            عدم الإدعاء بمنح الخدمات أو السماح بالوصول إليها لأي شخص غيرك. عدم
            السماح بمشاركة اسم المستخدم/ أو كلمة المرور أو غيرها من مسوغات
            الوصول إلى خدمات  جى سيو اكسبريس . وكجزء من سياسة الشحن الخاصة بخدمة  جى سيو اكسبريس  يحظر
            على المستخدمين والمندوبين شراء أو توصيل أو إرسال أو تلقي أو استلام
            الفئات التالية من المواد والمنتجات وما يتعلق بها، ويجب على اللشركة
            التأكد من سلامة أي ارسالية يقوم بها من خلال المنصة. الفئات
          </b>
          <h1>أمثلة للمنتجات والأصناف</h1>
          <b>
            الكحول المشروبات الروحية والمسكرة، النبيذ، الشمبانيا، البيرة وغيرها.
            الحيوانات والأجناس المحظورة أجزاء الحيوانات، الدماء أو السوائل
            الأخرى، الأعشاب الضارة، البذور المحظورة، النبات وأي مواد عضوية أخرى
            (تشمل المشتقات الإنتاجية) المعرضة لخطر الانقراض أو التي يمنع الاتجار
            فيها بموجب القانون. المواد الإباحية المواد الإباحية أو تلك التي من
            الممكن أن تشكل استغلالاً جنسياً للأطفال. المواد والتطبيقات المحمية
            بحقوق ملكية النسخ غير القانونية من الكتب والموسيقى والأفلام واي مواد
            أخرى مرخصة ومحمية بما في ذلك النسخ التي تتعدى على حقوق الملكية
            والنسخ غير القانونية من التطبيقات والبرامج وألعاب الفيديو وأي مواد
            أخرى مرخصة ومحمية مثل التطبيقات الأصلية أو البرامج الحزمية أو
            المنتجات الأخرى التي تمكن من إرسال رسائل مجهولة المصدر إلى البريد
            الإلكتروني. السلع المقلدة وغير القانونية السلع أو التصميمات المقلدة،
            المواد غير الموقعة من المشاهير والتي تحتاج في العادة إلى مثل هذا
            التوقيع، التوقيعات المزورة، العملات والطوابع وغيرها من السلع التي قد
            تكون غير قانونية. الأجهزة والآليات المستخدمة في فك أو اختراق الحماية
            التقنية أي شرائح أو أجهزة أخرى تستخدم لتجاوز الحماية التقنية على
            الأجهزة الرقيمة بما في ذلك أجهزة فك شفرات هواتف الآيفون. المخدرات
            ومعداتها المواد المحظورة والمخدرات والأدوية غير القانونية ومعدات
            التحضير بما في ذلك الحبوب العشبية مثل السالفيا والفطر السحري والمواد
            التي تروج لاستخدام مثل هذه المنتجات؛ أو المواد القانونية مثل
            النباتات والأعشاب بطريقة تقتضي هضمها أو استنشاقها او استخلاص أي مواد
            غير قانونية منها تؤدي نفس أثر المخدرات أو المواد غير القانونية أو
            التي تدعي تحقيق فوائد صحية دون إثبات أو برهان. الألعاب والقمار تذاكر
            اليانصيب والمراهنات الرياضية وعضويات مواقع القمار على الشبكة وأي
            محتوى متعلق بذلك. مواد القرصنة الإلكترونية الأدلة والتوجيهات
            والمعلومات والمتطلبات التي تخالف القانون من خلال التخريب أو السماح
            بالوصول غير القانوني إلى التطبيقات والبرامج والأجهزة الخادمة ومواقع
            الإنترنت أو أي ممتلكات أخرى محمية. الأعضاء البشرية الأعضاء البشرية
            أو أي أجزاء من الجسم والسوائل والخلايا الجذعية والأجنة. السلع غير
            القانونية والمسروقة المواد والمنتجات والمعلومات التي تروج لسلع غير
            قانونية أو تمكن من تنفيذ أفعال غير قانونية؛ السلع التي لا تملكها أو
            ليس لك الحق في بيعها؛ السلع التي يتم إنتاجها بالتعدي على حقوق ملكية
            لجهات أخرى؛ السلع المهربة والسلع التي تخالف أنظمة الاستيراد والتصدير
            والديباجات؛ السيارات الخاضعة لقيود النقل؛ السلع المسجلة في السجلات
            العامة (مثل العقارات) والتي يتطلب نقلها إجراءات رسمية لا يمكن
            إنجازها على الشبكة. وتكون أنت والعميل مسؤولين مسؤولية كاملة عن
            التحقق من أن جميع المواد التي تقومون ببيعها قانونية ومصرح بها. معدات
            الاتصالات غير القانونية الأجهزة المعدة لالتقاط إشارات الأقمار
            الصناعية والكابلات مجاناً وأجهزة فك شفرة البث الكابلي وبطاقات
            التشفير وأجهزة برمجة بطاقات التشفير والمعدات والمنتجات غير القانونية
            التي تستخدم في تعديل الهواتف الخليوية وغيرها من المعدات التي تعتبر
            غير قانونية بموجب أنظمة هيئة الاتصالات أو أي جهة منظمة في البلد التي
            تعرض فيها تلك السلع. الأدوية الخارقة العلاجات والأدوية غير المثبتة
            أو المواد التي يتم تسويقها على أنها علاج سريع وناجع للمشكلات الصحية
          </b>
          <h1>السلع غير اللائقة</h1>
          <b>
            السلع والمواد الأدبية والمنتجات أو أي مواد أخرى تعمل على: ● تشويه
            السمعة والتشهير بأي شخص أو مجموعة من الأشخاص على أساس العرق أو البلد
            أو الدين أو النوع أو أي عامل آخر. ● تشويه السمعة والتشهير بأي شخص أو
            مجموعة أشخاص محمية من التشهير وتشويه السمعة بموجب القانون السائد
            (مثل الحماية المتوفرة للأسر الحاكمة في بعض المناطق). ● التحريض على
            أو تشجيع الأفعال العنيفة. ● الترويج للتعصب والكراهية. ● الترويج
            للانتماء إلى المجموعات الإرهابية أو أي منظمات أخرى محظورة بموجب
            القانون. ● الترويج للنظريات التعديلية المحددة بموجب القانون. ● تنافي
            الضوابط الأخلاقية السائدة. السلع العدوانية والإجرامية المشاهد والصور
            والمواد الإجرامية مثل المتعلقات الشخصية المرتبطة بالجرائم أو الأفعال
            الإجرامية. المواد الثمينة البيع بكميات كبيرة للمعادن والأحجار
            النادرة أو الثمينة. المواد الثقافية والمشغولات اليدوية المحمية
            المواد التي يشملها ميثاق اليونسكو لعام 1970م بخصوص وسائل منع وحماية
            الاستيراد والتصدير والنقل غير المشروع لملكية الممتلكات الثقافية أو
            الممنوع بيعها أو تصديرها أو نقل ملكيتها بموجب القانون؛ المشغولات
            اليدوية والتكوينات الكهفية والمواد المتعلقة بالمدافن والمحمية بموجب
            القانون الفيدرالي مثل قانون حماية الموارد الكهفية لعام 1988م وقانون
            حماية المدافن الأمريكية. أجهزة الألعاب النارية والمواد الخطرة
            الألعاب النارية والسلع المتعلقة بها التي تعتبر في الأسواق التي تباع
            فيها: سامة، قابلة للاشتعال أومشعة ومواد مثل البارود والمواد المتفجرة
            والبنزين وعبوات البروبان. السلع الخاضعة لضوابط معينة الأكياس
            الهوائية؛ البطاريات التي تحتوي على الزئبق؛ الفيرون ومواد التبريد
            المماثلة؛ المذيبات الكيماوية والصناعية؛ العمليات الطبية؛ لوحات أرقام
            السيارات؛ ديباجات الشرطة ومعدات سلطات تطبيق القانون؛ أجهزة فتح
            الأقفال؛ الأجهزة الطبية؛ المبيدات الحشرية؛ معدات اللياقة والمحفزات
            الكهربائية؛ المواد المسترجعة؛ معدات التفتيش والمراقبة المستخدمة بشكل
            أساسي للتنصت على المكالمات الشهفية أو الإلكترونية بشكل غير قانوني أو
            التمكين من التنصت غير القانوني على الأشخاص وتسجيل مكالماتهم؛ السلع
            الخاضعة للرقابة من قبل الحكومة أو أي سلطات أخرى. أجهزة السلطات
            المرورية أجهزة التشويش على الرادار، أغطية لوحات السيارات، أجهزة
            تغيير إشارات المرور وغيرها من المنتجات ذات الصلة. الأسلحة الأسلحة،
            الذخيرة وأي مواد أخرى تشمل، دون حصر، الأسلحة والسكاكين المخفاة وصعبة
            الكشف والأسلحة التي تأخذ شكل قطع فنية وكاتمات الصوت وخزائن الذخيرة
            والأسلحة الرشاشة الخفيفة والغاز المسيل للدموع. البيع الإجمالي
            للعملات العملات المخفضة أو تداول العملات والعملات المدعومة بالمعادن
            الثمينة. التوصيل للأطفال في سن الدراسة الابتدائية أو المتوسطة أو دون
            ذلك يكون لدينا الحق في رفض تقديم الخدمة لجميع الأطفال دون الثامنة
            عشرة. وإضافةً إلى ذلك يكون لدينا الحق في رفض التوصيل إلى أي موقع
            داخل أو حول المدارس الابتدائية أو المتوسطة.
          </b>
          <h1>سوء استخدام المنصة البرمجية</h1>
          <b>
            نرفض بشكل كامل أي لغة مسيئة أو سلوك سيء تجاه  جى سيو اكسبريس  و/أو الخدمة التي
            نقدمها و/أو أيٍ من موظفينا و/أو أيٍ من المراسيل العاملين معنا. إذا
            تقرر بناءً على تقديرنا استخدام أي عميل للغة مسيئة للشركة و/أو الخدمة
            و/أو الموظفين و/أو المراسيل فسيؤدي ذلك إلى إغلاق حساب العميل بشكل
            نهائي دون رد أي مبالغ مستحقة. ويعتبرهذا تهديد بالمقاضاة أو التشهير
            أو القدح.
          </b>
          <h1> خرق سياسة الشحن:</h1>
          <b>
            تحتفظ  جى سيو اكسبريس  بالحق في السعي لتنفيذ أي معالجات متاحة لأي خرق لسياسة
            الشحن ويشمل ذلك، دون حصر، الحق في حجب إمكانية الوصول إلى خدمات
            وبرامج  جى سيو اكسبريس .
          </b>
          <h1>حقوق الملكية الفكرية</h1>
          <b>
            جميع حقوق الملكية الفكرية لهذه الخدمات وكافة المواد المتعلقة بها أو
            التي تظهر عليها (وتشمل أي محتوى تقوم بتقديمه أو بإدراجه - على سبيل
            المثال لا الحصر) هو ملكية ل” جى سيو اكسبريس ”. يجب عليك ألا تقوم بإعادة إنتاج
            أو السماح لأي شخص، لأي سبب من الأسباب، باستخدام أو إعادة إنتاج
            الخدمات أو أي علامات تجارية أو غيرها من الأسماء التجارية التي تظهر
            في الخدمات.
          </b>
          <h1>الدفع</h1>
          <b>
            تحتفظ  جى سيو اكسبريس  لنفسها بالحق في فرض رسوم جديدة على استخدام التطبيق أو
            الخدمة أو كليهما. إذا قررت  جى سيو اكسبريس  فرض رسوم جديدة أو نسبة عمولات، فإنه
            سيتم إفادتك بذلك وسيتم السماح لك بالاستمرار في العقد أو إنهائه. تقوم
            " جى سيو اكسبريس " بتحديد نسبة العمولة الخاصة ب" جى سيو اكسبريس " ويتم تحصيلها من المندوب
            وفق ما يتطلبه السوق، ويكون من حق المندوب طلب عدم الاستمرار في حال
            عدم مناسبة النسبة المقدمة من " جى سيو اكسبريس "، ويعد استمراره بعد إخطاره
            بالنسبة الجديدة موافقة صريحة منه على النسبة الجديدة. يجوز ل” جى سيو اكسبريس ”
            تعديل أو تحديث الأسعار من وقت لآخر، ويقع على عاتقك مسؤولية البقاء
            على معرفة بالنسب الحالية لخدمة التوصيل. يتوجب عليك استلام قيمة خدمة
            التوصيل من العميل النهائي نقداً، وفور تقديم الخدمة، وتتحمل وحدك
            مسؤولية عدم استلام قيمة خدمة التوصيل، وإنك تقر بأن تدفع ل" جى سيو اكسبريس "
            نسبته المتفق عليها من كل عملية توصيل تقوم بها. كما يتوجب عليك القبول
            بأي كوبون عندما يقوم العميل باستخدامه في الطلب. وسوف تقوم  جى سيو اكسبريس 
            بتعويضك عن قيمة الكوبون كماهو موضح في الطلب. تقر وتوافق بأن تلتزم
            بالأمانة دائماً فيما يتعلق بقيمة وحقيقة العمليات وخلافه، وتوافق بأنه
            في حال اكتشاف أي تحايل أو تلاعب أو مخادعة أو اساءة تقديم الخدمة
            للعميل بمايسئ للتطبيق بشكل مباشر أو غير مباشر، فإنك ستكون عرضة
            لإيقاف الخدمات الخاصة بك وحجبها عن تطبيق  جى سيو اكسبريس ، بالإضافة إلى حق  جى سيو اكسبريس {" "}
            في تحصيل غرامة إساءة استخدام تبلغ 1000 جنيه مصري، بالإضافة إلى حق{" "}
             جى سيو اكسبريس  بالمطالبة بالتعويضات عن كافة الأضرار التي نتجت أو ستنتج نتيجة
            لهذا التحايل والتلاعب أو سوء الاستخدام. التعويض
          </b>
          <h1></h1>
          <b>
            بموافقتك على شروط مقدم الخدمة الماثلة واستخدام التطبيق أو الخدمة،
            فإنك توافق على الدفاع عن  جى سيو اكسبريس  والشركات التابعة لها والجهات المرخِّصة
            لها وكل مسؤول من مسؤوليها ومديريها وغيرهم من المستخدمين والموظفين
            والمحامين والوكلاء وعدم إلحاق الضرر بهم وإبراء ذمتهم من أي مطالبات
            وتكاليف وأضرار وخسائر ومسؤوليات ومصروفات (بما في ذلك أتعاب وتكاليف
            المحاماة على سبيل المثال لا الحصر) تنشأ عن أو ترتبط بما يلي: انتهاكك
            أو مخالفتك لأي شرط من شروط مقدم الخدمة الماثلة هذه أو لأي قانون أو
            لوائح معمول بها، سواء أشير إليها في شروط وأحكام الاستخدام هذه أم لا.
            انتهاكك لأي حقوق تخص الغير، بما في ذلك مُقدمي خدمة التوصيل الذين
            ينظمهم التطبيق. استخدامك أو إساءة استخدامك للتطبيق أو الخدمة.
          </b>
          <h1>المسؤولية القانونية</h1>
          <b>
            المعلومات والتوصيات والخدمات أو أي منها التي قُدمت لك على أو من خلال
            موقع الويب والخدمة والتطبيق هي لأغراض المعلومات العامة فقط ولا تمثل
            أي نصيحة. ستحافظ  جى سيو اكسبريس  قدر الإمكان على صحة وتحديث الموقع والتطبيق
            ومحتوياته، لكنها لا تضمن أن (محتويات) الموقع أو التطبيق خالية من
            الأخطاء والعيوب والبرامج الضارة والفيروسات ولا تضمن صحة ودقة وتحديث
            موقع الويب والتطبيق. لا تتحمل  جى سيو اكسبريس  المسؤولية عن أي أضرار تنتج عن
            استخدام (أو عدم القدرة على استخدام) الموقع أو التطبيق، بما في ذلك
            الأضرار التي تسببها البرامج الضارة أو الفيروسات، كما لا تتحمل
            مسؤولية عدم صحة أو عدم اكتمال المعلومات أو موقع الويب أو التطبيق، ما
            لم يكن هذا الضرر ناتج عن سوء سلوك عمدي أو عن إهمال جسيم من جانب
             جى سيو اكسبريس . لا تتحمل  جى سيو اكسبريس  أي مسؤولية عن أي أضرار تنتج عن استخدام (أو عدم
            القدرة على استخدام) وسائل اتصال إلكترونية مع الموقع الإلكتروني أو مع
            التطبيق، بما في ذلك - على سبيل المثال لا الحصر - الأضرار الناتجة عن
            عدم تسليم المكاتبات الإلكترونية أو التأخر في تسليمها أو اعتراضها أو
            التلاعب في المكاتبات الإلكترونية من جانب الغير أو من جانب برامج
            الكمبيوتر المستخدمة للمكاتبات الإلكترونية ونقل الفيروسات. تقع
            مسؤولية جودة خدمة التوصيل المطلوبة باستخدام التطبيق أو الخدمة
            بأكملها على عاتقك، ولا يتحمل " جى سيو اكسبريس " أي مسؤولية في هذا الجانب، على
            أن من حق " جى سيو اكسبريس " مراجعة أدائك وبناءً عليه إلغاء ترخيصك. لا تقبل
             جى سيو اكسبريس  تحت أي ظرف من الظروف أي مسؤولية تتعلق بخدمات التوصيل التي
            يقدمها مقدم الخدمة أو تنشأ عنها.  جى سيو اكسبريس  لا تقبل المسؤولية عن أي أفعال
            أو تصرفات أو سلوك أو إهمال، أو جميع ما سبق، من جانب مقدم خدمة
            التوصيل. إن أي شكاوى بشأن خدمات التوصيل تقدم من قبل العملاء، ينبغي
            تقديمها لـ" جى سيو اكسبريس " بشكل مباشر، على أن " جى سيو اكسبريس " لا يتحمل أي مسؤولية في
            هذا الجانب، ويكون فقط حلقة وصل بين الطرفين.
          </b>
          <h1>تعديل الخدمات:</h1>
          <b>
            تحتفظ  جى سيو اكسبريس  بحقها، وبإرادتها المنفردة، بأن تقوم في أي وقت بالآتي:
            تغيير الخدمات أو أي مواد مرتبطة بها؛ و/ أو إيقاف نشر خدماتها. إذا
            قررت  جى سيو اكسبريس  إيقاف نشر خدماتها، فإنها قد تقوم بإرادتها باستبدال الخدمات
            بمواد أخرى مشابهة.
          </b>
          <h1>حدود المسؤولية:</h1>
          <b>
            لا تقدم  جى سيو اكسبريس  أي ضمانات، واضحة أو ضمنية، تتعلق بالخدمات، فهي جميعها
            يتم تقديمها "كما هي". تخلي  جى سيو اكسبريس  مسئوليتها بشكل واضح، إلى أقصى درجة
            ممكنة وفقاً للقانون، عن كافة الضمانات، وتشمل ولا تقتصر على، ضمانات
            الملائمة لغرض معين، الملائمة لمواصفات وأسواق معينة، وقابلية حقوق
            الملكية للبيع.  جى سيو اكسبريس  لا تضمن دقة، أو محتوى، أو توقيت الخدمات أو
            النتائج التي قد ينالها أو لا ينالها مستخدم الخدمات. ولن تكون  جى سيو اكسبريس  أو
            وكلائها أو مانحي التراخيص أو الشركات التابعة لها مسئولة في أي حال من
            الأحوال عن الأضرار المباشرة أو غير المباشرة أو الجزاءات أو الأضرار
            الخاصة أو حدوث أضرار عرضية أو تبعية (وتشمل على سبيل المثال لا الحصر،
            الأضرار الناتجة عن خسارة الأرباح، تعطل الأعمال، فقدان معلومات
            الأعمال أو غيرها من الخسائر المالية) الناجمة بشكل مباشر أو غير مباشر
            عن الوصول إلى الخدمات واستخدامها (أو الفشل في استخدامها) أو الاعتماد
            عليها.
          </b>
          <h1>الأمان:</h1>
          <b>
            إنك تقر بتحملك المسئولية منفرداً عن خصوصية الخدمات، وتكون مسئولاً
            منفرداً عن استخدامها من قبل أي شخص آخر باستخدام حسابك و/أو اسم
            المستخدم أو كلمة المرور أو مسوغات الوصول الخاصة بك. كما أنك توافق
            على إخطار  جى سيو اكسبريس  إذا أصبحت على علم بأي خسارة، أو سرقة، أو استخدام غير
            مصرح به لأي كلمة مرور، أو اسم مستخدم أو عنوان بروتكول الانترنت IP،
            أو غير ذلك من أساليب الوصول إلى الخدمات.
          </b>
          <h1>التعاملات مع الغير</h1>
          <b>
            استخدام موقع الويب والتطبيق والخدمة، قد يتم من آن لآخر توفير
            ارتباطات لمواقع ويب يمتلكها ويتحكم فيها الغير من أجل مراسلة الغير أو
            شراء منتجات أو خدمات منه أو المشاركة في العروض الترويجية التي
            يقدمها. تنتقل بك تلك الارتباطات إلى خارج الموقع والتطبيق والخدمة،
            وهي خارج نطاق سيطرة  جى سيو اكسبريس . أثناء استخدامك لموقع الويب والتطبيق
            والخدمة، قد تراسل أو تشتري بضائع أو خدمات أو تشارك في عروض ترويجية
            مقدمة من مقدمي خدمات أو مُعلنين أو جهات راعية تعرض بضائعها أو
            خدماتها عبر ارتباط على موقع الويب أو من خلال التطبيق أو الخدمة.
            تنتقل بك تلك الارتباطات إلى خارج الموقع والتطبيق والخدمة، وهي خارج
            نطاق سيطرة  جى سيو اكسبريس . إن مواقع الويب التي يمكنك الارتباط بها لها شروط
            وأحكام مستقلة وكذلك سياسة خصوصية مستقلة. ولا تتحمل  جى سيو اكسبريس  أي مسؤولية
            عن محتوى مواقع الويب تلك وأنشطتها ولا يمكن مساءلتها عنها. ولذلك،
            فإنك تتحمل كامل المخاطر التي تنتج عن زيارة تلك المواقع أو دخولها.
            يُرجى ملاحظة أن تلك المواقع الأخرى قد ترسل ملفات تعريف الارتباط
            الخاصة بها إلى المستخدمين أو تجمع بياناتهم أو تطلب معلومات شخصية،
            ومن ثم، نوصيك بالتحقق من شروط الاستخدام أو سياسات الخصوصية الموجودة
            على تلك المواقع قبل استخدامها.
          </b>
          <h1>مدة العقد وإنهاؤه</h1>
          <b>
            يكون العقد المُبرم بينك وبين  جى سيو اكسبريس  غير محدد المدة. ويحق لك إنهاء
            العقد في أي وقت بحذف التطبيق المُثبّت على هاتفك الذكي حذفًا نهائيًا،
            وبذلك تعطل استخدامك للتطبيق والخدمة. يمكنك أن تغلق حساب المندوب
            الخاص بك في أي وقت. يحق ل” جى سيو اكسبريس ” إنهاء العقد بأثر فوري في أي وقت
            (بتعطيل استخدامك للتطبيق والخدمة)، وذلك في حالة قيامك بأي مما يلي:
            انتهاك أو خرق أي شرط من شروط مقدم الخدمة، إذا رأت  جى سيو اكسبريس  أنك تسيء
            استخدام التطبيق أو الخدمة. إن  جى سيو اكسبريس  غير ملزمة بإرسال سابق إخطار
            بإنهاء العقد.
          </b>
          <h1>بطلان حكم أو أكثر</h1>
          <b>
            لا يؤثر بطلان أي حكم من أحكام شروط مقدم الخدمة الماثلة على صحة باقي
            الأحكام الأخرى الواردة فيها. في حالة وجود أي حكم باطل في شروط مقدم
            الخدمة الماثلة أو وجود حكم غير مقبول في ظروف معينة وفقًا لمعايير
            المعقولية والعدالة وإلى هذا المدى فقط، يُعمل بدلاً منه بين الطرفين
            بحكم يكون مقبولاً مراعاةً لجميع الظروف ويتوافق مع أحكام الشرط الباطل
            قدر الإمكان، مع مراعاة محتوى شروط مقدم الخدمة الماثلة وغرضها.
          </b>
          <h1>تعديل الخدمة وشروط مقدم الخدمة</h1>
          <b>
            تحتفظ  جى سيو اكسبريس  لنفسها بالحق، وفقًا لتقديرها وحدها، في تعديل أي شرط من
            شروط مقدم الخدمة الماثلة أو استبداله، أو تغيير الخدمة أو التطبيق أو
            تعليقهما أو إيقافهما (بما في ذلك على سبيل المثال لا الحصر، توفير أي
            ميزة أو قاعدة بيانات أو محتوى) في أي وقت، وذلك بنشر إخطار على الموقع
            أو بإرسال إخطار لك من خلال الخدمة أو التطبيق أو عبر البريد
            الإلكتروني. يجوز ل” جى سيو اكسبريس ” أن تضع قيودًا على ميزات وخدمات مُعيَّنة أو
            تقصر وصولك إلى أجزاء من الخدمة أو الخدمة بأكملها دون إخطار أو
            مسؤولية.
          </b>
          <h1>الإخطار</h1>
          <b>
            يجوز للشركة أن ترسل إخطارًا عن طريق إرسال إخطار عام عن الخدمة أو
            التطبيق، أو بإرسال بريد إلكتروني إلى عنوانك البريدي المُسجل في
            معلومات الحساب لدى  جى سيو اكسبريس ، أو بإرسال مكاتبة بالبريد العادي على عنوانك
            المسجل في معلومات الحساب لدى  جى سيو اكسبريس .
          </b>
          <h1> القانون المعمول به وحل النزاعات</h1>
          <b>
            تخضع شروط مقدم الخدمة الماثلة ويطبق على تسوية أي نزاع أو مطالبة أو
            خلاف ينشأ عن شروط مقدم الخدمة الماثلة أو يتعلق بها أو أي انتهاك لها
            أو إنهائها أو تنفيذها أو تفسيرها أو صحتها أو استخدام الموقع أو
            الخدمة أو التطبيق، للقوانين والأنظمة المطبقة في جمهورية مصر العربية
            وتفسر وفقا لها.
          </b>
          <h1>سياسية عدم التمييز</h1>
          <b>
            تلتزم  جى سيو اكسبريس  بعدم التمييز ضد مستخدمي منصتنا على أساس العرق أو الدين
            أو الأصل القومي أو الإعاقة أو الجنس أو الحالة الاجتماعية أو العمر أو
            أي خاصية أخرى محمية بموجب قانون جمهوريه مصر العربيه تحظر  جى سيو اكسبريس  بشدة
            التمييز ضد المستخدمين بناءً على الفئات المذكورة أعلاه ، بما في ذلك
            التفاعلات الشخصية والمراجعات والتقييمات وتوفير الخدمات وقبولها. أي
            مستخدم يثبت أنه انتهك هذه السياسة سيفقد الوصول إلى النظام سيتمكن أي
            عامل أو مستخدم تعرض للتمييز من الاتصال بالممثلين البشريين لمنصتنا
            للحصول على المساعدة في الوقت المناسب ، بما في ذلك في مواقف مثل
            الاعتداء أو الإساءة أو أي شكل من أشكال المضايقة ، عن طريق إرسال
            رسالة عبر التطبيق. بالإضافة إلى معالجة التمييز ، نحن ملتزمون بشكل
            فعال بالتحقيق في العوائق التي تحول دون المشاركة المتساوية في المنصة
            أو الفئات المحرومة في مصر وتفكيكها. علاوة على ذلك ، نلتزم بضمان أن
            التصميم والعمليات والإدارة لمنصتنا ، حيثما أمكن ، لا يسهم في نتائج
            غير متكافئة أو تمييزية. إذا كانت لديك ملاحظات حول كيفية الالتزام
            بهذه السياسة بشكل أفضل ، فيمكنك تقديمها هنا من خلال الاتصال مع الدعم
            مع خيار عدم الكشف عن هويتك، بما في ذلك تحديد أي خطوات ذات صلة تم
            اتخاذها تماشياً مع التعليقات.
          </b>
        </div>
      </div>
    </>
  );
}
