import Chat from "../../../compoenents/Chat";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";

import Cookies from "js-cookie";
import LoginCheck from "../../../LoginCheck/page";
import { MAPS_API_KEY } from "../../../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 30.033, // الموقع الافتراضي
  lng: 31.233,
};
export default function CustomerShoppingTo(Search) {
  const [deliveryLocation, setDeliveryLocation] = useState(center);
  const [deliveryName, setDeliveryName] = useState("");
  const [isLoaded, setIsLoaded] = useState(false); // حالة تحميل الخريطة
  const [pickupLocation, setPickupLocation] = useState(null); // نقطة الاستلام
  const [distance, setDistance] = useState("0"); // المسافة بين نقطتي الاستلام والتسليم
  const [currentLocation, setCurrentLocation] = useState(null); // الموقع الحالي للمستخدم
  const [map, setMap] = useState(null);
  const searchInputRef = useRef();
  const autocompleteRef = useRef();
  const navigate = useNavigate();
  const libraries = useMemo(() => ["places", "geometry"], []);

  useEffect(() => {
    const savedPickupLocation = JSON.parse(
      localStorage.getItem("pickupLocation")
    );
    if (savedPickupLocation) {
      setPickupLocation(savedPickupLocation.location);
    }
  }, [pickupLocation]);
  /*
  useEffect(() => {
    // الحصول على الموقع عند تحميل الصفحة
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDeliveryLocation({ lat: latitude, lng: longitude });
          getPlaceName(latitude, longitude);
          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log(location);
          // // حفظ المكان في localStorage
          // localStorage.setItem(
          //   "pickupLocation",
          //   JSON.stringify({ location, name: pickupName})
          // );
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("متصفحك لا يدعم الحصول على الموقع.");
    }
  }, []);
*/

  const [error, setError] = useState(null);
  const [locationMessage, setLocationMessage] = useState(""); // لحفظ الرسائل
  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      // مراقبة الموقع باستمرار
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDeliveryLocation({ lat: latitude, lng: longitude });
          getPlaceName(latitude, longitude);

          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log("Current location:", location);
          setLocationMessage(""); // إزالة أي رسالة خطأ
        },
        (error) => {
          // تحديد سبب الخطأ بناءً على الكود
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert(
                "لم يتم السماح بالوصول إلى الموقع. يرجى تفعيل الموقع من الإعدادات."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              alert("الموقع غير متوفر حاليًا. حاول لاحقًا.");
              break;
            case error.TIMEOUT:
              console.error(
                "انتهت مهلة محاولة الحصول على الموقع. حاول مجددًا."
              );
              break;
            default:
              alert("حدث خطأ غير متوقع أثناء محاولة تحديد الموقع.");
          }
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: false, // استخدم دقة أقل إذا أمكن
          maximumAge: 10000, // يسمح باستخدام القيم المخزنة لمدة تصل إلى 10 ثوانٍ
          timeout: 20000, // ضبط مهلة لجلب الموقع
        }
      );
    } else {
      alert("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
      console.error("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
    }

    // تنظيف عند إلغاء المكون
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  useEffect(() => {
    if (window.google && window.google.maps) {
      const input = searchInputRef.current;
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocompleteRef.current = autocomplete;
      // عند تحديد مكان من الـ  Autocomplete
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setDeliveryLocation(location);
          getPlaceName(location.lat, location.lng);
          console.log(location);
          console.log(pickupLocation);
          // حساب المسافة بين نقطتي الاستلام والتسليم
          if (pickupLocation) {
            calculateDistance(pickupLocation, location);
          }
          // تحريك الخريطة إلى الموقع المحدد
          if (map) {
            map.panTo(location); // تحريك الخريطة إلى المكان المحدد
            map.setZoom(14); // تحديد مستوى التكبير عند النقطة
          }
        }
      });
    }
  }, [map]);

  const handleMapClick = async (event) => {
    const latLng = event.latLng;
    const location = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    setDeliveryLocation(location);
    getPlaceName(location.lat, location.lng);
    console.log(pickupLocation);
    // حساب المسافة بين نقطتي الاستلام والتسليم
    if (pickupLocation) {
      calculateDistance(pickupLocation, location);
    }
    // تحريك الخريطة إلى الموقع المحدد
    if (map) {
      map.panTo(location); // تحريك الخريطة إلى المكان المحدد
      map.setZoom(14); // تحديد مستوى التكبير عند النقطة
    }
  };

  const getPlaceName = async (latitude, longitude) => {
    try {
      const location = {
        lat: latitude,
        lng: longitude,
      };
      setDeliveryLocation(location);
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      setDeliveryName(response.data.display_name);
    } catch (error) {
      console.error("خطأ في جلب اسم الموقع:", error);
    }
  };

  const getPlaceName0 = (lat, lng) => {
    const location = {
      lat: lat,
      lng: lng,
    };
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setDeliveryName(results[0].formatted_address);
        // setDeliveryLocation(location);
        // localStorage.setItem(
        //   "deliveryLocation",
        //   JSON.stringify({ location, name: results[0].formatted_address })
        // );
      }
    });
  };

  // دالة حساب المسافة بين نقطتين
  const calculateDistance0 = (pickup, delivery) => {
    const R = 6371e3; // نصف قطر الأرض بالأمتار
    const φ1 = (pickup.lat * Math.PI) / 180; // تحويل درجة العرض الأولى إلى راديان
    const φ2 = (delivery.lat * Math.PI) / 180; // تحويل درجة العرض الثانية إلى راديان
    const Δφ = ((delivery.lat - pickup.lat) * Math.PI) / 180; // فرق العرض بالراديان
    const Δλ = ((delivery.lng - pickup.lng) * Math.PI) / 180; // فرق الطول بالراديان

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceInMeters = R * c; // المسافة بالأمتار
    const distanceInKilometers = distanceInMeters / 1000; // المسافة بالكيلومترات

    // تحديد الوحدات وعرض النتيجة
    let result;
    if (distanceInKilometers >= 1) {
      result = `${distanceInKilometers.toFixed(2)} كم`; // إذا كانت المسافة بالكيلومترات
    } else {
      result = `${distanceInMeters.toFixed(0)} م`; // إذا كانت المسافة بالأمتار
    }

    console.log("Distance: " + result);
    setDistance(result); // تخزين المسافة
    // حفظ اdistance__لبيانات في localStorage
    localStorage.setItem("distance", JSON.stringify({ distance: result }));
    return result; // النتيجة مع الوحدات
  };

  const calculateDistance = (pickup, delivery) => {
    const service = new window.google.maps.DistanceMatrixService();
    // const pickup = convertArabicNumbersToEnglish(pickup_);
    // const delivery = convertArabicNumbersToEnglish(delivery_);
    service.getDistanceMatrix(
      {
        origins: [pickup],
        destinations: [delivery],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          const distance_ = response.rows[0].elements[0].distance.text;

          // const distance__ = convertArabicNumbersToEnglish(distance_);

          setDistance(distance_); // تخزين المسافة
          // حفظ اdistance__لبيانات في localStorage
          localStorage.setItem(
            "distance",
            JSON.stringify({ distance: distance_ })
          );
        } else {
          alert("حدث خطأ أثناء حساب المسافة.");
        }
      }
    );
  };
  //----------------------------------------
  const convertArabicNumbersToEnglish = (input) => {
    if (!input) return "";

    // استبدال الأرقام
    const arabicToEnglishMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };

    // تحويل الأرقام والفواصل
    return input
      .replace(/[٠-٩]/g, (digit) => arabicToEnglishMap[digit]) // استبدال الأرقام
      .replace(/،/g, ","); // استبدال الفاصلة
  };

  // تحميل الخريطة وتغيير حالة isLoaded عند الانتهاء
  const handleLoad = () => {
    setIsLoaded(true); // الخريطة أصبحت جاهزة
  };

  const SaveLocation = () => {
    if (deliveryLocation) {
      localStorage.setItem(
        "deliveryLocation",
        JSON.stringify({ location: deliveryLocation, name: deliveryName })
      );
      console.log(deliveryLocation);
      navigate("/../Customer/Shopping/AnyShing");
    } else {
      alert("يرجى تحديد نقطة الاستلام أولاً.");
    }
  };

  //-----------------------------------------
  const params = useParams();
  const imgstyle = {
    height: "100%",
  };

  return (
    <>
      <div
        className="p-1 row  text-center m-auto "
        style={{ maxWidth: "500px" }}
      >
        <div className="col-12 py-2 text-center bg-light mb-1">
          <span className="fs-6 fw-bold"> موقع تسليم الشحنة </span>
          <Link to="../Customer/Shopping/AnyShing" className="float-start">
            <i className="fa-solid fa-xmark  fs-4 "></i>
          </Link>
        </div>
        <div className="col-12 p-2 div-mapTo bg-light mb-2">
          <div style={{ marginBottom: "20px" }}>
            <input
              type="text"
              id="pickup-search"
              placeholder="ابحث فى الخريطة"
              ref={searchInputRef}
              style={{ width: "100%", padding: "10px" }}
              className="searchText"
            />
          </div>
          <LoadScript
            googleMapsApiKey={MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleLoad} // تعيين isLoaded على true بعد تحميل المكتبة
          >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={deliveryLocation}
                zoom={14}
                onClick={handleMapClick} // عند النقر على الخريطة
                onLoad={(mapInstance) => setMap(mapInstance)} // عندما يتم تحميل الخريطة
              >
                {pickupLocation && (
                  <Marker position={pickupLocation} label="استلام" />
                )}
                {deliveryLocation && (
                  <Marker position={deliveryLocation} label="تسليم" />
                )}
              </GoogleMap>
            ) : (
              <div>الخريطة في مرحلة التحميل... يرجى الانتظار</div> // عرض رسالة مخصصة عند تحميل الخريطة
            )}
          </LoadScript>
        </div>
        <div className="col-12 px-2 bg-greenLight text-end p-3">
          <i className="fa-solid fa-location-dot text-green  mx-2"></i>
          <span>{deliveryName || "لم يتم تحديدها"}</span>
          <br />
          <span>{distance}</span>
        </div>
        <div className="col-12 my-4 p-1 text-end">
          <h6>تفاصيل اضافية</h6>
          <input
            type="text"
            name=""
            className="form-control"
            placeholder="رقم المنزل / العمارة / الشقة (اختيارى)"
          />
          <div dir="ltr" className="mt-3">
            <label htmlFor="Saveplase" className="text-secondary mt-0">
              {"حفظ المكان لاستخدامة لاحقا  "}
            </label>

            <input
              id="Saveplase"
              type="checkbox"
              name="Saveplase"
              className="mx-2"
              // checked={inputs.active}
              // onChange={(e) => {
              //  setinputs({ ...inputs, active: event.target.checked });
              // }}

              // onChange={handleChange}
            />
          </div>

          <button
            className="btn btn-green w-100 fw-bold p-2 mt-3"
            onClick={() => SaveLocation()}
          >
            {" موقع تسليم الشحنة"}
          </button>
        </div>
      </div>
    </>
  );
}
