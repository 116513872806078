import { useState, useEffect, useRef } from "react";
import { useAudio } from "../../../AudioContext";
import Cookies from "js-cookie";
import UpdateLocatin from "../../UpdateLocation/page";
import Chat from "../../compoenents/Chat";
import CheckAccount from "../AccountCheck/page";
import ButtomTab from "./ButtomTab";
import { ApiUrl } from "../../Values";

export default function DellerAlert() {
  const [Data, SetData] = useState([]);
  const { audio, audioAllowed } = useAudio();
  var userinfo = [];
  var Username = "";
  var UserID = "";

  // تحقق من تسجيل الدخول
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  // جلب البيانات عند تحميل الصفحة
  useEffect(() => {
    GetData();
  }, []);

  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);

  // جلب الرسائل بشكل دوري
  useEffect(() => {
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        GetData();
      }, 5000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive]);

  // دالة جلب البيانات
  async function GetData() {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        statue: "",
        Action: "Alerts",
      };
      const respons = await fetch(`${ApiUrl}/Deller/CalculateData.php`, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data_ = await respons.json();
      // تحقق من وجود بيانات جديدة أو تغييرات
      const datanew = JSON.stringify(data_);
      const dataold = JSON.stringify(Data);
      if (datanew !== dataold) {
        SetData(data_);  // تحديث البيانات فقط إذا كانت جديدة

        /*
          if (audioAllowed) {
            audio.pause();
            audio.currentTime = 0;
            audio.play(); 
            console.log("audio start");
          }
          */
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <>
      <UpdateLocatin />
      <Chat />
      <CheckAccount />
      <div className="row m-auto" style={{ maxWidth: "500px" }}>
        <div className="col-12 p-1 bg-syan">
          <h4>
            <i className="fa-solid fa-bell  m-0 p-0"></i> التنبيهات
          </h4>
        </div>

        <div className="col-master col-card-alert col-12 my-4 pt-2">
          {Data.length > 0 ? <CardAlerts /> : <NoAlerts />}
        </div>
      </div>
      <ButtomTab />
    </>
  );

  // عرض التنبيهات
  function CardAlerts() {
    return Data.map((x, index) => {
      var icon = "fa fa-user-tie";
      if (x.msg === "قبول عرض") icon = "fa-solid fa-clipboard-check text-syan";
      if (x.msg === "تاكيد سداد") icon = "fa-brands fa-cc-visa text-blue";
      if (x.msg === "خصم جزاءات") icon = "fa-solid fa-triangle-exclamation text-danger";
      if (x.msg === "تصفيه حساب") icon = "fa-solid fa-money-check-dollar text-warning";
      return (
        <div key={index} className="card-content card" style={{ maxWidth: "500px" }}>
          <div className="card-body p-0">
            <div className="row">
              <div className="col-2 text-center pt-3">
                <i className={icon} style={{ fontSize: "30px", borderRadius: "30px" }}></i>
              </div>
              <div className="col-10">
                {x.msg !== x.Description ? (
                  <>
                    <small className="col-10 text-dark fs-6 fw-bold">{x.msg}</small>
                    <br />
                    <small style={{ color: "#000" }}>{x.Description}</small>
                  </>
                ) : (
                  <small className="col-10">{x.msg}</small>
                )}
                <br />
                <small>{`${x.date} - ${x.time}`}</small>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  // حالة لا توجد تنبيهات
  function NoAlerts() {
    return (
      <div className="card m-0">
        <div className="card-body text-center " style={{ height: "650px" }}>
          <i style={{ fontSize: "100px" }} className="fa-solid fa-bell-slash m-4 p-4 text-secondary"></i>
          <h4>لا يوجد اى تنبيهات</h4>
        </div>
      </div>
    );
  }
}
