import { useState, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
//-------------------------------------------
//npm install browser-image-compression
import imageCompression from "browser-image-compression"; // مكتبة ضغط الصور
//---------------------------------------------
import { API } from "../../Values";
import { Modal, Button } from "react-bootstrap";

export default function CreateFatora(order) {
  const [ShowModal, SetShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cost, setCost] = useState("");
  const fileInputRef = useRef(null);
  const params = useParams();
  const userinfo = Cookies.get("userinfo")
    ? JSON.parse(Cookies.get("userinfo"))
    : [];
  const navigate = useNavigate();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // إعدادات الضغط
        const options = {
          maxSizeMB: 0.1, // الحد الأقصى لحجم الصورة (0.1 ميجابايت)
          // maxSizeMB: 0.5, // الحد الأقصى لحجم الصورة (500 ;dg,fhdj)
          maxWidthOrHeight: 420, // الحد الأقصى للطول أو العرض
          useWebWorker: true,
        };

        // ضغط الصورة
        const compressedFile = await imageCompression(file, options);
        setSelectedImage(compressedFile); // تخزين الملف المضغوط
      } catch (error) {
        console.error("خطأ أثناء ضغط الصورة:", error);
      }
    }
  };

  const handleSubmit = async () => {
    if (!cost || !selectedImage) {
      alert("الرجاء تعبئة جميع الحقول واختيار صورة");
      return;
    }
    SetShowModal(true);
    const formData = new FormData();
    formData.append("Action", "creatFatora");
    formData.append("number_order", params.number_order);
    formData.append("cost", cost);
    formData.append("image", selectedImage);
    try {
      const response = await axios.post(API.creatFatora, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      SetShowModal(false);
      if (response.data.success) {
        navigate("../DellerCHat/" + params.number_order);
      } else {
        console.error(response.data.error);
        alert(response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div
        dir="rtl"
        className="row mt-0 text-center m-auto"
        style={{ maxWidth: "500px" }}
      >
        <div className="col-1 py-1">
          <Link to={`../DellerCHat/${params.number_order}`}>
            <i className="fa-solid fa-chevron-right text-info mt-2 fs-4"></i>
          </Link>
        </div>
        <div className="col-11 py-1 text-center">
          <h4>{"إصدار فاتورة"}</h4>
        </div>
        <div className="col-master col-card-alert col-12 mb-4 pt-3">
          <div className="row">
            <div className="col-12 mb-4 text-end">
              <label>اكتب قيمة الفاتورة</label>
              <input
                type="number"
                name="cost"
                className="form-control mt-2"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
              />
            </div>
            <div className="col-12 mb-4 text-end">
              <h6>المرفقات</h6>
              <input
                type="file"
                capture="camera"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                type="button"
                className="btn btn-light"
                onClick={handleButtonClick}
              >
                <i className="fa-solid fa-camera fs-4 text-syan mt-2"></i>
                <br />
                {"ارفاق صورة"}
              </button>
            </div>
            <div className="col-12 ">
              <div
                className="m-2 p-2 bg-white"
                style={{ border: "2px solid #f00" }}
              >
                <label htmlFor="">
                  انتبة عند التقاط صورة الفاتورة تاكد من اخفاء رقم بطاقة الشحن
                  حتى لا تتعرض للاحتيال
                </label>
              </div>
            </div>

            <div className="col-12 text-center" style={{ height: "270px" }}>
              {selectedImage && (
                <div
                  className="bg-white p-1"
                  style={{
                    marginTop: "20px",
                    width: "200px",
                    border: "2px solid #BFEDF2",
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <h6
                    className="text-danger mb-1 text-end"
                    onClick={() => setSelectedImage(null)}
                  >
                    x
                  </h6>
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="الصورة المختارة"
                    style={{
                      width: "100%",
                      maxHeight: "230px",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="col-12 text-center bg-white mt-4 p-3">
              <h6 className="text-end">تكلفة الفاتورة شاملة الضريبة</h6>
              <button
                className="btn btn-green p-2 w-100 fw-bold fs-5"
                onClick={handleSubmit}
              >
                حفظ
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={ShowModal} onHide={() => SetShowModal(false)}>
        <Modal.Body>
          <div className="row mt-0 px-0">
            <div className="col-12 text-center">
              <img
                style={{ width: "80px" }}
                src="../../../Images/loding2.gif"
                alt=""
              />
              <h4 className="list p-2 my-4">{"جارٍ رفع ومعالجة الصورة"}</h4>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
