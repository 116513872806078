import { useState, useEffect, useMemo } from "react";
import { LoadScript } from "@react-google-maps/api";
import Cookies from "js-cookie";
import { MAPS_API_KEY, DefaultImage } from "../../../Values";
import { useParams, useNavigate, Link } from "react-router-dom";
import LoginCheck from "../../../LoginCheck/page";

export default function CustomerSearch() {
  const defaultCenter = { lat: 30.033, lng: 31.233 }; // الموقع الافتراضي
  const params = useParams();
  const navigate = useNavigate();
  LoginCheck(); // التحقق من تسجيل الدخول

  const userinfo = Cookies.get("userinfo")
    ? JSON.parse(Cookies.get("userinfo"))
    : [];

  const [userLocation, setUserLocation] = useState([]);
  const [SearchData, SetSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [SearchType, setSearchType] = useState("ماكولات ومشروبات");
  const libraries = useMemo(() => ["places"], []);
  const [isLoaded, setIsLoaded] = useState(false); // حالة تحميل الخريطة
  // تحديد نوع البحث بناءً على الفئة
  useEffect(() => {
    switch (params.Search) {
      case "restaurant":
        setSearchType("ماكولات ومشروبات");
        break;
      case "supermarket":
        setSearchType("سوبر ماركت");
        break;
      case "store":
        setSearchType("المحلات التجارية");
        break;
      case "shopping_mall":
        setSearchType("مولات تجارية");
        break;
      default:
        setSearchType("ماكولات ومشروبات");
    }
  }, [params.Search]);

  // تعيين الموقع الجغرافي الحالي
  useEffect(() => {
    if (params.lat && params.lng) {
      setUserLocation({
        lat: parseFloat(params.lat),
        lng: parseFloat(params.lng),
      });
    } else {
      alert("يرجى توفير موقع صالح.");
    }
  }, [params.lat, params.lng]);

  useEffect(() => {
    if (isLoaded) {
      fetchSearchData();
    }
  }, [isLoaded]);
  // جلب بيانات البحث
  const fetchSearchData = async () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Places API is not loaded");
      return;
    }

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    const request = {
      location: new window.google.maps.LatLng(
        parseFloat(params.lat),
        parseFloat(params.lng)
      ),
      radius: 20000, // مسافة 6000 متر (يمكنك زيادتها)
      type: params.Search,
      // fields: ["name", "geometry", "photos", "rating", "vicinity"],
    };

    setLoading(true);

    try {
      const placesResponse = await new Promise((resolve, reject) => {
        placesService.nearbySearch(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            reject(`Error fetching places: ${status}`);
          }
        });
      });

      const distanceMatrixService =
        new window.google.maps.DistanceMatrixService();
      const destinations = placesResponse.map((place) => ({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }));

      const distanceRequest = {
        origins: [`${parseFloat(params.lat)},${parseFloat(params.lng)}`],
        destinations: destinations.map(
          (loc) => new window.google.maps.LatLng(loc.lat, loc.lng)
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      const distanceResults = await new Promise((resolve, reject) => {
        distanceMatrixService.getDistanceMatrix(
          distanceRequest,
          (results, status) => {
            if (status === window.google.maps.DistanceMatrixStatus.OK) {
              resolve(results);
            } else {
              reject(`Error fetching distances: ${status}`);
            }
          }
        );
      });

      const distances = distanceResults.rows[0].elements;
      const searchDataWithDetails = placesResponse.map((place, index) => {
        const photoUrl = place.photos
          ? place.photos[0].getUrl({ maxWidth: 250 })
          : "https://via.placeholder.com/400x200?text=No+Image";

        return {
          id: place.place_id,
          name: place.name,
          address: place.vicinity,
          rating: place.rating || "غير متوفر",
          photoUrl,
          location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
          distance: distances[index]?.distance?.text || "غير متوفر",
        };
      });

      SetSearchData(searchDataWithDetails);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function Goto(pickupLocation, pickupName, distance, logo) {
    const userLocation_ = {
      lat: parseFloat(params.lat),
      lng: parseFloat(params.lng),
    };
    localStorage.setItem(
      "pickupLocation",
      JSON.stringify({ location: pickupLocation, name: pickupName })
    );
    localStorage.setItem("distance", JSON.stringify({ distance: distance }));

    localStorage.setItem(
      "deliveryLocation",
      JSON.stringify({ location: userLocation_, name: params.LocationName })
    );
    localStorage.setItem("logo", logo);
    navigate(`../Customer/Shopping/AnyShing`);
  }
  //=============================
  const handleLoad = () => {
    setIsLoaded(true); // الخريطة أصبحت جاهزة
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={MAPS_API_KEY}
        libraries={libraries}
        onLoad={handleLoad}
      />

      <div className="p-1 row text-center m-auto" style={{ maxWidth: "500px" }}>
        <div className="col-1">
          <Link to="../Customer/Shopping">
            <i className="fa-solid fa-arrow-right mt-2 fs-4"></i>
          </Link>
        </div>
        <div className="col-11 px-2">
          <div className="text-end divSearch d-flex">
            <input
              type="text"
              name="Search"
              id="Search"
              className="TextSearch"
              placeholder={" بحث فى " + SearchType}
            />
          </div>
        </div>
        <div className="col-master col-card-aler col-12 text-center mt-1 pt-1">
          {SearchData.length > 0 ? (
            SearchData.map((x) => (
              <div
                className="row p-0 m-1"
                key={x.id}
                onClick={() => Goto(x.location, x.name, x.distance, x.photoUrl)}
              >
                <div className="col-12 bg-light my-2 p-0">
                  <div className="mb-1">
                    <img
                      src={x.photoUrl || DefaultImage}
                      alt=""
                      loading="lazy"
                      style={{ width: "100%", height: "250px" }}
                      onError={(e) => {
                        e.target.src = DefaultImage;
                      }}
                    />
                  </div>
                  <div className="col-12 my-2 py-2 pb-2 w-100">
                    <div className="col-12 text-end p-0 m-0 pe-1">
                      <img
                        src={x.photoUrl || DefaultImage}
                        alt=""
                        loading="lazy"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "12px",
                        }}
                        onError={(e) => {
                          e.target.src = DefaultImage;
                        }}
                      />
                      <span className="mx-1 fw-bold me-2">{x.name}</span>
                    </div>
                    <div className="col-12 text-end p-0 m-0 pe-1">
                      <small
                        className="mx-1 fw-bold "
                        style={{ fontSize: "12px" }}
                      >
                        {x.address}
                      </small>
                    </div>
                    <small className="mx-2 px-1 float-start bg-white">
                      <i className="fa-solid fa-location-dot ms-2 text-warning"></i>
                      {x.distance}
                    </small>
                    <span className="float-end">
                      <i className="fa-solid fa-star ms-1 text-warning"></i>
                      {x.rating}
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <br />
              <br />

              <i
                class="fa-solid fa-magnifying-glass text-secondary mb-2"
                style={{ fontSize: "60px" }}
              ></i>
              <h3>لا توجد نتائج للبحث</h3>
            </>
          )}
        </div>
      </div>
    </>
  );
}
