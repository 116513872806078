import Chat from "../../../compoenents/Chat";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import LoginCheck from "../../../LoginCheck/page";
import { MAPS_API_KEY } from "../../../Values";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from 'axios';

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 30.033,
  lng: 31.233,
};

// import GoogleMapsComponent from "../../compoenents/GoogleMapsComponent";
export default function CustomerShoppingFrom(Search) {
  const params = useParams();
  const imgstyle = {
    height: "100%",
  };
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false); // استخدام حالة جديدة لتحديد إذا كانت الخريطة محملة أم لا
  const searchInputRef = useRef();
  const autocompleteRef = useRef();
  const [pickupLocation, setPickupLocation] = useState(center);
  const [pickupName, setPickupName] = useState("");
  const [distance, setDistance] = useState("0"); // المسافة بين نقطتي الاستلام والتسليم
  const [deliveryLocation, setDeliveryLocation] = useState(null); // نقطة التسليم
  const [deliveryName, setDeliveryName] = useState(null); // نقطة التسليم

  const libraries = useMemo(() => ["places", "geometry"], []);

  useEffect(() => {
    const savedDeliveryLocation = JSON.parse(
      localStorage.getItem("deliveryLocation")
    );
    if (savedDeliveryLocation) {
      setDeliveryLocation(savedDeliveryLocation.location);
      setDeliveryName(savedDeliveryLocation.name);
    }
  }, []);
/*
  useEffect(() => {
    // الحصول على الموقع عند تحميل الصفحة
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPickupLocation({ lat: latitude, lng: longitude });
          getPlaceName(latitude, longitude);
          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log(location);
          // // حفظ المكان في localStorage
          // localStorage.setItem(
          //   "pickupLocation",
          //   JSON.stringify({ location, name: pickupName})
          // );
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("متصفحك لا يدعم الحصول على الموقع.");
    }
  }, []);
*/



  const [error, setError] = useState(null);
  const [locationMessage, setLocationMessage] = useState(""); // لحفظ الرسائل
  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      // مراقبة الموقع باستمرار
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPickupLocation({ lat: latitude, lng: longitude });
          getPlaceName(latitude, longitude);

          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log("Current location:", location);
          setLocationMessage(""); // إزالة أي رسالة خطأ
        },
        (error) => {
          // تحديد سبب الخطأ بناءً على الكود
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert("لم يتم السماح بالوصول إلى الموقع. يرجى تفعيل الموقع من الإعدادات.");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("الموقع غير متوفر حاليًا. حاول لاحقًا.");
              break;
            case error.TIMEOUT:
              console.error("انتهت مهلة محاولة الحصول على الموقع. حاول مجددًا.");
              break;
            default:
              alert("حدث خطأ غير متوقع أثناء محاولة تحديد الموقع.");
          }
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: false, // استخدم دقة أقل إذا أمكن
          maximumAge: 10000, // يسمح باستخدام القيم المخزنة لمدة تصل إلى 10 ثوانٍ
          timeout: 20000, // ضبط مهلة لجلب الموقع
        }
      );
    } else {
      alert("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
      console.error("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
    }

    // تنظيف عند إلغاء المكون
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);



  useEffect(() => {
    if (window.google && window.google.maps) {
      const input = searchInputRef.current;
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocompleteRef.current = autocomplete;

      // عند تحديد مكان من الـ Autocomplete
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          console.log(location);
          setPickupLocation(location);
          setPickupName(place.formatted_address);

          // حفظ المكان في localStorage
          // localStorage.setItem(
          //   "pickupLocation",
          //   JSON.stringify({ location, name: place.formatted_address })
          // );

          // تحريك الخريطة إلى الموقع المحدد
          if (map) {
            map.panTo(location); // تحريك الخريطة إلى المكان المحدد
            map.setZoom(14); // تحديد مستوى التكبير عند النقطة
          }
        }
      });
    }
  }, [map]);

  const handleMapClick = async (event) => {
    const latLng = event.latLng;
    const location = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    setPickupLocation(location);
    getPlaceName(location.lat, location.lng);
    // // حفظ البيانات في localStorage
    localStorage.setItem(
      "pickupLocation",
      JSON.stringify({ location, name: pickupName })
    );
    // حساب المسافة بين نقطتي الاستلام والتسليم
    if (pickupLocation) {
      calculateDistance(pickupLocation, location);
    }
    // تحريك الخريطة إلى الموقع المحدد
    if (map) {
      map.panTo(location); // تحريك الخريطة إلى المكان المحدد
      map.setZoom(14); // تحديد مستوى التكبير عند النقطة
    }
  };

  const getPlaceName = async (latitude, longitude) => {
    try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
        setPickupName(response.data.display_name);
    } catch (error) {
        console.error('خطأ في جلب اسم الموقع:', error);
    }
};



  const getPlaceName0 = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setPickupName(results[0].formatted_address);
      }
    });
  };

  // تحميل الخريطة وتغيير حالة isLoaded عند الانتهاء
  const handleLoad = () => {
    setIsLoaded(true); // الخريطة أصبحت جاهزة
  };

  const calculateDistance = (pickup, delivery) => {
    // const pickup = convertArabicNumbersToEnglish(pickup_);
    // const delivery = convertArabicNumbersToEnglish(delivery_);
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [pickup],
        destinations: [delivery],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          const distance_ = response.rows[0].elements[0].distance.text;
          const distance__ = convertArabicNumbersToEnglish(distance_);
          setDistance(distance__); // تخزين المسافة
          // حفظ البيانات في localStorage
          localStorage.setItem(
            "distance",
            JSON.stringify({ distance: distance__ })
          );
        } else {
          alert("حدث خطأ أثناء حساب المسافة.");
        }
      }
    );
  };

  const convertArabicNumbersToEnglish = (input) => {
    if (!input) return "";

    // استبدال الأرقام
    const arabicToEnglishMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };

    // تحويل الأرقام والفواصل
    return input
      .replace(/[٠-٩]/g, (digit) => arabicToEnglishMap[digit]) // استبدال الأرقام
      .replace(/،/g, ","); // استبدال الفاصلة
  };

  const SaveLocation = () => {
    if (pickupLocation) {
      localStorage.setItem(
        "pickupLocation",
        JSON.stringify({ location: pickupLocation, name: pickupName })
      );
      console.log(pickupLocation);
      navigate("/../Customer/Shopping/AnyShing");
    } else {
      alert("يرجى تحديد نقطة الاستلام أولاً.");
    }
  };

  return (
    <>
      <div
        className="p-1 row  text-center m-auto "
        style={{ maxWidth: "500px" }}
      >
        <div className="col-12 py-2 text-center bg-light mb-1">
          <span className="fw-bold fs-6">من اين تريد ان تطلب</span>
          <Link to="../Customer/Shopping/AnyShing" className="float-start">
            <i className="fa-solid fa-xmark  fs-4 "></i>
          </Link>
        </div>
        <div className="col-12 p-1 div-mapFrom bg-light text-center mb-2">
          <div style={{ marginBottom: "20px" }}>
            <input
              type="text"
              id="pickup-search"
              placeholder="ابحث فى الخريطة"
              ref={searchInputRef}
              style={{ width: "100%", padding: "10px" }}
              className="searchText"
            />
          </div>

          <LoadScript
            googleMapsApiKey={MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleLoad} // تعيين isLoaded على true بعد تحميل المكتبة
          >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={pickupLocation}
                zoom={14}
                onClick={handleMapClick} // عند النقر على الخريطة
                onLoad={(mapInstance) => setMap(mapInstance)} // عندما يتم تحميل الخريطة
              >
                {pickupLocation && (
                  <Marker position={pickupLocation} label="استلام" />
                )}
              </GoogleMap>
            ) : (
              <div>الخريطة في مرحلة التحميل... يرجى الانتظار</div> // عرض رسالة مخصصة عند تحميل الخريطة
            )}
          </LoadScript>
        </div>
        <div className="col-12 px-2 bg-light text-end p-3">
          <i className="fa-solid fa-location-dot text-green  mx-2"></i>
          <span>
            <strong> </strong>
            {pickupName || "لم يتم تحديدها"}
          </span>
        </div>

        <div className="col-12 mt-2 p-0">
          <button
            className="btn btn-green w-100 fw-bold p-2"
            onClick={() => SaveLocation()}
          >
            {"  تاكيد الموقع "}
          </button>
        </div>
      </div>
    </>
  );
}
