// import ReactDOM from "react-dom/client";
// import Cookies from "universal-cookie";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  Payment,
  PaymentCallBack,
  Spalesh,
  Chat,
  UsedWarning,
  Khsosya,
  Login,
  NoPage,
  EditePassword,
  ForgetPassword,
  RestorePassword,
  UploadImage,
  //---------------------
  CustomerStartPage,
  CustomerAccount,
  CustomerAlert,
  CustomerEditeProfile,
  CustomerRegister,
  CustomerOrders,
  CustomerOrderInfo,
  CustomerShopping,
  CustomerSearch,
  CustomerShoppingAnyThing,
  CustomerOrderWaiting,
  CustomerOrderPinding,
  CustomerCHat,
  CustomerOrderCancel,
  CustomerShoppingFrom,
  CustomerShoppingTo,
  CustomerMain,
  CustomerAddRassed,
  CustomerAdressSaved,
  CustomerSubscrib,
  CustomerNotes,
  CustomerKobon,
  CustomerSupport,
  CustomerSetting,
  CustomerAdressAdd,
  CustomerAdressEdite,
  CustomerHistoryShkawa,
  CustomerCHatShkawa,
  // ------------------------------------
  OrderAddOffer,
  DellerAccount,
  DellerAddRassed,
  DellerAlert,
  DellerArbah,
  DellerEditeProfile,
  DellerKobon,
  DellerHome,
  DellerNotes,
  DellerOrders,
  DellerOrderInfo,
  DellerRegister,
  DellerReplaseAcount,
  DellerSetting,
  DellerSupport,
  DellerHistoryShkawa,
  DellerSubscrib,
  AccountDisable,
  DellerCHat,
  CreateFatora,
  DellerCHatShkawa,
  AdminCHatShkawa,
  //==========================================
  AdminHome,
  AdminOrders,
  AdminOrderInfo,
  AdminShkawa,
  AdminCustomer,
  AdminDeller,
  AdminEmpolyee,
  AdminSetting,
  AdminUsers,
  AdminPayment,
  CompanyInfo,
  Destanse,
  ApiSetting,
  KobonSetting,
  Database,
  EmpolyeeAdd,
  DellerNavigation,
  DellerNavigationAll,
} from "./pages/Routing";
// --------------------------------
import { AudioProvider } from "./AudioContext";
function App() {
  return (
    <div className="App">
      <AudioProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<NoPage />} />
            <Route path="/" element={<Spalesh />} />
            <Route path="/Payment/:mony?" element={<Payment />} />
            
            <Route
              path="UploadImage/:order_id?/:sender_id?/:sender_name?/:receiver_id?/:receiver_name?/:chat_type?"
              element={<UploadImage />}
            />

            <Route path="/PaymentCallBack" element={<PaymentCallBack />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/EditePassword" element={<EditePassword />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route path="/RestorePassword" element={<RestorePassword />} />
            <Route path="/UsedWarning" element={<UsedWarning />} />
            <Route path="/Khsosya" element={<Khsosya />} />
            <Route path="Customer/StartPage" element={<CustomerStartPage />} />
            <Route path="Customer/Account" element={<CustomerAccount />} />
            <Route path="Customer/Alerts" element={<CustomerAlert />} />
            <Route
              path="Customer/AddRassed/:mony?"
              element={<CustomerAddRassed />}
            />
            <Route path="Customer/Subscrib" element={<CustomerSubscrib />} />
            <Route path="Customer/Notes" element={<CustomerNotes />} />
            <Route path="Customer/Kobon" element={<CustomerKobon />} />
            <Route
              path="Customer/Support/:number_order?"
              element={<CustomerSupport />}
            />
            <Route path="Customer/Setting" element={<CustomerSetting />} />
            <Route
              path="Customer/HistoryShkawa"
              element={<CustomerHistoryShkawa />}
            />
            <Route
              path="Customer/AdressSaved"
              element={<CustomerAdressSaved />}
            />
            <Route path="Customer/AdressAdd" element={<CustomerAdressAdd />} />
            <Route
              path="Customer/AdressEdite/:name?"
              element={<CustomerAdressEdite />}
            />
            <Route
              path="Customer/EditeProfile"
              element={<CustomerEditeProfile />}
            />
            <Route path="Customer/Register" element={<CustomerRegister />} />
            <Route path="Customer/Orders" element={<CustomerOrders />} />
            <Route
              path="Customer/OrderInfo/:order?"
              element={<CustomerOrderInfo />}
            />
            <Route path="Customer" element={<CustomerShopping />} />
            <Route path="Customer/Shopping" element={<CustomerShopping />} />
            <Route
              path="Customer/Shopping/Search/:Search?/:lat?/:lng?/:LocationName?"
              element={<CustomerSearch />}
            />
            <Route
              path="Customer/Shopping/AnyShing/:type?"
              element={<CustomerShoppingAnyThing />}
            />
            <Route
              path="Customer/OrderWaiting/:number_order?/:re_order?"
              element={<CustomerOrderWaiting />}
            />
            <Route
              path="Customer/OrderPinding/:number_order?"
              element={<CustomerOrderPinding />}
            />
            <Route
              path="CustomerCHat/:number_order?"
              element={<CustomerCHat />}
            />

            <Route
              path="Customer/OrderCancel/:order?"
              element={<CustomerOrderCancel />}
            />
            <Route
              path="Customer/ShoppingFrom"
              element={<CustomerShoppingFrom />}
            />
            <Route
              path="Customer/ShoppingTo"
              element={<CustomerShoppingTo />}
            />
            <Route path="Customer" element={<CustomerMain />} />
            {/* =================== */}

            {/* <Route path="Deller/StartPage" element={<DellerStartPage />} /> */}

            <Route
              path="DellerNavigation/:DellerID?"
              element={<DellerNavigation />}
            />
            <Route
              path="DellerNavigationAll"
              element={<DellerNavigationAll />}
            />

            <Route path="OrderAddOffer" element={<OrderAddOffer />} />
            <Route path="Deller/Account" element={<DellerAccount />} />
            <Route
              path="Deller/AddRassed/:mony?"
              element={<DellerAddRassed />}
            />
            <Route path="Deller/Alerts" element={<DellerAlert />} />
            <Route path="Deller/Arbah" element={<DellerArbah />} />
            <Route
              path="Deller/EditeProfile"
              element={<DellerEditeProfile />}
            />
            <Route path="Deller/Subscrib" element={<DellerSubscrib />} />
            <Route path="Deller/Kobon" element={<DellerKobon />} />
            <Route path="Deller/Notes" element={<DellerNotes />} />
            <Route path="Deller/Orders" element={<DellerOrders />} />
            <Route path="AccountDisable" element={<AccountDisable />} />
            <Route path="DellerCHat/:number_order?" element={<DellerCHat />} />
            <Route
              path="CreateFatora/:number_order?"
              element={<CreateFatora />}
            />
             <Route path="AdminCHatShkawa/:number_order?/:name?/:id?" element={<AdminCHatShkawa />} />
            <Route path="DellerCHatShkawa/:number_order?" element={<DellerCHatShkawa />} />
            <Route path="CusomerCHatShkawa/:number_order?" element={<CustomerCHatShkawa />} />
            
            <Route
              path="Deller/OrderInfo/:order?"
              element={<DellerOrderInfo />}
            />
            <Route path="Deller/Register" element={<DellerRegister />} />
            <Route
              path="Deller/ReplaseAcount"
              element={<DellerReplaseAcount />}
            />
            <Route path="Deller/Setting" element={<DellerSetting />} />
            <Route path="Deller/Support" element={<DellerSupport />} />
            <Route
              path="Deller/HistoryShkawa"
              element={<DellerHistoryShkawa />}
            />

            <Route path="Deller/home" element={<DellerHome />} />
            <Route path="Deller" element={<DellerHome />} />
            {/* =============================================== */}
            <Route path="admin" element={<AdminHome />} />
            <Route path="admin/Orders" element={<AdminOrders />} />
            <Route
              path="admin/OrderInfo/:order?"
              element={<AdminOrderInfo />}
            />

            <Route path="admin/Shkawa" element={<AdminShkawa />} />

            <Route path="admin/Customer" element={<AdminCustomer />} />
            <Route path="admin/Deller" element={<AdminDeller />} />
            <Route path="admin/Empolyee" element={<AdminEmpolyee />} />
            <Route path="EmpolyeeAdd" element={<EmpolyeeAdd />} />
            <Route path="admin/Setting" element={<AdminSetting />} />
            <Route path="admin/Users" element={<AdminUsers />} />
            <Route path="admin/Payment" element={<AdminPayment />} />
            <Route path="CompanyInfo" element={<CompanyInfo />} />
            <Route path="Destanse" element={<Destanse />} />
            <Route path="ApiSetting" element={<ApiSetting />} />
            <Route path="KobonSetting" element={<KobonSetting />} />
            <Route path="Database" element={<Database />} />

            {/* =================== */}
            {/* <Route path="contact/:id?/:phone?" element={<Contact />} /> */}
            {/* <Route path="contact/:id" element={<Contact />} /> */}
          </Routes>
        </BrowserRouter>
      </AudioProvider>
    </div>
  );
}
export default App;
