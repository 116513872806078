// import Cookies from "universal-cookie";
//  const Cookies = new Cookies();
// Cookies.set("elawael", "111", { httpOnly: true });
// console, log(Cookies);

// npm install js-cookie
// import Cookies from "js-cookie";
// Set a cookie
// Cookies.set("username", "nasser", { expires: 365 }); // Sets a cookie for 365 days
// Get a cookie
// const username = Cookies.get("username");
// console.log(username);
// Remove a cookie
// Cookies.remove('username');

// sessionStorage.setItem("username", "nasser");
// const username0 = sessionStorage.getItem("username");
// console.log(username0); // Output: JohnDoe
// Removing a session item
// sessionStorage.removeItem('username');
// // Clearing all session items
// sessionStorage.clear();
// var Url = "https://elawaeltech.com/jsio/api";
var Url = "https://jsio-api.j-sio.com/api";
// Url = "http://2.2.2.2/elmohaseb_JSIO/api";
export const AppValues = {
  logo: "./welecome.jpg",
};

export const MAPS_API_KEY0 = "AIzaSyDmPuWZGbBNXzNf_uxKdFsj0RqiC6N4-6Y";
export const MAPS_API_KEY = "AIzaSyDP5kBfX5USxhjtVOsgecS9-ksnqVZmLhk";
export const ApiUrl = Url;
export const DefaultImage = Url + "/imags/default.jpeg";
export const API = {
  Login: Url + "/Login.php",
  //--------------------------------------
  Orders: Url + "/orders/orders.php",
  creatFatora: Url + "/orders/creatFatora.php",
  uploadImage: Url + "/orders/uploadImage.php",
  SaveMessagesSound: Url + "/orders/SaveMessagesSound.php",
  CustomerAccount: Url + "/Customer/account.php",
  Images: Url + "/",
  //--------------------------------------
  DellerOrders: Url + "/Deller/orders.php",
  DellerAccount: Url + "/Deller/account.php",
  //--------------------------------------
  updateLocatin: Url + "/puplic/UpdateLocatin.php",
  Cities: Url + "/puplic/Cities.php",
  CheckKobon: Url + "/puplic/CheckKobon.php",
};
export const ApiAdmin = Url + "/admin";
export const ApiSound = Url + "/Assits/sound/notifuctions/";
//==================================
const dateObj = new Date();
const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
const day = ("0" + dateObj.getDate()).slice(-2);
const year = dateObj.getFullYear();
export const GetDate_ = {
  FirstMonth: `${year}-${month}-${"01"}`,
  Today: `${year}-${month}-${day}`,
};
//-------------------------------------------------
export const PayMoob = {
  hmac: "ECEBEC442AEB2A8C3C2D4ACA23C84CFD",
  api_key:
    "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2TVRBd09UYzJPU3dpYm1GdFpTSTZJbWx1YVhScFlXd2lmUS5DTFNfTldNaXdmaUZvYmlqOWRXS1VqaF85X2lkaU1yOXBzVk5yVmd6bXVZdG51aFlnLUhUTTZiTTJnSHgzTVlRR0R6Nm1CSTFnYnViRnA4THgwb0wzQQ==",
  secret_key:
    "egy_sk_test_baea3eae976b89a85395263478ca8d40710de5b0b2a5c194d7e1677dcd5b3661",
  public_key: "egy_pk_test_7XVHw2ZqrE5BXozy1Vs2ny1yunNCOJKV",
  integration_id: "4891042",
  integration_processed:
    "https://accept.paymobsolutions.com/api/acceptance/post_pay",
  integration_response:
    "https://accept.paymobsolutions.com/api/acceptance/post_pay",
  iframe_id: "885128",
  // iframe_id:"885129",
};
