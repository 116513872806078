import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { AppValues, API, PayMoob } from "../Values";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../LoginCheck/page";
export default function Payment({}) {
  const navigate = useNavigate();
  const params = useParams();

  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }
  //-------------------------------------------------
  const [amountOrder, setAmountOrder] = useState(params.mony);

  const [amount, setAmount] = useState(params.mony);
  const [Token, SetToken] = useState("");
  const [PaymentKey, SetPaymentKey] = useState("");
  //-------------------------------------------------------
  const [billing_data, setbilling_data] = useState({
    first_name: userinfo[0].Name,
    last_name: "NA",
    email: userinfo[0].Email,
    phone_number: userinfo[0].Mobail,
    country: "EG",
    city: userinfo[0].City,
    state: userinfo[0].State,
    postal_code: "NA",
    street: userinfo[0].Address,
    building: "NA",
    floor: "NA",
    shipping_method: "NA",
    apartment: "NA",
  });

  // useEffect(() => {
  //   handlePayment();
  // }, []);

  async function handlePayment() {
    try {
      //==== Step 1: Authentication ========
      const authResponse = await axios.post(
        `https://accept.paymob.com/api/auth/tokens`,
        {
          api_key: PayMoob.api_key,
        }
      );
      const token = authResponse.data.token;
      console.log(authResponse);
      //-------------------------------------------------------
      //==== Step 2: Create Order  ======
      const orderResponse = await axios.post(
        `https://accept.paymob.com/api/ecommerce/orders`,
        {
          auth_token: token,
          delivery_needed: false,
          amount_cents: amount * 100, // Convert to cents
          currency: "EGP",
          items: [],
        }
      );
      const orderId = orderResponse.data.id;
      console.log(orderResponse);
      //-------------------------------------------------------
      //==== Step 3: Generate Payment Key  ======
      const paymentKeyResponse = await axios.post(
        `https://accept.paymob.com/api/acceptance/payment_keys`,
        {
          auth_token: token,
          amount_cents: amount * 100,
          expiration: 3600,
          order_id: orderId,
          billing_data: billing_data,
          currency: "EGP",
          integration_id: PayMoob.integration_id,
        }
      );
      SetPaymentKey(paymentKeyResponse.data.token);
      console.log(paymentKeyResponse);
    } catch (error) {
      console.error("Payment failed:", error);
      alert("Payment failed!");
      return error;
    }
  }
  //================================================================
  //================================================================
  const checkPaymentStatus = async (transactionId) => {
    const API_URL = `https://accept.paymob.com/api/acceptance/transactions/${transactionId}`;
    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${PayMoob.api_key}`,
        },
      });

      if (response.data) {
        const { is_success, data } = response.data;
        console.log("Payment Data:", data);
        return is_success ? "Payment Successful" : "Payment Failed";
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
      return "Error checking payment status";
    }
  };
  //================================================================
  //================================================================
  //---------------------------------------------

  function handleAmount(e) {
    setAmount(e);
    console.log(e);
    Cookies.set("VesaAmount", e, {
      expires: 1,
    });
  }

  return (
    <>
      <LoginCheck />
      <div
        dir="rtl"
        className="row mt-0 text-center0 m-auto "
        style={{ maxWidth: "1800px" }}
      >
        <div className="col-1 py-1 bg-syan ">
          <i
            className="fa-solid fa-chevron-right  mt-2 fs-4"
            onClick={() => navigate(-1)}
          ></i>
        </div>
        <div className="col-11 py-1 text-center bg-syan">
          <h4>PayMent Information </h4>
        </div>
        <br />
        {PaymentKey == "" && (
          <div className="col-lg-4 col-12 text-center mt-4 m-auto">
            <br />
            <img
              style={{ width: "100px" }}
              src="../../Images/visa.png"
              alt=""
            />
            <br />

            {amountOrder != null ? (
              <>
                <h4>دفع حساب اوردر</h4>

                <h2 className="text-danger">{`${amount} جنية مصرى`}</h2>
              </>
            ) : (
              <>
                <label className="fs-5 fw-bold">
                  {"      اكتب المبلغ المراد دفعة"}
                </label>
                <input
                  className="form-control mt-4 w-50 m-auto text-center"
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => handleAmount(e.target.value)}
                />
              </>
            )}

            <button
              className="btn btn-green mt-4 p-2 px-4 fw-bold fs-5 w-75 m-auto "
              onClick={() => handlePayment()}
            >
              {"ادفع الان"}
            </button>
          </div>
        )}
        {PaymentKey != "" && (
          <div className="col-12 payment p-0 m-0">
            <iframe
              src={`https://accept.paymob.com/api/acceptance/iframes/${PayMoob.iframe_id}?payment_token=${PaymentKey}`}
              title="PayMob Payment"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              allow="payment" // Ensures necessary permissions for payment interactions
            ></iframe>
          </div>
        )}
      </div>
    </>
  );
}
