import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
import { AppValues, API, ApiAdmin, MAPS_API_KEY } from "../../Values";
import { useState, useMemo, useEffect, useRef } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "80%",
};

const center_ = {
  lat: 30.033,
  lng: 31.233,
};

export default function DellerNavigation() {
  const navigate = useNavigate();
  const params = useParams();
  const [map, setMap] = useState(null);
  const [center, Setcenter] = useState(center_);
  const [DellerInfo, SetDellerInfo] = useState([{ Name: "", locationName: "" }]);
  const fetchIntervalRef = useRef(null);

  const libraries = useMemo(() => ["places", "geometry"], []);
  const [isLoaded, setIsLoaded] = useState(false);

  //===========Check Login ================
  LoginCheck();
  const admininfo = Cookies.get("admininfo") ? JSON.parse(Cookies.get("admininfo")) : [];
  const AdminID = admininfo.length ? admininfo[0].ID : "";

  //-------------------------------------------------------------
  // استعلام جلب معلومات المندوب
  useEffect(() => {
    GetDeller(); // أول استعلام عند تحميل المكون
  }, [params.DellerID]); // تحديث عند تغيير DellerID

  //-------------------------------------------------------------
  // جلب معلومات المندوب بشكل دوري (كل 5 ثوانٍ)
  useEffect(() => {
    fetchIntervalRef.current = setInterval(() => {
      GetDeller();
    }, 5000); // مدة التكرار بين الاستعلامات

    return () => clearInterval(fetchIntervalRef.current); // تنظيف الـ interval عند الخروج
  }, [center]); // يتم تحديث الاستعلام عندما تتغير الإحداثيات

  //-------------------------------------------------------------
  // دالة جلب معلومات المندوب
  async function GetDeller() {
    try {
      const post = {
        ID: params.DellerID,
        Action: "AdminDellerInfo",
      };
      const response = await fetch(ApiAdmin + "/deller.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();

      // تحقق مما إذا كانت الإحداثيات قد تغيرت
      const newLatitude = parseFloat(data[0].latitude);
      const newLongitude = parseFloat(data[0].longitude);

      if (newLatitude !== center.lat || newLongitude !== center.lng) {
        // إذا تغيرت الإحداثيات، قم بتحديث الإحداثيات في الحالة
        SetDellerInfo(data);
        Setcenter({
          lat: newLatitude,
          lng: newLongitude,
        });
        smoothPanTo(newLatitude, newLongitude);

        // if (map) {
        //   // تحريك الخريطة انسيابياً إلى الموقع الجديد
        //   map.panTo({
        //     lat: newLatitude,
        //     lng: newLongitude,
        //   });
        // }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
//=======================================
// دالة لتحريك الخريطة بشكل انسيابي
const smoothPanTo = (newLat, newLng) => {
  if (!map) return;

  const duration = 2000; // زمن الحركة بالمللي ثانية (2 ثانية)
  const startLat = center.lat;
  const startLng = center.lng;

  const startTime = performance.now(); // الوقت الذي يبدأ فيه التحريك

  const animate = (currentTime) => {
    const elapsedTime = currentTime - startTime; // الوقت المنقضي منذ البداية
    const progress = Math.min(elapsedTime / duration, 1); // مدى التقدم (من 0 إلى 1)

    const currentLat = startLat + (newLat - startLat) * progress;
    const currentLng = startLng + (newLng - startLng) * progress;

    map.panTo({ lat: currentLat, lng: currentLng });

    if (progress < 1) {
      requestAnimationFrame(animate); // استمر في التحديث حتى الوصول للنهاية
    } else {
      Setcenter({ lat: newLat, lng: newLng }); // تحديث المركز النهائي
    }
  };

  requestAnimationFrame(animate); // بدء الحركة
};

  //-------------------------------------------------------------
  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <>
      <Header_ />
      <div dir="rtl" className="row mt-0 text-center0 m-auto ">
        <div className="col-12 p-0 text-center bg-white">
          <h3 className="text-orange fw-bold py-1">تتبع المندوب </h3>
          <h6>{DellerInfo[0].Name}</h6>
          <small>{DellerInfo[0].locationName}</small>
        </div>
        <div className="col-master col-12 mt-0 bg-light px-1 text-center">
          <LoadScript
            googleMapsApiKey={MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleLoad}
          >
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={18}
              mapTypeId="satellite"
              onLoad={(mapInstance) => {
                setMap(mapInstance);
              }}
              onDragEnd={() => {
                const center_ = map.getCenter();
                Setcenter({
                  lat: center_.lat(),
                  lng: center_.lng(),
                });
              }}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
          <br />
        </div>
      </div>
    </>
  );
}
