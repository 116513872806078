import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

export default function Chat() {
  const navigate = useNavigate();

  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  const style_img = {
    width: "65px",
    height: "60px",
    borderRadius: "40px",
  };

  // الحالة لحفظ موقع العنصر
  const [position, setPosition] = useState({
    x: 20,
    y: window.innerHeight - 180,
  }); // بدءًا من أسفل الصفحة مع ارتفاع 100 بكسل من الأسفل
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // التعامل مع بداية السحب (عند الضغط على العنصر)
  const handleMouseDown = (event) => {
    setIsDragging(true);
    // حفظ الفرق بين موضع العنصر والنقطة التي تم الضغط عليها
    const offsetX = event.clientX - position.x;
    const offsetY = event.clientY - position.y;
    setOffset({ x: offsetX, y: offsetY });
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
    // حفظ الفرق بين موضع العنصر والنقطة التي تم الضغط عليها
    const touch = event.touches[0];
    const offsetX = touch.clientX - position.x;
    const offsetY = touch.clientY - position.y;
    setOffset({ x: offsetX, y: offsetY });
  };

  // التعامل مع حركة الماوس أثناء السحب
  const handleMouseMove = (event) => {
    if (isDragging) {
      setPosition({
        x: event.clientX - offset.x,
        y: event.clientY - offset.y,
      });
    }
  };

  // التعامل مع حركة اللمس أثناء السحب
  const handleTouchMove = (event) => {
    if (isDragging) {
      const touch = event.touches[0];
      setPosition({
        x: touch.clientX - offset.x,
        y: touch.clientY - offset.y,
      });
    }
  };

  // التعامل مع إنهاء السحب (عند رفع الماوس أو الإصبع)
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  // التأكد من تحديث الإحداثيات عند تغيير حجم النافذة
  useEffect(() => {
    const updatePosition = () => {
      setPosition((prevPosition) => ({
        ...prevPosition,
        y: window.innerHeight - 100, // إبقاء العنصر دائمًا 100px من أسفل الصفحة
      }));
    };

    window.addEventListener("resize", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  function CHatType() {
    const LoginType_ = Cookies.get("LoginType");
    if (LoginType_ == "customers") {
      navigate("../CusomerCHatShkawa/" + userinfo[0].ID);
    } else {
      navigate("../DellerCHatShkawa/" + userinfo[0].ID);
    }
  }

  return (
    <div
      onMouseDown={handleMouseDown} // بداية السحب عند الضغط على العنصر
      onTouchStart={handleTouchStart} // بداية السحب عند اللمس
      onMouseMove={handleMouseMove} // التعامل مع حركة الماوس
      onTouchMove={handleTouchMove} // التعامل مع حركة اللمس
      onMouseUp={handleMouseUp} // التعامل مع إنهاء السحب
      onTouchEnd={handleTouchEnd} // التعامل مع إنهاء السحب
      style={{
        position: "absolute",
        top: position.y, // تحريك العنصر عموديًا بناءً على السحب
        left: position.x, // تحريك العنصر أفقيًا بناءً على السحب
        zIndex: 9999, // z-index أعلى من باقي العناصر
      }}
    >
      <img
        style={style_img}
        src="../../Images/chat.jpg"
        alt="Chat Icon"
        onClick={CHatType}
      />
    </div>
  );
}
