import { Visa } from "../../Forms/payMent/visa";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import { useState, useMemo, useEffect, useRef } from "react";
import { AppValues, API } from "../../Values";
import axios from "axios";
import Cookies from "js-cookie";
import Content from "../compoenents/Content";
import Header_ from "../compoenents/Header_";
import Footer from "../compoenents/Footer";
import Chat from "../../compoenents/Chat";
import LoginCheck from "../../LoginCheckAdmin/page";

import { Modal, Button, Dropdown } from "react-bootstrap";
import "../style.css";
export default function AdminShkawa() {
  const Username = "";
  const UserID = "";
  if (Cookies.get("userinfo")) {
    const userinfo = JSON.parse(Cookies.get("userinfo"));
    const Username = userinfo[0].Name;
    const UserID = userinfo[0].ID;
  }
  const [SearchText, SetSearchText] = useState("");
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const [ShakwaNaumber, SetShakwaNaumber] = useState("");
  const [order_number, Setorder_number] = useState("");
  const [account_type, Setaccount_type] = useState("");

  const [name_id_, Setname_id_] = useState("");
  const [name_, Setname_] = useState("");

  const navigate = useNavigate();

  const [errors, Seterrors] = useState("");
  const [inputs, setInputs] = useState({
    description: "",
  });
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetShkawa();
  }, []);

  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  // جلب الرسائل بشكل دوري
  useEffect(() => {
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        GetShkawa();
      }, 5000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive]);

  //Implementing the setInterval method
  // const interval = setInterval(() => {
  //   GetShkawa();
  // }, 10000);
  //-------------------------------------
  //-------------------------------------------------------------
  async function GetShkawa() {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        statue: "",
        SearchText: SearchText,
        Action: "AdminShkawa",
      };
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data_ = await response.json();
      // console.log(data_);
      const datanew = JSON.stringify(data_);
      const dataold = JSON.stringify(Data);
      // if (datanew.length != dataold.length) {
      // if (Orders.length !== data.length) {
      if (datanew !== dataold) {
        SetData(data_);
      }
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  async function ChangeShakwa(id, order_number, account_type, name, name_id) {
    try {
      setIntervalActive(false);
      SetShakwaNaumber(id);
      Setorder_number(order_number);
      Setaccount_type(account_type);
      Setname_(name);
      Setname_id_(name_id);
      handleShowModal();
    } catch (error) {
      return error;
    }
  }

  //----------------------------------------

  //-------------------------------------------------------------
  const handleChangeSearchText = (e) => {
    setIntervalActive(false);
    SetSearchText(e.target.value);
    console.log(SearchText);
  };
  const handleTextClearSearchText = (e) => {
    SetSearchText("");
  };
  //-----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //===============================
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIntervalActive(false);
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm("هل تريد الرد على الشكوى  ")) {
      try {
        const response = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(data_),
        });
        const data = await response.json();
        if (data.errors != "") {
          return alert(data.errors);
        }
        if (data.msg == "success") {
          SetData(data.data);
          handleCloseModal();
          setIntervalActive(true);
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("Errorr 400");
        } else {
          return alert("Can Not Save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  function gotochat() {}
  return (
    <>
      <Header_ />
      <div dir="rtl" className="row p-0 m-0 mt-0 text-center">
        <h3 className="text-orange fw-bold py-1">الشكاوى</h3>

        <div className="col-12 my-2">
          <div className="div-SearchText">
            <input
              type="text"
              name="SearchText"
              className="SearchText"
              onChange={handleChangeSearchText}
              onClick={handleTextClearSearchText}
              value={SearchText}
              autoComplete="off"
              // placeholder="بحث برقم تليفون او رقم اوردر او اسم مندوب"
            />
            <button className="btn btn-warning m-0" onClick={GetShkawa}>
              Search
            </button>
          </div>
        </div>

        <div className="col-12 mt-0 bg-light0 text-center">
          <div className="table-responsive">
            <table
              dir="rtl"
              className="table table-striped  m-auto"
              cellspacing="1"
              style={{ minWidth: "920px" }}
            >
              <thead>
                <tr>
                  <th width="70px">الحالة</th>
                  {/* <th width="50px">كوبون</th> */}
                  <th width="60px">من</th>
                  <th width="40px">شات</th>
                  <th width="160px">الاسم</th>
                  <th width="170px">نوع الشكوى</th>
                  <th width="100px">الاوردر</th>
                  <th width="100px">رقم اوردر</th>
                  <th width="100px">تاريخ</th>
                  <th width="200px">البيان</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((x, index) => {
                  var classStatu = "";

                  if (x.statue == "نشطة") {
                    classStatu = "bg-warning";
                  }
                  if (x.statue != "نشطة") {
                    classStatu = "bg-green text-white";
                  }

                  return (
                    <tr key={index}>
                      <td className="text-center px-1">
                        <label
                          onClick={() =>
                            ChangeShakwa(
                              x.id,
                              x.order_number,
                              x.account_type,
                              x.name,
                              x.name_id
                            )
                          }
                          className={`w-100 rounded ${classStatu}`}
                        >
                          {x.statue}
                        </label>
                      </td>

                      {/* <td className="text-center px-1">
                          <label
                         onClick={() =>
                           ChangekOBON(x.id, x.order_number, x.account_type)
                          }
                            className={`w-100 rounded text-danger fw-bold`}
                          >
                           تعويض
                          </label>
                        </td> */}
                      <td className="text-center">
                        <i
                          class="fa-solid fa-comment-dots text-syan fs-3"
                          onClick={() =>
                            navigate(
                              "../AdminCHatShkawa/" +
                                x.order_number +
                                "/" +
                                x.name +
                                "/" +
                                x.name_id
                            )
                          }
                        ></i>
                      </td>
                      <td className="text-center">{x.account_type}</td>
                      <td>{x.name}</td>
                      <td>{x.type_shakwa}</td>
                      <td>{x.type_order}</td>
                      <td className="text-center">{x.order_number}</td>
                      <td className="text-center">{x.shakwa_date}</td>
                      <td>{x.description}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ShakwaModal />
    </>
  );
  function ShakwaModal() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <form method="POST" onSubmit={handleSubmit}>
              <input type="hidden" name="Action" value="EditeShkawa" />
              <input type="hidden" name="id" value={ShakwaNaumber} />
              <input type="hidden" name="order_number" value={order_number} />
              <input type="hidden" name="account_type" value={account_type} />
              <input type="hidden" name="name_id_" value={name_id_} />
              <input type="hidden" name="name_" value={name_} />

              <div className="row">
                <div className="col-12 text-center">
                  <h4 className="text-start fw-bold p-0 m-0"> رد على شكوى </h4>
                  <hr />
                  <span className="fs-6 fw-bold">
                    الرد على الشكوى رقم {ShakwaNaumber}
                  </span>
                  <br />
                  <span className="fs-6 fw-bold" dir="rtl">
                    {" "}
                    الخاصة بالاوردر {order_number}#
                  </span>
                  <br />
                  <input
                    type="text"
                    className="form-control mt-4"
                    name="description"
                  />
                  <br />
                  <button
                    style={{ fontSize: "14px" }}
                    className="btn btn-green fs-6 fw-bold mt-2 p-2 mx-1 px-4"
                  >
                    رد على الشكوى
                  </button>
                  <button
                    onClick={handleCloseModal}
                    style={{ fontSize: "14px" }}
                    className="btn btn-danger fs-6 fw-bold mt-2 p-2 px-4
                    mx-1"
                  >
                    الغاء
                  </button>
                  <br />
                  <br />
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
