import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API } from "../../../Values";
import axios from "axios";
import Cookies from "js-cookie";
// import Chat from "../../../compoenents/Chat";
import LoginCheck from "../../../LoginCheck/page";
import { Modal, Button, Dropdown } from "react-bootstrap";

import {
  Reviews0,
  Reviews1,
  Reviews2,
  Reviews3,
  Reviews4,
  Reviews5,
} from "../../../Reviews/page";
export default function CustomerOrderPinding() {
  const navigate = useNavigate();
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const [ModalRegisterType, SetModalRegisterType] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const [Shakwa, SetShakwa] = useState("");
  //-------------------------------------
  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }
  //--------------------------------------------
  const [AutoAccipt, SetAutoAccipt] = useState("false");
  const [OrderInfo, SetOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const [DataDelevary, SetDataDelevary] = useState([
    {
      ID: "",
      Name: "",
      Mobail: "",
      reviews: "",
    },
  ]);

  useEffect(() => {
    GetOrderInfo();
  }, []);
  //-------------------------------------------------------------
  const Params = useParams();
  async function GetOrderInfo() {
    try {
      const post = {
        number_order: Params.number_order,
        Action: "OrderInfoWitting",
      };
      console.log(post);
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await response.json();
      console.log(body);
      SetOrderInfo(body.OrderInfo);
      SetDataDelevary(body.DataDelevary);
    } catch (error) {
      return error;
    }
  }

  const handleChange = (e) => {
    SetShakwa(e);
  };

  async function handelHelp() {
    handleCloseModal();
    if (window.confirm("هل تريد ارسال شكوى للدعم")) {
      try {
        const post = {
          account_type: "عميل",
          name: Username,
          name_id: UserID,
          number_order: Params.number_order,
          type_shakwa: Shakwa,
          type_order: OrderInfo[0].type_order,
          Action: "Help",
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        navigate("../Customer/Orders");
      } catch (error) {
        return error;
      }
    }
  }

  async function CancelOrder() {
    if (
      OrderInfo[0].statue == "بانتظار العروض" ||
      OrderInfo[0].statue == "بانتظار التاكيد" ||
      OrderInfo[0].statue == "انتظار الموافقة"
    ) {
      if (window.confirm("هل تريد  الغاء الطلب")) {
        try {
          const post = {
            number_order: Params.number_order,
            description: "لست بحاجة للطلب",
            statue: "ملغى",
            Action: "CancelOrder",
          };
          const result = await fetch(API.Orders, {
            method: "POST",
            body: JSON.stringify(post),
          });
          const body = await result.json();
          console.log(body);
          navigate("../Customer/Orders");
        } catch (error) {
          return error;
        }
      }
    }
  }

  return (
    <>
      <LoginCheck />
      <div
        dir="rtl"
        className="row mt-0 text-center0 m-auto bg-light"
        style={{ maxWidth: "1000px" }}
      >
        <div className="col-12 py-1 bg-light ">
          <i
            onClick={() => navigate("../Customer/Orders")}
            className="fa-solid fa-chevron-right  mt-2 fs-4"
          ></i>
          <label className="text-center" style={{ width: "65%" }}>
            <img
              src="../../../../Images/gps.png"
              alt=""
              style={{ width: "20px" }}
            />
            <span className="fw-bold fs-5 w-50 mx-2">
              {OrderInfo[0].type_order}
            </span>
          </label>
          <span className="float-start mt-2">
            <Dropdown>
              <Dropdown.Toggle variant="warning"> المساعدة </Dropdown.Toggle>
              <Dropdown.Menu>
                {OrderInfo[0].statue != "بانتظار العروض" && (
                  <>
                    <Dropdown.Item href="#">
                      <h6
                        onClick={() =>
                          navigate(`../CustomerCHat/${Params.number_order}`)
                        }
                        className="text-center text-green fs-6 fw-bold"
                      >
                        تواصل مع المندوب
                      </h6>
                    </Dropdown.Item>
                  </>
                )}
                <Dropdown.Item href="#">
                  <h6
                    onClick={() => handleShowModal()}
                    className="text-center text-green fs-6 fw-bold"
                  >
                    تواصل مع الدعم
                  </h6>
                </Dropdown.Item>

                <Dropdown.Item href="#">
                  <h6
                    onClick={() => CancelOrder()}
                    className="text-center text-danger fs-6 fw-bold"
                  >
                    الغاء الطلب
                  </h6>
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <h6 className="text-center  fs-6 fw-bold">رجوع</h6>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>

        <div className="col-master0 col-12 m-0 mt-3 mb-4 p-2">
          <div className="row shadow0 m-0 p-1">
            <div className="col-12 p-1 text-secondary bg-white mb-2">
              <h6>
                تفاصيل الطلب : <small>{OrderInfo[0].number_order}#</small>
              </h6>
              <small>{OrderInfo[0].items}</small>
            </div>
            <PINDDING />
          </div>
          <MSG />
          <br />
        </div>
      </div>
    </>
  );

  function PINDDING() {
    return (
      <>
        <div className="col-12 p-1 text-secondary bg-white text-center">
          <h3 className="mb-4">بالطريق لاستلام الطلب</h3>
          <i className="fa-solid fa-clipboard mx-1 fs-4 fw-bold text-orange"></i>
          <span className="px-1 mx-1 fs-4 fw-bold text-green">{" ******"}</span>
          <i className="fa-solid fa-truck-fast mx-1 fs-4 fw-bold text-gray"></i>
          <span className="px-1 mx-1 fs-4 fw-bold text-gray"> ****** </span>
          <i className="fa-solid fa-location-dot mx-1 fs-4 fw-bold text-gray"></i>
          <br />
          <small className="mt-4 pt-4">
            طلبك قيد التحضير وسوف نبلغك لحظة استلامة من المندوب
          </small>
        </div>
        <div className="col-12 p-1 pt-2 pb-4 mt-2 text-secondary bg-white text-center">
          <i
            className="fa-solid fa-circle-user"
            style={{ fontSize: "50px" }}
          ></i>
          <br />
          <span className="mb-40">{DataDelevary[0].Name}</span>
          <br />
          <div className="mb-4">
            {DataDelevary[0].reviews == "0" && <Reviews0 />}
            {DataDelevary[0].reviews == "1" && <Reviews1 />}
            {DataDelevary[0].reviews == "2" && <Reviews2 />}
            {DataDelevary[0].reviews == "3" && <Reviews3 />}
            {DataDelevary[0].reviews == "4" && <Reviews4 />}
            {DataDelevary[0].reviews == "5" && <Reviews5 />}
          </div>
          <Link
            to={`tel:${DataDelevary[0].Mobail}`}
            // target="_blank"
          >
            <button className="btn btn-alert-success mx-1 fs-6 fw-bold ">
              <i className="fa-solid fa-phone mx-1"></i>
              {"اتصال"}
            </button>
          </Link>

          <Link to={`../CustomerCHat/${Params.number_order}`}>
            <button className="btn btn-alert-success mx-1 fs-6 fw-bold ">
              <i class="fa-solid fa-comment mx-1"></i>
              {"شات"}
            </button>
          </Link>

          <Link
            to={`https://www.google.com/maps?q=${DataDelevary[0].latitude},${DataDelevary[0].longitude}`}
            // target="_blank"
          >
            <button className="btn btn-alert-success mx-1 px-1 fs-6 fw-bold">
              <i className="fa-solid fa-location-arrow mx-1"></i>

              {"عرض على الخريطة"}
            </button>
          </Link>
        </div>
      </>
    );
  }

  //===============================

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h5 className="text-danger text-center p-0 m-0">
                  كيف يمكننا مساعدتك{" "}
                </h5>
                <hr />
                <h5
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                  onClick={() => handleChange("اين طلبى")}
                >
                  اين طلبى
                </h5>
                <h5
                  onClick={() => handleChange("الطلب اتاخر كتير ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1 "
                >
                  {"الطلب اتاخر كتير "}
                </h5>
                <h5
                  onClick={() => handleChange("الغاء الطلب لست بحاجة لة ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                >
                  {"الغاء الطلب لست بحاجة لة "}
                </h5>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={() => handelHelp()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
