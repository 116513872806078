// import Link from "next/link";
import { useState, useEffect } from "react";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import ButtomTab from "./ButtomTab";
import Chat from "../../compoenents/Chat";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheck/page";
import CheckAccount from "../AccountCheck/page";
import Notifications from "../../compoenents/Notifications";
import { AppValues, API, ApiUrl } from "../../Values";
import {
  Reviews0,
  Reviews1,
  Reviews2,
  Reviews3,
  Reviews4,
  Reviews5,
} from "../../Reviews/page";

export default function DellerAccount() {
  const [ClassRassed, SetClassRassed] = useState("text-green");

  //---------CHECK LOGIN------------------------------

  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }
  //-------------------------------------------------
  const reviews = userinfo[0].reviews;
  const navigate = useNavigate();
  function showmodal() {
    const AddRassedModal = document.querySelector("AddRassedModal");
    AddRassedModal.ariaModal = "show";
  }

  const LogOut = async () => {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        Action: "warking",
        warking: "متوقف",
      };
      const result = await fetch(API.DellerAccount, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await result.json();
      console.log(data);
      if (data.msg == "success") {
        Cookies.set("LoginType", "", { expires: 365 }); // Sets a cookie for 365 days
        Cookies.set("userinfo", "", { expires: 365 }); // Sets a cookie for 365 days
        Cookies.remove("userinfo");
        Cookies.remove("LoginType");
        return navigate("../");
      }
    } catch (error) {
      return error;
    }
  };
  //-------------------------------------------------\
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetOrders();
  }, []);
  async function GetOrders() {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        $account_type: "مندوب",
        statue: "",
        Action: "CalculateData",
      };
      const respons = await fetch(`${ApiUrl}/Deller/CalculateData.php`, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await respons.json();
      var raseed = parseFloat(data.raseed);
      if (raseed < 1) {
        SetClassRassed("text-danger");
      }

      SetData(data);
    } catch (error) {
      return error;
    }
  }

  function ReplaseAccount() {
    try {
      Cookies.set("LoginType", "customers", { expires: 365 }); // Sets a cookie for 365 days
      return navigate("../Customer/Account");
    } catch (error) {
      return error;
    }
  }
  //===========================================
  function GoToPayment() {
    const Vesanumber_order = {
      number_order: "0",
      type: "AddRasseMandowb",
    };
    Cookies.set("Vesanumber_order", JSON.stringify(Vesanumber_order), {
      expires: 1,
    }); // Sets a cookie for 365 days

    navigate(`../Payment/${""}`);
  }
  //==================================

  return (
    <>
      <CheckAccount />

      <div className="card  m-auto p-0" style={{ maxWidth: "500px" }}>
        <div className="card-header m-0">
          <div className="row p-0 m-0">
            <div className="col-2 text-center p-1">
              <i
                className="fa fa-user-tie bg-light text-secondary "
                style={{ fontSize: "60px", borderRadius: "60px" }}
              ></i>
            </div>
            <div className="col-10 p-0 pe-3">
              <h5 className="p-0 m-0" style={{ fontSize: "15px" }}>
                {Username}{" "}
              </h5>
              {Data.reviews == "0" && <Reviews0 />}
              {Data.reviews == "1" && <Reviews1 />}
              {Data.reviews == "2" && <Reviews2 />}
              {Data.reviews == "3" && <Reviews3 />}
              {Data.reviews == "4" && <Reviews4 />}
              {Data.reviews == "5" && <Reviews5 />}
              <br />
              <span className="text-green">
                <i style={{ fontSize: "12px" }} className="fa fa-star">
                  اظهار هوية المندوب
                </i>
              </span>
            </div>
            <div className="col-6 text-center p-0 m-0">
              <small>الطلبات الموصلة</small>
              <h6>{Data.orders}</h6>
            </div>
            <div className="col-6 text-center p-0 m-0">
              <small> اجمالى الايرادات</small>
              <h6>{Data.totalMony} جنيه</h6>
            </div>
          </div>
        </div>
        <div className="card-body p-21 ">
          <table className="table-options w-1000">
            <tbody>
              <tr onClick={() => navigate("../Deller/Orders")}>
                <td>
                  <i className="fa fa-chart-pie"> </i>
                </td>
                <td width="180px">استلام الطلب</td>
                <td></td>
                <td className="text-start ">
                  {"  قائمة الطلبات "}
                  <i className="fa-solid fa-angle-left fw-bold text-green"></i>
                </td>
              </tr>

              {/* ========================== */}
              <tr>
                <td>
                  <i className="fa fa-dollar-sign"></i>
                </td>

                <td>رصيد الحساب</td>
                <td
                  width="200px"
                  className={`text-center fw-bold  ${ClassRassed}`}
                >
                  {` ${Data.raseed} جنية `}
                </td>
                <td className="text-start">
                  {/* <Link to="../Deller/AddRassed"> */}
                 
                    <button className="btn btn-sm p-1 px-3  btn-green fw-bold" onClick={GoToPayment}>
                      اضافة +
                    </button>
                 
                </td>
              </tr>
              {/* ========================== */}
              <tr onClick={() => navigate("../Deller/Arbah")}>
                <td>
                  <i className="fab fa-cc-amazon-pay"></i>
                </td>
                <td>ارباحى</td>
                <td></td>
                <td className="text-start">
                  <i className="fa-solid fa-angle-left fw-bold fs-6 text-green"></i>
                </td>
              </tr>
              {/* ========================== */}

              <tr onClick={() => ReplaseAccount()}>
                <td>
                  <i className="fa fa-user-tie"></i>
                </td>
                <td>وضع المستخدم</td>
                <td className="text-center ">
                  <span className="text-danger fs-6 fw-bold">
                    {"وضع مندوب"}
                  </span>
                </td>
                <td className="text-start">
                  <i className="fa-solid fa-angle-left fw-bold fs-6 text-green"></i>
                </td>
              </tr>
              {/* ========================== */}
              <tr onClick={() => navigate("../Deller/Notes")}>
                <td>
                  <i className="fa fa-shekel-sign"></i>
                </td>
                <td width="180px">ملاحظات مستخدمين</td>
                <td className="text-green fw-bold text-center ">
                  {Data.notes}
                </td>
                <td className="text-start">
                  <i className="fa-solid fa-angle-left fw-bold fs-6  text-green"></i>
                </td>
              </tr>
              {/* ========================== */}

              <tr onClick={() => navigate("../Deller/Kobon")}>
                <td>
                  <i className="fa fa-industry"></i>
                </td>
                <td>
                  <span>الكوبونات </span>
                </td>
                <td className="text-green fw-bold text-center ">
                  {Data.kobbons} كوبون
                </td>
                <td className="text-start">
                  <i className="fa-solid fa-angle-left fw-bold fs-6 text-green"></i>
                </td>
              </tr>
              {/* ========================== */}
              <tr onClick={() => navigate("../Deller/Support")}>
                <td>
                  <i className="fa fa-star"></i>
                </td>
                <td>
                  <span> دعم المناديب </span>
                </td>
                <td></td>
                <td className="text-start">
                  <i className="fa-solid fa-angle-left fw-bold fs-6  text-green"></i>
                </td>
              </tr>
              {/* ========================== */}
              <tr onClick={() => navigate("../Deller/Setting")}>
                <td>
                  <i className="fa fa-tools"></i>
                </td>
                <td>
                  <span> اعدادات </span>
                </td>
                <td></td>
                <td className="text-start">
                  <i className="fa-solid fa-angle-left fw-bold fs-6  text-green"></i>
                </td>
              </tr>
              {/* ========================== */}
              <tr onClick={() => LogOut()}>
                <td>
                  <i className="fa-solid fa-right-from-bracket text-danger"></i>
                </td>
                <td>
                  <span> تسجيل الخروج </span>
                </td>
                <td></td>
                <td className="text-start" width="150px"></td>
              </tr>
              {/* ========================== */}
            </tbody>
          </table>
        </div>
      </div>
      <Chat />
      <ButtomTab />
      {/* <Chat /> */}
      {/* <Notifications account_type="مندوب" /> */}
    </>
  );
}
