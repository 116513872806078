import { Visa } from "../../Forms/payMent/visa";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import ButtomTab from "./ButtomTab";
import Chat from "../../compoenents/Chat";
import LoginCheck from "../../LoginCheck/page";
import Cookies from "js-cookie";
import Notifications from "../../../pages/compoenents/Notifications";
import { MAPS_API_KEY } from "../../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import UpdateLocatin from "../../UpdateLocation/page";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 30.033, // الموقع الافتراضي
  lng: 31.233,
};
export default function CustomerShopping() {
  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }
  //----------------------------------------------
  const navigate = useNavigate();
  //-------------------------------------------------
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  }); // الموقع الحالي للمستخدم
  const [currentLocationName, setcurrentLocationName] = useState("p");

  const [map, setMap] = useState(null);
  const searchInputRef = useRef();
  const autocompleteRef = useRef();
  const libraries = useMemo(() => ["places", "geometry"], []);
  const [isLoaded, setIsLoaded] = useState(false); // حالة تحميل الخريطة
  const [error, setError] = useState(null);
  const [locationMessage, setLocationMessage] = useState(""); // لحفظ الرسائل

  useEffect(() => {
    const savedCurentLocation = JSON.parse(
      localStorage.getItem("CurentLocation")
    );
    if (savedCurentLocation) {
      setCurrentLocation(savedCurentLocation.location);
      setcurrentLocationName(savedCurentLocation.name);
    }
    console.log(savedCurentLocation);
  }, [currentLocation]);

  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);

  useEffect(() => {
    GetMyLocation();
  }, [currentLocation]);

  useEffect(() => {
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        GetMyLocation();
      }, 5000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive]);

  function GetMyLocation() {
    const savedCurentLocation = JSON.parse(
      localStorage.getItem("CurentLocation")
    );
    if (savedCurentLocation) {
      setCurrentLocation(savedCurentLocation.location);
      setcurrentLocationName(savedCurentLocation.name);
    }
    console.log(savedCurentLocation);
  }

  /*
  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      // مراقبة الموقع باستمرار
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          fetchLocationName(latitude, longitude);

          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log("Current location:", location);
          setLocationMessage(""); // إزالة أي رسالة خطأ
        },
        (error) => {
          // تحديد سبب الخطأ بناءً على الكود
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error(
                "لم يتم السماح بالوصول إلى الموقع. يرجى تفعيل الموقع من الإعدادات."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("الموقع غير متوفر حاليًا. حاول لاحقًا.");
              break;
            case error.TIMEOUT:
              console.error(
                "انتهت مهلة محاولة الحصول على الموقع. حاول مجددًا."
              );
              break;
            default:
              console.error("حدث خطأ غير متوقع أثناء محاولة تحديد الموقع.");
          }
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: false, // استخدم دقة أقل إذا أمكن
          maximumAge: 30000, // يسمح باستخدام القيم المخزنة لمدة تصل إلى 30 ثوانٍ
          timeout: 5000, // ضبط مهلة لجلب الموقع
        }
      );
    } else {
      alert("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
      console.error("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
    }

    // تنظيف عند إلغاء المكون
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  const fetchLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      setcurrentLocationName(response.data.display_name);
    } catch (error) {
      console.error("خطأ في جلب اسم الموقع:", error);
    }
  };
*/
  const Goto = (type) => {
    if (currentLocationName == "p") {
      return alert("لا يوجد بيان بموقعك الحالى");
    }
    localStorage.removeItem("pickupLocation");
    localStorage.removeItem("distance");
    localStorage.removeItem("deliveryLocation");
    localStorage.setItem("shoppingType", JSON.stringify(type));
    localStorage.setItem("logo", "../../../Images/gps.png");
    navigate(
      `../Customer/Shopping/Search/${type}/${currentLocation.lat}/${currentLocation.lng}/${currentLocationName}`
    );
  };

  const GotoAnyThing = () => {
    localStorage.removeItem("pickupLocation");
    localStorage.removeItem("distance");
    localStorage.removeItem("deliveryLocation");
    localStorage.setItem("shoppingType", JSON.stringify("anything"));
    navigate(`../Customer/Shopping/AnyShing`);
  };

  const imgstyle = {
    width: "100%",
  };

  return (
    <>
      <UpdateLocatin />
      <div
        className="p-1 row  text-center m-auto "
        style={{ maxWidth: "500px" }}
      >
        <div className="col-12 text-end">
          <h6>السلام عليكم يا {Username}</h6>
        </div>
        <div className="col-12 px-2">
          <div className="text-end divSearch">
            <input
              type="text"
              name="Search"
              id="Search"
              className="TextSearch"
              placeholder="بحث عن اى شىء"
            />
            <span>
              <i className="fa-solid fa-location-dot mt-1 text-warning"></i>
              <label
                className="mx-2"
                style={{ maxWidth: "255px", overflowy: "scroll" }}
              >
                {currentLocationName}
              </label>
            </span>
          </div>
        </div>
      </div>

      <LoadScript
        googleMapsApiKey={MAPS_API_KEY}
        libraries={libraries}
      ></LoadScript>

      <div className="shopDiv">
        <div
          className="  row-master0 p-1 row  text-center m-auto "
          style={{ maxWidth: "500px" }}
        >
          <div className="col-6 p-1">
            <div className="bg-light p-1" onClick={GotoAnyThing}>
              <div className="div-image-home">
                <img
                  style={imgstyle}
                  src="../../../Images/anything.jpeg"
                  alt=""
                />
                <h6 className="fw-bold">وصل اى حاجة</h6>
              </div>
            </div>
          </div>
          <div className="col-6 p-1  ">
            <div className="bg-light p-1" onClick={() => Goto("restaurant")}>
              <div className="div-image-home  bg-light">
                <img style={imgstyle} src="../../../Images/food.jpg" alt="" />
                <h6 className="fw-bold"> ماكولات ومشروبات</h6>
              </div>
            </div>
          </div>

          <div className="col-6 p-1 px-1">
            <div className="bg-light p-1" onClick={() => Goto("supermarket")}>
              <div className="div-image-home">
                <img
                  style={imgstyle}
                  src="../../../Images/suppermarket.jpg"
                  alt=""
                />
                <h6 className="fw-bold"> سوير ماركت </h6>
              </div>
            </div>
          </div>
          <div className="col-6 p-1 px-1">
            <div className="bg-light p-1" onClick={() => Goto("store")}>
              <div className="div-image-home">
                <img
                  style={imgstyle}
                  src="../../../Images/image1.jpeg"
                  alt=""
                />
                <h6 className="fw-bold">المحلات التجارية</h6>
              </div>
            </div>
          </div>
          <div className="col-12 text-end p-1">
            <h6> عروضك الخاصة</h6>
            <div className="arowd py-1">
              <Link to="../Customer/Shopping/Search/استخدام كوبون">
                <img
                  className="m-1"
                  src="../../../Images/usekobon.jpg"
                  alt=""
                />
              </Link>
              <Link to="../Customer/Shopping/Search/عروض">
                <img className="m-1" src="../../../Images/arowd.jpg" alt="" />
              </Link>
              <Link to="../Customer/Shopping/Search/الاعلى تقيما">
                <img className="m-1" src="../../../Images/ok.jpg" alt="" />
              </Link>
              <Link to="../Customer/Shopping/Search/وقت القهوة">
                <img className="m-1" src="../../../Images/cofee.jpg" alt="" />
              </Link>
              <Link to="../Customer/Shopping/Search/جدبد">
                <img className="m-1" src="../../../Images/new.jpg" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-12 p-0 text-end mb-4">
            <h6>خدماتنا الاصلية</h6>

            <img
              style={{ width: "70px", height: "70px" }}
              src="../../../Images/gps.png"
              alt=""
              onClick={GotoAnyThing}
            />
            <h6>اطلب اى حاجة</h6>
          </div>
        </div>
      </div>
      <Notifications account_type="عميل" />
      <ButtomTab />

      {/* <Chat /> */}
    </>
  );
}
