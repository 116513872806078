import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function Destanse() {
  const navigate = useNavigate();
  const [errors, Seterrors] = useState("");
  const [Action, SetAction] = useState("");
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetData();
  }, []);
  //-------------------------------------------------------------
  async function GetData() {
    try {
      const post = {
        Action: "GetDestanse",
      };
      const response = await fetch(ApiAdmin + "/Destanse.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data[0]);
      setInputs(data[0]);
      SetAction("AddDestanse");
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  //----------------------------------------
  const [Inputs, setInputs] = useState([]);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //--------------------------------
  const handleDubelClick = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
  
     const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm("هل تريد الحفظ")) {
      try {
        const response = await fetch(ApiAdmin + "/Destanse.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          Seterrors(data.errors);
          handleShowModal();
        }
        if (data.msg != "") {
          Seterrors(data.msg);
          handleShowModal();
          // GetData();
          // e.target.reset();
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Header_ />
      <form method="POST" onSubmit={handleSubmit}>
        <div
          dir="rtl"
          className="row p-0 mt-0 text-end m-auto"
          style={{ maxWidth: "600px" }}
        >
          <h3 className="text-orange fw-bold py-1 mt-2">
            <Link to="../admin/Setting">
              <i className="fa-solid fa-chevron-right text-orange bg-light p-1 mx-2 fs-4"></i>
            </Link>
            {" اعدادت المسافات "}
          </h3>

          <input type="hidden" name="Action" value={Action} />
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              سعر الكيلومتر
            </label>
            <input
              type="text"
              name="km"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.km}
              maxLength={4}
            />
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              سعر اول كيلومتر
            </label>
            <input
              type="text"
              name="first_km"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.first_km}
              maxLength={4}
            />
          </div>
          
        
        
         
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              نسبة الشركة
            </label>
            <input
              type="text"
              name="nesba_company"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.nesba_company}
              maxLength={4}
            />
          </div>
          <div className="col-12 mt-4 text-center">
            <button className="btn btn-success w-50 px-4">SAVE</button>
          </div>
        </div>
      </form>
      <MSG />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">خطا </h4>
                <hr />
                <h5 className=" text-center ">{errors}</h5>
                <button
                  onClick={() => handleCloseModal()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
