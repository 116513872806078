import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { ApiUrl, API } from "../../Values";
import LoginCheck from "../../LoginCheck/page";
import Chat from "../../Forms/Chat";
import { Modal, Button, Dropdown } from "react-bootstrap";
import ErrorAudio from "../../../sound/2.wav";
import axios from "axios";
export default function DellerCHatShkawa() {
  const params = useParams();
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const [ShowModalHelep, SetShowModalHelep] = useState(false);
  const [ModalRegisterType, SetModalRegisterType] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const handleCloseModalHelep = () => SetShowModalHelep(false);
  const handleShowModalHelep = () => SetShowModalHelep(true);
  const [Shakwa, SetShakwa] = useState("");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  const [msgType, setmsgType] = useState("text");
  const audioRef = useRef(new Audio("../../sound/5.wav")); // استبدل المسار الفعلي لملف الصوت
  const chatInfo = JSON.parse(localStorage.getItem("chatInfo"));
  useEffect(() => {
    fetchMessages();
  }, []);

  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  const [OrderInfo, setOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const navigate = useNavigate();
  let [audio] = useState(new Audio(ErrorAudio));
  let [hasInteracted, setHasInteracted] = useState(false);
  let handleUserInteraction = () => {
    setHasInteracted(true);
    audio.load(); // Preload the sound to ensure playback is ready
  };

  useEffect(() => {
    fetchMessages();
  }, []);
  //-------------------------------------------------------------

  useEffect(() => {
    audio.muted = true;
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        fetchMessages();
      }, 3000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive]);

  const fetchMessages = async () => {
    const post = {
      order_id: params.number_order,
      chat_type: "shkawaDeller",
      Action: "admin",
    };
    const response = await fetch(`${ApiUrl}/orders/GetMessageShkawa.php`, {
      method: "POST",
      body: JSON.stringify(post),
    });
    const data = await response.json();
    console.log(data);
    //-------------------------
    const datanew = JSON.stringify(data.dataMessage);
    const dataold = JSON.stringify(messages);
    if (datanew != dataold) {
      setMessages(data.dataMessage);
      if (hasInteracted) {
        setHasInteracted(false);
        // audio.muted = false;
        audio
          .play()
          .catch((error) => console.error("Audio playback failed:", error));
      }
    } else {
      console.log("old data");
    }
  };
  const sendMessage = async () => {
    // audioRef.current.play();
    // handleUserInteraction();
    const post = {
      order_id: params.number_order,
      receiver_id: params.id,
      receiver_name: params.name,
      sender_id: "0",
      sender_name: "admin",
      type: "text",
      chat_type: "shkawaDeller",
      message: message,
      Action: "SaveMessage",
    };

    if (message) {
      const response = await fetch(`${ApiUrl}/orders/SaveMessages.php`, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      fetchMessages();
      setMessage("");
    }
  };

  //----------------------------------------
  //----------------------------------------
  const [isRecord, setisRecord] = useState(false);
  const Recording = async () => {
    if (isRecord) {
      setisRecord(false);
      stopRecording();
    } else {
      setisRecord(true);
      startRecording();
    }
  };

  //----------------------------------------
  // حالات التسجيل الصوتي
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("التسجيل الصوتي غير مدعوم في هذا المتصفح.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
        audioBitsPerSecond: 64000, // تقليل معدل البت لتحسين السرعة
      });
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      // mediaRecorderRef.current.ondataavailable = async (event) => {
      //   if (event.data.size > 0) {
      //     const chunkBlob = new Blob([event.data], { type: "audio/webm" });
      //     await sendAudioChunk(chunkBlob);
      //   }
      // };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/webm" });
        setAudioBlob(audioBlob); // حفظ البيانات الصوتية
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      console.log("بدأ التسجيل...");
    } catch (error) {
      console.error("خطأ أثناء تسجيل الصوت:", error);
    }
  };

  // const sendAudioChunk = async (chunkBlob) => {
  //   const formData = new FormData();
  //   formData.append("audio_chunk", chunkBlob, "chunk.webm");

  //   try {
  //     const response = await axios.post(API.UploadChunk, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });

  //     console.log("تم إرسال الجزء:", response.data);
  //   } catch (error) {
  //     console.error("خطأ أثناء رفع الجزء:", error);
  //   }
  // };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      sendAudioMessage();
      console.log("تم إيقاف التسجيل.");
    }
  };

  const sendAudioMessage = async () => {
    if (!audioBlob) {
      alert("لا يوجد ملف صوتي لإرساله.");
      return;
    }

    const formData = new FormData();
    formData.append("Action", "SaveMessage");
    formData.append("order_id", params.number_order);
    formData.append("receiver_id", params.id);
    formData.append("receiver_name", params.name);
    formData.append("sender_id", "0");
    formData.append("sender_name", "admin");
    formData.append("type", "audio");
    formData.append("audio_file", audioBlob, "recording.webm");
    formData.append("message", "audio");
    formData.append("chat_type", "shkawaDeller");
    try {
      const response = await axios.post(API.SaveMessagesSound, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Progress: ${progress}%`);
        },
      });

      // const data = await response.json();

      console.log(response.data.success);
      if (response.data.success) {
        fetchMessages(); // تحديث الرسائل
        // alert(response.data.error);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleScroll = (event) => {
    const target = event.target;
    const atBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    setIsAtBottom(atBottom);

    if (!atBottom) {
      setIntervalActive(false);
    } else {
      setIntervalActive(true);
    }
  };

  //-----------------------------------------
  // دالة لاختيار الكلمة الأولى من النص
  function change_first_word(inputText) {
    let firstWord = inputText;
    if (inputText) {
      firstWord = inputText.split(" ")[0];
    }
    return firstWord;
  }
  const handleChange = (e) => {
    SetShakwa(e);
    setMessage(e);
  };
  async function handelHelp() {
    if (!Shakwa) {
      return alert("اختار نوع الشكوى من القائمة العلوية اولا");
    }
    handleCloseModal();
    if (window.confirm("هل تريد ارسال شكوى للدعم")) {
      try {
        const post = {
          account_type: "مندوب",
          name: params.name,
          name_id: params.id,
          number_order: params.number_order,
          type_shakwa: Shakwa,
          type_order: "كل الاوردرات",
          Action: "Help",
        };
        console.log(post);
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);

        sendMessage();
        // navigate("../Customer/Orders");
      } catch (error) {
        return error;
      }
    }
  }

  const imgstyle = {
    height: "100%",
  };
  let classStatu = "";
  if (OrderInfo[0].statue == "انتظار الموافقة") {
    classStatu = "bg-orange";
  }
  if (OrderInfo[0].statue == "تم الموافقة") {
    classStatu = "bg-danger";
  }

  if (OrderInfo[0].statue == "جارى التاكيد") {
    classStatu = "bg-primary";
  }

  if (OrderInfo[0].statue == "جارى التنفيذ") {
    classStatu = "bg-orange";
  }
  if (OrderInfo[0].statue == "جارى التوصيل") {
    classStatu = "bg-syan";
  }
  if (OrderInfo[0].statue == "تم التوصيل") {
    classStatu = "bg-green";
  }

  function uploadImage() {
    var params_ = params.number_order + `/`;

    params_ += `0/`;
    params_ += `admin/`;
    params_ += params.id + `/`;
    params_ += params.name + `/`;

    params_ += "shkawaDeller";
    navigate(`../UploadImage/` + params_);
  }

  return (
    <>
      <div
        className="p-1 row  text-center m-auto"
        style={{ maxWidth: "600px" }}
      >
        <div className="col-1 py-0 text-center bg-green">
          <Link
            to={`../admin/Shkawa`}
            // to={`../Deller/OrderInfo/${params.number_order}`}
            className="float-end"
          >
            <i className="fa-solid fa-angle-right mt-3 fs-4 text-white"></i>
          </Link>
        </div>

        <div className="col-11 py-2 text-center  bg-green">
          <small dir="ltr" className="text-secondary text-white fs-4 fw-bold">
            شكاوى المندوب
          </small>
        </div>

        {/* <div className="col-1 px-1 text-center  bg-green">
          <i
            className="fa-solid fa-list  fs-4 text-white mt-3"
            onClick={() => handleShowModal()}
          ></i>
        </div> */}

        <div className="col-12 px-2 mt-1 text-center">
          <div className="row">
            {/* {audioBlob && (
              <audio controls src={URL.createObjectURL(audioBlob)}></audio>
            )} */}

            <div
              className="col-12 text-end bg-gray0 chat_msg"
              onScroll={handleScroll}
            >
              {messages.map((msg, index) => {
                const timestampDate = new Date(msg.created_at);
                const currentDate = new Date();
                // حساب الفرق بين الوقت الحالي والتاريخ الموجود في timestamp
                const timeDifference =
                  (currentDate - timestampDate) / (1000 * 60 * 60); // الفرق بالساعة
                // تحديد ما إذا كان الوقت أكبر من 24 ساعة
                const created_at =
                  timeDifference >= 24
                    ? timestampDate.toLocaleDateString()
                    : timestampDate.toLocaleTimeString();

                const class_ =
                  msg.sender_id !== "0"
                    ? "text-chatSender"
                    : "text-chatReciver";
                const class_show =
                  msg.statue != "no" ? "text-green" : "text-secondary";

                return (
                  <div key={index}>
                    {msg.type == "text" && (
                      <>
                        {msg.sender_id !== "0" ? (
                          <div className="text-start w-100">
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {msg.type == "uploadImage" && (
                      <>
                        {msg.sender_id !== "0" ? (
                          <div className="text-start w-100">
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                                                {change_first_word(receiver_name)}
                                              </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}
                    {msg.type == "audio" && (
                      <>
                        {msg.sender_id !== "0" ? (
                          <div className="text-start w-100">
                            <audio
                              controls
                              src={API.Images + msg.message}
                            ></audio>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <audio
                              controls
                              src={API.Images + msg.message}
                            ></audio>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
              {/* العنصر المخفي للتمرير التلقائي */}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-buttom">
              {isRecording && (
                <div className="recording-animation">
                  <span>🔴 جاري التسجيل...</span>
                </div>
              )}
              <label className="text-chat-div mx-1">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="اكتب رسالتك هنا..."
                  className="textchat"
                />
                <i
                  className="fa-solid fa-paper-plane ms-1"
                  onClick={() => {
                    setmsgType("text");
                    sendMessage();
                  }}
                ></i>
              </label>
              {/* <i
                className={`fa-solid fa-microphone mx-2 fs-3 ${
                  isRecording ? "text-danger" : "text-syan"
                }`}
                onClick={() => Recording()}
             
              ></i> */}
              {/* <i
                className={`fa-solid fa-camera mx-2 fs-3 ${
                  isRecording ? "text-danger" : "text-syan"
                }`}
                onClick={() => uploadImage()}
              ></i> */}
            </div>
          </div>

          {/* <Link to={"../Deller/OrderInfo/" + OrderInfo[0].number_order}>
            <label className="btn-review-order"> تحقق من تفاصيل الطلب</label>
          </Link> */}
        </div>
      </div>
      <List />
    </>
  );

  //===============================

  function List() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row mt-0 px-0">
              <div className="col-12 text-center0">
                <span className="fw-bold">نوع الشكوى</span>
                <span
                  className="fs-5 text-danger float-start"
                  onClick={() => handleCloseModal()}
                >
                  X
                </span>
                <hr />
                <h6
                  onClick={() => handleChange("طلب تعويض")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"طلب تعويض"}
                </h6>
                <h6
                  onClick={() => handleChange("تعرضت لحادث سير")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"تعرضت لحادث سير"}
                </h6>
                <h6
                  onClick={() => handleChange("موقع العميل غير صحيح")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"موقع العميل غير صحيح"}
                </h6>
                <h6
                  onClick={() => handleChange("العميل لا يستجيب")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"العميل لا يستجيب"}
                </h6>
                <h6
                  onClick={() => handleChange("يرفض العميل استلام الطلب")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"يرفض العميل استلام الطلب"}
                </h6>
                <h6
                  onClick={() => handleChange("كيف احصل على طلبات اكثر")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"كيف احصل على طلبات اكثر"}
                </h6>
                <h6
                  onClick={() => handleChange("يرغب العميل بالغاء الطلب")}
                  className="text-green0 list p-1 fs-6 fw-normal"
                >
                  {"يرغب العميل بالغاء الطلب"}
                </h6>
                <button
                  className="btn btn-danger p-1 w-100"
                  onClick={() => handelHelp()}
                >
                  تاكيد الشكوى
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
