import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { ApiUrl, API } from "../../Values";
import LoginCheck from "../../LoginCheck/page";
import Chat from "../../Forms/Chat";
import { Modal, Button, Dropdown } from "react-bootstrap";
import ErrorAudio from "../../../sound/2.wav";
import axios from "axios";
export default function CustomerCHat() {
  const params = useParams();
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const [ShowModalHelep, SetShowModalHelep] = useState(false);
  const [ModalRegisterType, SetModalRegisterType] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const handleCloseModalHelep = () => SetShowModalHelep(false);
  const handleShowModalHelep = () => SetShowModalHelep(true);
  const [Shakwa, SetShakwa] = useState("");
  const [Total, SetTotal] = useState("");

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  const [msgType, setmsgType] = useState("text");
  const audioRef = useRef(new Audio("../../sound/5.wav")); // استبدل المسار الفعلي لملف الصوت
  const chatInfo = JSON.parse(localStorage.getItem("chatInfo"));
  useEffect(() => {
    fetchMessages();
  }, []);

  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  const [OrderInfo, setOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const navigate = useNavigate();
  let [audio] = useState(new Audio(ErrorAudio));
  let [hasInteracted, setHasInteracted] = useState(false);
  let handleUserInteraction = () => {
    setHasInteracted(true);
    audio.load(); // Preload the sound to ensure playback is ready
  };

  useEffect(() => {
    fetchMessages();
  }, []);
  //-------------------------------------------------------------

  useEffect(() => {
    audio.muted = true;
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        fetchMessages();
      }, 3000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive]);

  const fetchMessages = async () => {
    const post = {
      order_id: params.number_order,
      chat_type: "order",
      Action: "customer",
    };
    const response = await fetch(`${ApiUrl}/orders/GetMessage.php`, {
      method: "POST",
      body: JSON.stringify(post),
    });
    const data = await response.json();
    setOrderInfo(data.OrderInfo);
    const total_ =
      parseInt(data.OrderInfo[0].safee) +
      parseInt(data.OrderInfo[0].items_price) +
      parseInt(data.OrderInfo[0].tips);
    SetTotal(total_);
    const datanew = JSON.stringify(data.dataMessage);
    const dataold = JSON.stringify(messages);
    if (datanew != dataold) {
      setMessages(data.dataMessage);
      if (hasInteracted) {
        setHasInteracted(false);
        // audio.muted = false;
        audio
          .play()
          .catch((error) => console.error("Audio playback failed:", error));
      }
    } else {
      console.log("old data");
    }
  };

  const sendMessage = async () => {
    // audioRef.current.play();
    // handleUserInteraction();
    const post = {
      order_id: params.number_order,
      sender_id: OrderInfo[0].customer_id,
      sender_name: OrderInfo[0].customer_name,
      receiver_id: OrderInfo[0].delevary_id,
      receiver_name: OrderInfo[0].delevary_name,
      chat_type: "order",
      type: "text",
      message: message,
      Action: "SaveMessage",
    };

    if (message || audioBlob) {
      const response = await fetch(`${ApiUrl}/orders/SaveMessages.php`, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      fetchMessages();
      setMessage("");
      setAudioBlob(null);
    }
  };
  //----------------------------------------
  // حالات التسجيل الصوتي
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("التسجيل الصوتي غير مدعوم في هذا المتصفح.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/webm" });
        setAudioBlob(audioBlob); // حفظ البيانات الصوتية
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      console.log("بدأ التسجيل...");
    } catch (error) {
      console.error("خطأ أثناء تسجيل الصوت:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      sendAudioMessage();
      console.log("تم إيقاف التسجيل.");
    }
  };

  const startRecording0 = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("التسجيل الصوتي غير مدعوم في هذا المتصفح.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        setAudioBlob(event.data);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      console.log("بدأ التسجيل...");
    } catch (error) {
      console.log("حدث خطأ أثناء تسجيل الصوت. تأكد من منح الأذونات.");
    }
  };

  const sendAudioMessage = async () => {
    if (!audioBlob) {
      alert("لا يوجد ملف صوتي لإرساله.");
      return;
    }

    const formData = new FormData();
    formData.append("Action", "SaveMessage");
    formData.append("order_id", params.number_order);
    formData.append("sender_id", OrderInfo[0].delevary_id);
    formData.append("sender_name", OrderInfo[0].delevary_name);
    formData.append("receiver_id", OrderInfo[0].customer_id);
    formData.append("receiver_name", OrderInfo[0].customer_name);
    formData.append("type", "audio");
    formData.append("audio_file", audioBlob, "recording.webm");
    formData.append("message", "audio");
    formData.append("chat_type", "order");
    try {
      const response = await axios.post(API.SaveMessagesSound, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Progress: ${progress}%`);
        },
      });

      // const data = await response.json();

      console.log(response.data.success);
      if (response.data.success) {
        alert(response.data.error);
      } else {
        console.error(response.data.error);
        fetchMessages(); // تحديث الرسائل
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleScroll = (event) => {
    const target = event.target;
    const atBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    setIsAtBottom(atBottom);

    if (!atBottom) {
      setIntervalActive(false);
    } else {
      setIntervalActive(true);
    }
  };

  //-----------------------------------------
  // دالة لاختيار الكلمة الأولى من النص
  function change_first_word(inputText) {
    let firstWord = inputText;
    if (inputText) {
      firstWord = inputText.split(" ")[0];
    }
    return firstWord;
  }
  const handleChange = (e) => {
    SetShakwa(e);
  };
  async function handelHelp() {
    handleCloseModal();
    if (window.confirm("هل تريد ارسال شكوى للدعم")) {
      try {
        const post = {
          account_type: "عميل",
          name: Username,
          name_id: UserID,
          number_order: params.number_order,
          type_shakwa: Shakwa,
          type_order: OrderInfo[0].type_order,
          Action: "Help",
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        navigate("../Customer/Orders");
      } catch (error) {
        return error;
      }
    }
  }

  async function CancelOrder() {
    if (
      OrderInfo[0].statue == "بانتظار العروض" ||
      OrderInfo[0].statue == "بانتظار التاكيد" ||
      OrderInfo[0].statue == "انتظار الموافقة"
    ) {
      if (window.confirm("هل تريد  الغاء الطلب")) {
        try {
          const post = {
            number_order: params.number_order,
            description: "لست بحاجة للطلب",
            statue: "ملغى",
            Action: "CancelOrder",
          };
          const result = await fetch(API.Orders, {
            method: "POST",
            body: JSON.stringify(post),
          });
          const body = await result.json();
          console.log(body);
          navigate("../Customer/Orders");
        } catch (error) {
          return error;
        }
      }
    }
  }
  //-------------------------------------------------------------
  async function EditeOrder(Edite) {
    // if (admininfo[0].add != "on") {
    //   var Edite_ = `هل تريد  ${Edite}  الاوردر`;
    //   if (window.confirm(Edite_)) {
    //     try {
    //       const post = {
    //         number_order: number_order,
    //         Edite: Edite,
    //         Action: "EditeOrder",
    //       };
    //       const response = await fetch(ApiAdmin + "/orders.php", {
    //         method: "POST",
    //         body: JSON.stringify(post),
    //       });
    //       const data = await response.json();
    //       console.log(data);
    //       handleCloseModal();
    //       GetOrders();
    //     } catch (error) {
    //       return error;
    //     }
    //   }
    // } else {
    //   alert("عفوا ليس لك الصلاحية بهذة العملية");
    // }
  }

  function GoToPayment() {
    const Vesanumber_order = {
      number_order: params.number_order,
      type: "sdadOrder",
    };
    Cookies.set("Vesanumber_order", JSON.stringify(Vesanumber_order), {
      expires: 1,
    }); // Sets a cookie for 365 days

    navigate(`../Payment/${Total}`);
  }

  const imgstyle = {
    height: "100%",
  };
  let classStatu = "";
  if (OrderInfo[0].statue == "انتظار الموافقة") {
    classStatu = "bg-orange";
  }
  if (OrderInfo[0].statue == "تم الموافقة") {
    classStatu = "bg-danger";
  }

  if (OrderInfo[0].statue == "جارى التاكيد") {
    classStatu = "bg-primary";
  }

  if (OrderInfo[0].statue == "جارى التنفيذ") {
    classStatu = "bg-orange";
  }
  if (OrderInfo[0].statue == "جارى التوصيل") {
    classStatu = "bg-syan";
  }
  if (OrderInfo[0].statue == "تم التوصيل") {
    classStatu = "bg-green";
  }

  function uploadImage() {
    var params_ = OrderInfo[0].number_order + `/`;
    params_ += userinfo[0].ID + `/`;
    params_ += userinfo[0].Name + `/`;
    params_ += OrderInfo[0].delevary_id + `/`;
    params_ += OrderInfo[0].delevary_name+ `/`;
    params_ += "order";
    navigate(`../UploadImage/` + params_);
  }

  return (
    <>
      <div
        className="p-1 row  text-center m-auto"
        style={{ maxWidth: "600px" }}
      >
        <div className="col-1 py-0 text-center">
          <Link
            to={`../Customer/Orders`}
            // to={`../Deller/OrderInfo/${params.number_order}`}
            className="float-end"
          >
            <i className="fa-solid fa-angle-right mt-3 fs-4 text-info"></i>
          </Link>
        </div>

        <div className="col-10 py-2 text-center">
          <span className="fs-6 fw-bold"> {OrderInfo[0].type_order}</span>
          <br />

          <small className={`rounded px-2 mx-1 text-white ${classStatu}`}>
            {OrderInfo[0].statue}
          </small>
          <small dir="ltr" className="text-secondary">
            #{OrderInfo[0].number_order}
          </small>
        </div>

        <div className="col-1 px-1 text-center">
          <i
            className="fa-solid fa-list  fs-4 text-info mt-3"
            onClick={() => handleShowModal()}
          ></i>
        </div>

        <div className="col-1 bg-syan-light p-1 text-center">
          <i className="fa-solid fa-user fs-2 text-syan mt-3"></i>
        </div>
        <div className="col-8 py-2 bg-syan-light mb-0 text-end">
          <span>
            {change_first_word(OrderInfo[0].delevary_name)}
            <br />
            <small className="text-syan fw-bold">
              {`المجموع ` + Total + " جنية "}
            </small>

            {OrderInfo[0].payment_type == "بطاقة بنكية" && (
              <img
                src="../../../Images/visa.png"
                alt="visa"
                className="me-1"
                style={{ width: "40px" }}
                onClick={() => GoToPayment(Total)}
              />
            )}
          </span>
        </div>

        <div className="col-3 bg-syan-light p-0 pt-3 text-center">
          {/* <Link to={"../Deller/OrderInfo/" + OrderInfo[0].number_order}>
            <button className="btn btn-syan mx-1 fs-6 fw-bold btn-call mt-1">
              <i className="fa-solid fa-info"></i>
            </button>
          </Link>
          <Link to={`tel:${OrderInfo[0].mobail}`} target="_blank">
            <button className="btn btn-syan mx-1 fs-6 fw-bold btn-call mt-1">
              <i className="fa-solid fa-phone"></i>
            </button>
          </Link> */}
        </div>

        <div className="col-12 px-2 mt-1 text-center">
          <div className="row">
            {/* {audioBlob && (
              <audio controls src={URL.createObjectURL(audioBlob)}></audio>
            )} */}

            <div
              className="col-12 text-end bg-gray chat_msg"
              onScroll={handleScroll}
            >
              {messages.map((msg, index) => {
                const timestampDate = new Date(msg.created_at);
                const currentDate = new Date();
                // حساب الفرق بين الوقت الحالي والتاريخ الموجود في timestamp
                const timeDifference =
                  (currentDate - timestampDate) / (1000 * 60 * 60); // الفرق بالساعة
                // تحديد ما إذا كان الوقت أكبر من 24 ساعة
                const created_at =
                  timeDifference >= 24
                    ? timestampDate.toLocaleDateString()
                    : timestampDate.toLocaleTimeString();

                const class_ =
                  msg.sender_id === OrderInfo[0].delevary_id
                    ? "text-chatSender"
                    : "text-chatReciver";
                const class_show =
                  msg.statue != "no" ? "text-green" : "text-secondary";

                return (
                  <div key={index}>
                    {msg.type == "fatora" && (
                      <>
                        <div className="text-start">
                          <label
                            className="bg-white p-2 text-end "
                            style={{
                              maxHeight: "300px",
                              border: "2px solid #7FD7EB",
                              marginTop: "10px",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <small className="fw-bold">
                              {"    تم اصدار الفاتورة بواسطة "}
                              {OrderInfo[0].delevary_name}
                            </small>
                            <hr />
                            <small>
                              {" قيمة الطلب : "}
                              {parseFloat(OrderInfo[0].items_price, 2)}
                              {" جنية "}
                            </small>
                            <br />
                            <small>
                              {" قيمة التوصيل : "}
                              {parseFloat(OrderInfo[0].safee, 2)}
                              {" جنية "}
                            </small>
                            <br />
                            <span className="fw-bold">
                              {" المبلغ المستحق : "}
                              {parseFloat(OrderInfo[0].items_price, 2) +
                                parseFloat(OrderInfo[0].safee, 2)}
                              {" جنية "}
                            </span>
                          </label>
                        </div>
                        <div className="text-start w-100">
                          <img
                            src={API.Images + msg.message}
                            alt=""
                            style={{
                              width: "80%",
                              maxHeight: "300px",
                              border: "2px solid #7FD7EB",
                              marginTop: "10px",
                              borderRadius: "10px",
                              padding: "5px",
                            }}
                          />
                          <br />

                          <i
                            style={{ fontSize: "12px" }}
                            className={`fa-solid fa-check  ${class_show}`}
                          ></i>
                          <small
                            dir="ltr"
                            className="mb-4 px-1"
                            style={{ fontSize: "12px" }}
                          >
                            {created_at}
                          </small>
                          <i
                            style={{ fontSize: "12px" }}
                            className={`fa-solid fa-user  text-info`}
                          ></i>
                          <br />
                        </div>
                      </>
                    )}
                    {msg.type == "text" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {msg.type == "uploadImage" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}
                    {msg.type == "audio" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <audio
                              controls
                              src={API.Images + msg.message}
                            ></audio>

                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {msg.created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <audio
                              controls
                              src={API.Images + msg.message}
                            ></audio>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {msg.created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
              {/* العنصر المخفي للتمرير التلقائي */}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-buttom">
              {isRecording && (
                <div className="recording-animation">
                  <span>🔴 جاري التسجيل...</span>
                </div>
              )}
              <label className="text-chat-div mx-1">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="اكتب رسالتك هنا..."
                  className="textchat"
                />
                <i
                  className="fa-solid fa-paper-plane ms-1"
                  onClick={() => {
                    setmsgType("text");
                    sendMessage();
                  }}
                ></i>
              </label>
              <i
                className={`fa-solid fa-microphone mx-2 fs-1 ${
                  isRecording ? "text-danger" : "text-syan"
                }`}
                // onClick={() => sendAudioMessage()}

                onMouseDown={() => startRecording()}
                onMouseUp={() => stopRecording()}
                onTouchStart={() => startRecording()} // للأجهزة التي تعمل باللمس
                onTouchEnd={() => stopRecording()} // للأجهزة التي تعمل باللمس
              ></i>
              <i
                className={`fa-solid fa-camera mx-2 fs-3 ${
                  isRecording ? "text-danger" : "text-syan"
                }`}
                onClick={() => uploadImage()}
              ></i>
            </div>
          </div>

          {/* <Link to={"../Deller/OrderInfo/" + OrderInfo[0].number_order}>
            <label className="btn-review-order"> تحقق من تفاصيل الطلب</label>
          </Link> */}
        </div>
      </div>
      <List />
    </>
  );

  //===============================

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModalHelep}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h5 className="text-danger text-center p-0 m-0">
                  كيف يمكننا مساعدتك{" "}
                </h5>
                <hr />
                <h5
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                  onClick={() => handleChange("اين طلبى")}
                >
                  اين طلبى
                </h5>
                <h5
                  onClick={() => handleChange("الطلب اتاخر كتير ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1 "
                >
                  {"الطلب اتاخر كتير "}
                </h5>
                <h5
                  onClick={() => handleChange("الغاء الطلب لست بحاجة لة ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                >
                  {"الغاء الطلب لست بحاجة لة "}
                </h5>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={() => handelHelp()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  function List() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModalHelep}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h5 className="text-dark p-2">{"      كيف يمكننا مساعدتك "}</h5>
                <hr />
                <h5
                  style={{ fontSize: "15px" }}
                  className="text-green list p-2"
                  onClick={() => handleChange("اين طلبى")}
                >
                  اين طلبى
                </h5>
                <h5
                  onClick={() => handleChange("الطلب اتاخر كتير ")}
                  style={{ fontSize: "15px" }}
                  className="text-green list p-2"
                >
                  {"الطلب اتاخر كتير "}
                </h5>
                <h5
                  onClick={() => handleChange("الغاء الطلب لست بحاجة لة ")}
                  style={{ fontSize: "15px" }}
                  className="text-green list p-2"
                >
                  {"الغاء الطلب لست بحاجة لة "}
                </h5>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={() => handelHelp()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
                <h5
                  style={{ fontSize: "14px" }}
                  onClick={() => handleCloseModal()}
                  className=" list p-2"
                >
                  تراجع
                </h5>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
