import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { API, ApiUrl, MAPS_API_KEY } from "../../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Iconlocation1 from "../../../Images/location1.png";
import Iconlocation2 from "../../../Images/location2.png";
import Iconlocation3 from "../../../Images/location3.png";
import UpdateLocatin from "../../UpdateLocation/page";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 30.033, // الموقع الافتراضي
  lng: 31.233,
};

export default function OrderAddOffer() {
  const [OrderAddOffer, setOrderAddOffer] = useState([]);
  const [offer, setoffer] = useState("");
  const [pickupLocation, setPickupLocation] = useState(null); // نقطة الاستلام
  const [deliveryLocation, setDeliveryLocation] = useState(null);
  const [curentLocation, setCurentLocation] = useState(center);

  const [delevaryRassed, SetdelevaryRassed] = useState(null);
  const [deliveryName, setDeliveryName] = useState("");
  const [isLoaded, setIsLoaded] = useState(false); // حالة تحميل الخريطة
  const [distance, setDistance] = useState("0"); // المسافة بين نقطتي الاستلام والتسليم
  const [bgstatue, setbgstatue] = useState("bg-green");
  const [bgstatuedark, setbgstatuedark] = useState("bg-greenDark");
  const [offer_, setoffer_] = useState("");
  const [offer_low, setoffer_low] = useState("");
  const [offer_top, setoffer_top] = useState("");
  const [offerstat, setofferstat] = useState("");

  const [map, setMap] = useState(null);
  const searchInputRef = useRef();
  const autocompleteRef = useRef();
  const navigate = useNavigate();
  const libraries = useMemo(() => ["places", "geometry"], []);

  const [tips, settips] = useState("0");
  useEffect(() => {
    const savedOrderAddOffer = JSON.parse(
      localStorage.getItem("OrderAddOffer")
    );
    if (savedOrderAddOffer) {
      setOrderAddOffer(savedOrderAddOffer.post);
      if (savedOrderAddOffer.post.Action != "other") {
        setoffer(savedOrderAddOffer.post.delevary_price);
        settips(savedOrderAddOffer.post.tips);
      }

      setoffer_(
        ` قدم عرض  من ${String(
          parseInt(savedOrderAddOffer.post.delevary_price - 20)
        )} -  ${String(parseInt(savedOrderAddOffer.post.delevary_price))}`
      );

      setofferstat(
        ` قدم عرض  من ${String(
          parseInt(savedOrderAddOffer.post.delevary_price - 20)
        )} -  ${String(parseInt(savedOrderAddOffer.post.delevary_price))}`
      );

      setoffer_low(
        `  لا يمكن تقديم عرض اقل من  ${String(
          parseInt(savedOrderAddOffer.post.delevary_price - 20)
        )} جنية`
      );
      setoffer_top(
        `    لا يمكن تقديم عرض اكبر من  ${String(
          parseInt(savedOrderAddOffer.post.delevary_price)
        )} جنية`
      );

      setPickupLocation({
        lat: parseFloat(savedOrderAddOffer.post.from_latitude),
        lng: parseFloat(savedOrderAddOffer.post.from_longitude),
      });
      setDeliveryLocation({
        lat: parseFloat(savedOrderAddOffer.post.to_latitude),
        lng: parseFloat(savedOrderAddOffer.post.to_longitude),
      });
    }
  }, []);

  //==========================================
  //==========================================

  //==========================================
  //==========================================
  //==========================================

  useEffect(() => {
    if (window.google && window.google.maps) {
      const input = searchInputRef.current;
      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocompleteRef.current = autocomplete;
      // عند تحديد مكان من الـ  Autocomplete
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          const location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          getPlaceName(location.lat, location.lng);
          console.log(location);
          console.log(pickupLocation);
          // حساب المسافة بين نقطتي الاستلام والتسليم
          if (pickupLocation) {
            calculateDistance(pickupLocation, location);
          }
          // تحريك الخريطة إلى الموقع المحدد
          if (map) {
            map.panTo(location); // تحريك الخريطة إلى المكان المحدد
            map.setZoom(14); // تحديد مستوى التكبير عند النقطة
          }
        }
      });
    }
  }, [map]);

  const handleMapClick = async (event) => {
    const latLng = event.latLng;
    const location = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    getPlaceName(location.lat, location.lng);
    console.log(pickupLocation);
    // حساب المسافة بين نقطتي الاستلام والتسليم
    if (pickupLocation) {
      calculateDistance(pickupLocation, location);
    }
    // تحريك الخريطة إلى الموقع المحدد
    if (map) {
      map.panTo(location); // تحريك الخريطة إلى المكان المحدد
      map.setZoom(14); // تحديد مستوى التكبير عند النقطة
    }
  };

  const getPlaceName = (lat, lng) => {
    const location = {
      lat: lat,
      lng: lng,
    };
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setDeliveryName(results[0].formatted_address);
        setCurentLocation(location);
        console.log(results[0].formatted_address);
        localStorage.setItem(
          "deliveryLocation",
          JSON.stringify({ location, name: results[0].formatted_address })
        );
        if (pickupLocation) {
          calculateDistance(pickupLocation, location);
        }
      }
    });
  };

  // دالة حساب المسافة بين نقطتين
  const calculateDistance = (pickup, delivery) => {
    const R = 6371e3; // نصف قطر الأرض بالأمتار
    const φ1 = (pickup.lat * Math.PI) / 180; // تحويل درجة العرض الأولى إلى راديان
    const φ2 = (delivery.lat * Math.PI) / 180; // تحويل درجة العرض الثانية إلى راديان
    const Δφ = ((delivery.lat - pickup.lat) * Math.PI) / 180; // فرق العرض بالراديان
    const Δλ = ((delivery.lng - pickup.lng) * Math.PI) / 180; // فرق الطول بالراديان

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceInMeters = R * c; // المسافة بالأمتار
    const distanceInKilometers = distanceInMeters / 1000; // المسافة بالكيلومترات

    // تحديد الوحدات وعرض النتيجة
    let result;
    if (distanceInKilometers >= 1) {
      result = `${distanceInKilometers.toFixed(2)} كم`; // إذا كانت المسافة بالكيلومترات
    } else {
      result = `${distanceInMeters.toFixed(0)} م`; // إذا كانت المسافة بالأمتار
    }

    console.log("Distance: " + result);
    setDistance(result); // تخزين المسافة
    // حفظ اdistance__لبيانات في localStorage
    localStorage.setItem("distance", JSON.stringify({ distance: result }));
    return result; // النتيجة مع الوحدات
  };

  const calculateDistance0 = (pickup, delivery) => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [pickup],
        destinations: [delivery],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          const distance_ = response.rows[0].elements[0].distance.text;
          setDistance(distance_); // تخزين المسافة
          // حفظ البيانات في localStorage
          localStorage.setItem(
            "distance",
            JSON.stringify({ distance: distance_ })
          );
        } else {
          alert("حدث خطأ أثناء حساب المسافة.");
        }
      }
    );
  };

  // تحميل الخريطة وتغيير حالة isLoaded عند الانتهاء
  const handleLoad = () => {
    if (pickupLocation) {
      calculateDistance(pickupLocation, curentLocation);
    }
    setIsLoaded(true); // الخريطة أصبحت جاهزة
  };
  //-----------------------------------------
  const params = useParams();
  const imgstyle = {
    height: "100%",
  };
  // دالة لاختيار الكلمة الأولى من النص
  function change_first_word(inputText) {
    let firstWord = inputText;
    if (inputText) {
      firstWord = inputText.split(" ")[0];
    }
    return firstWord;
  }

  function handelChange(e) {
    setoffer(e);
    const cost_delevaryFrom = parseInt(OrderAddOffer.cost_delevaryFrom);
    const delevary_price = parseInt(OrderAddOffer.delevary_price);
    if (parseInt(e) < parseInt(cost_delevaryFrom)) {
      setbgstatue("bg-danger");
      setbgstatuedark("bg-red-dark");
      setofferstat(offer_low);
    } else if (parseInt(e) > parseInt(delevary_price)) {
      setbgstatue("bg-danger");
      setbgstatuedark("bg-red-dark");
      setofferstat(offer_top);
    } else {
      setbgstatue("bg-green");
      setbgstatuedark("bg-greenDark");
      setofferstat(offer_);
    }
    if (e == "") {
      setofferstat(offer_);
    }
  }

  //======================================

  //==========================================

  //======================================

  async function AddOfer() {
    try {
      if (offer == "" || offer == "0") {
        return alert("الرجاء كتابة قيمة العرض ");
      }
      const cost_delevaryFrom = parseInt(OrderAddOffer.cost_delevaryFrom);
      const delevary_price = parseInt(OrderAddOffer.delevary_price);
      if (parseFloat(offer) < parseFloat(cost_delevaryFrom)) {
        setbgstatue("bg-danger");
        return alert("  قيمة العرض اقل من المطلوب ");
      } else if (parseFloat(offer) > parseFloat(delevary_price)) {
        setbgstatue("bg-danger");
        return alert("  قيمة العرض اكبر من المطلوب ");
      } else {
        setbgstatue("bg-green");
      }

      const post = {
        ID: OrderAddOffer.ID,
        Name: OrderAddOffer.Name,
        lat: curentLocation.lat,
        lng: curentLocation.lng,
        delevary_price: offer,
        number_order: OrderAddOffer.number_order,
        Action: "AddOfers",
        auto_accipt: OrderAddOffer.auto_accipt,
      };
      console.log(post);
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      if (data.error != "") {
        return alert(data.error);
      }
      navigate(`../DellerCHat/` + OrderAddOffer.number_order);
    } catch (error) {
      return error;
    }
  }

  return (
    <>
      <UpdateLocatin />
      <div
        className="p-1 row  text-center m-auto "
        style={{ maxWidth: "600px" }}
      >
        {/* OrderAddOffer.items */}

        <div className="col-12 py-2 text-center bg-light mb-0">
          <span className="fs-6 fw-bold"> {OrderAddOffer.type_order} </span>
          <Link to="../Deller/home" className="float-start">
            <i className="fa-solid fa-xmark  fs-4 "></i>
          </Link>
        </div>
        <div className="col-8 py-2 bg-syan-light mb-0 text-end">
          <i className="fa-solid fa-user fs-1 text-info mx-2"></i>
          <span>{change_first_word(OrderAddOffer.customer_name)} </span>
        </div>

        <div className="col-4 py-2 bg-syan-light mb-0 text-end">
          {tips != "0" && (
            <>

              <small className="fw-bold bg-red text-red">
                <i class="fa-solid fa-star mx-1 text-warning"></i>

                هدية {tips} جنية


              </small>
            </>
          )}
        </div>
        <div className="col-12 py-1 mb-0 text-end">
          <span className="fw-bold"> تفاصيل الطلب</span>
          <small dir="ltr" className="text-secondary float-start">
            #{OrderAddOffer.number_order}
          </small>
          <br />
          <span>{OrderAddOffer.items} </span>
        </div>
      </div>
      <div
          className="col-master row m-auto p-1"
          style={{ maxWidth: "600px", maxHeight: "650px" }}
        >
        <div className="col-12 p-1 div-mapaddOffers bg-light mb-0">
          <LoadScript
            googleMapsApiKey={MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleLoad} // تعيين isLoaded على true بعد تحميل المكتبة
          >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                  lat: deliveryLocation.lat,
                  lng: deliveryLocation.lng,
                }}
                zoom={1} // بدء التكبير من المستوى 14
                // mapTypeId="satellite" 
                onClick={handleMapClick} // عند النقر على الخريطة
                onLoad={(mapInstance) => {
                  setMap(mapInstance);
                  let currentZoom = 1; // مستوى التكبير المبدئي
                  const maxZoom = 14; // مستوى التكبير النهائي
                  const interval = setInterval(() => {
                    if (currentZoom < maxZoom) {
                      currentZoom += 1;
                      mapInstance.setZoom(currentZoom); // ضبط مستوى التكبير
                    } else {
                      clearInterval(interval); // إيقاف المؤقت عند الوصول إلى المستوى المطلوب
                    }
                  }, 50); // تحديث كل ثانية (يمكنك تغيير المدة حسب الحاجة)
                }}
              >
                {curentLocation && (
                  <Marker
                    position={curentLocation}
                    icon={{
                      url: Iconlocation1,
                      scaledSize: new window.google.maps.Size(50, 30),
                    }}
                    label={{
                      text: "أنا",
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  />
                )}

                {pickupLocation && (
                  <Marker
                    position={pickupLocation}
                    icon={{
                      url: Iconlocation2,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    label={{
                      text: "استلام",
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  />
                )}
                {deliveryLocation && (
                  <Marker
                    position={deliveryLocation}
                    icon={{
                      url: Iconlocation3,
                      scaledSize: new window.google.maps.Size(30, 30),
                    }}
                    label={{
                      text: "تسليم",
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  />
                )}
              </GoogleMap>
            ) : (
              <div>الخريطة في مرحلة التحميل... يرجى الانتظار</div>
            )}
          </LoadScript>
        </div>
        <div className="col-6 py-2  mb-4 text-end">
          <Link
            to={`https://www.google.com/maps?q=${OrderAddOffer.from_latitude},${OrderAddOffer.from_longitude}`}
          >
            <i className="fa-solid fa-home bg-green text-white p-1 mx-1"></i>
            <span>{OrderAddOffer.mylocationCount} </span>
            <br />
            <small className="fw-bold">موقع الاستلام</small>
            <br />
            <small>{OrderAddOffer.from_plase} </small>
          </Link>
          <br /><br /><br /><br />
        </div>

        <div className="col-6 py-1  mb-4 text-end">
          <Link
            to={`https://www.google.com/maps?q=${OrderAddOffer.to_latitude},${OrderAddOffer.to_longitude}`}
          >
            <i className="fa-solid fa-flag bg-info text-white p-1 mx-1"></i>
            <span>{OrderAddOffer.distance} </span>
            <br />
            <small className="fw-bold"> التوصيل</small>
            <br />
            <small>{OrderAddOffer.to_plase} </small>
          </Link>
          <br /><br /><br /><br />
        </div>

      </div>
      <div className="row  buttom-div">
        <div className={`col-12 my-0 p-2 text-center text-white  ${bgstatue}`}>
          <h5> تاكيد العرض</h5>
          {OrderAddOffer.Action == "other" && <span>{offerstat} </span>}
          <div className={`row p-1 m-0 mt-2  ${bgstatuedark}`}>
            <div className="col-10 p-0">
              {OrderAddOffer.Action == "other" ? (
                <input
                  type="number"
                  name="offer"
                  value={offer}
                  className={`w-100 text-center  ${bgstatuedark}`}
                  onChange={(e) => handelChange(e.target.value)}
                  style={{ outline: "0px", border: "0px" }}
                />
              ) : (
                <label className="text-white w-100 fs-6 pt-2 px-4 bg-success text-start">
                  {offer}
                </label>
              )}
            </div>
            <div className="col-2 p-0">
              <span
                className={`btn btn-success fw-bold px-2 ${bgstatue}`}
                onClick={() => AddOfer()}
              >
                {" ارسال "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
