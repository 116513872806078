import React, { createContext, useContext, useEffect, useState } from "react";

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [audioAllowed, setAudioAllowed] = useState(false);
  const [audio] = useState(new Audio("/2.wav")); // مسار ملف الصوت

  const enableAudio = () => {
    audio.play().catch(() => {}); // محاولة تشغيل الصوت لتفعيل الإذن
    setAudioAllowed(true);
  };

  return (
    <AudioContext.Provider value={{ audio, audioAllowed, enableAudio }}>
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);
