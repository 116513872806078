import { Visa } from "../../Forms/payMent/visa";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";

import { useState, useMemo, useEffect, useRef } from "react";
import { API, MAPS_API_KEY } from "../../Values";
import ButtomTab from "./ButtomTab";
import axios from "axios";
import Cookies from "js-cookie";
import Chat from "../../compoenents/Chat";
import LoginCheck from "../../LoginCheck/page";
import CheckAccount from "../AccountCheck/page";
import Notifications from "../../compoenents/Notifications";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import UpdateLocatin from "../../UpdateLocation/page";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 30.033, // الموقع الافتراضي
  lng: 31.233,
};
export default function DellerHome() {
  const navigate = useNavigate();
  //--------- CHECK LOGIN ------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  var CityWork = "";
  var UserID = "";
  var warking = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
    CityWork = userinfo[0].CityWork;
    warking = userinfo[0].warking;
  }
  //-------------------------------------------------
  const [currentLocation, setCurrentLocation] = useState(center); // الموقع الحالي للمستخدم
  const [pickupLocation, setPickupLocation] = useState(center); // نقطة الاستلام
  const [distance, setDistance] = useState("0"); // المسافة بين نقطتي الاستلام والتسليم
  const libraries = useMemo(() => ["places", "geometry"], []);
  const [Orders, SetOrders] = useState([]);
  const [Offer_, SetOffer_] = useState("0");
  const [warking_, Setwarking_] = useState("يعمل");
  const [PlaceName, setPlaceName] = useState(null);

  useEffect(() => {
    GetOrders();
    // Setwarking_(warking);
  }, []);

  const [error, setError] = useState(null);
  const [locationMessage, setLocationMessage] = useState(""); // لحفظ الرسائل
  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    GetMyLocation();
  }, []);

  useEffect(() => {
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        GetMyLocation();
        GetOrders();
      }, 5000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive, currentLocation]);

  function GetMyLocation() {
    const savedCurentLocation = JSON.parse(
      localStorage.getItem("CurentLocation")
    );
    if (savedCurentLocation) {
      setCurrentLocation(savedCurentLocation.location);
      setPlaceName(savedCurentLocation.name);
    }
    console.log(savedCurentLocation);
  }

  /*
  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      // مراقبة الموقع باستمرار
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPlaceName(latitude, longitude);

          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log("Current location:", location);
          setLocationMessage(""); // إزالة أي رسالة خطأ
        },
        (error) => {
          // تحديد سبب الخطأ بناءً على الكود
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert(
                "لم يتم السماح بالوصول إلى الموقع. يرجى تفعيل الموقع من الإعدادات."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              alert("الموقع غير متوفر حاليًا. حاول لاحقًا.");
              break;
            case error.TIMEOUT:
              console.error(
                "انتهت مهلة محاولة الحصول على الموقع. حاول مجددًا."
              );
              break;
            default:
              setLocationMessage(
                "حدث خطأ غير متوقع أثناء محاولة تحديد الموقع."
              );
          }
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: false, // استخدم دقة أقل إذا أمكن
          maximumAge: 10000, // يسمح باستخدام القيم المخزنة لمدة تصل إلى 10 ثوانٍ
          timeout: 20000, // ضبط مهلة لجلب الموقع
        }
      );
    } else {
      alert("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
      console.error("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
    }

    // تنظيف عند إلغاء المكون
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  /*
  useEffect(() => {
    // الحصول على الموقع عند تحميل الصفحة
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPlaceName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      alert("متصفحك لا يدعم الحصول على الموقع.");
    }
  }, []);

  //=================================================
  const getPlaceName0 = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        // setPickupName(results[0].formatted_address);
        const location = {
          lat: lat,
          lng: lng,
        };
        setCurrentLocation(location);
        console.log("Location: ", location);
        // calculateDistance(pickupLocation, location);
        console.log(results[0].formatted_address);
        console.log(location);
      }
    });
  };

  const getPlaceName00 = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAPS_API_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setPlaceName(data.results[0].formatted_address);
        const location = {
          lat: lat,
          lng: lng,
        };
        setCurrentLocation(location);
        console.log("Location: ", location);
        // calculateDistance(pickupLocation, location);
        console.log(data.results[0].formatted_address);
      } else {
        setPlaceName("تعذر العثور على اسم المكان.");
      }
    } catch {
      setPlaceName("خطأ في جلب اسم المكان.");
    }
  };

  //======================================
  const getPlaceName = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
      );

      const location = {
        lat: lat,
        lng: lng,
      };
      setCurrentLocation(location);
      console.log("Location A: ", location);
      // calculateDistance(pickupLocation, location);
      setPlaceName(response.data.display_name);
      // console.log(response.data.display_name);
    } catch (error) {
      console.error("خطأ في جلب اسم الموقع:", error);
    }
  };
  */
  // جلب الرسائل بشكل دوري

  //-------------------------------------------------------------
  async function GetOrders() {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        Action: "DellerWaitingOfers",
      };
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      const datanew = JSON.stringify(data);
      const dataold = JSON.stringify(Orders);
      if (datanew !== dataold) {
        SetOrders(data);
      }
    } catch (error) {
      return error;
    }
  }

  async function AddOfer(
    Action,
    offer,
    cost_delevaryFrom,
    number_order,
    customer_name,
    from_plase,
    from_latitude,
    from_longitude,
    to_plase,
    to_latitude,
    to_longitude,
    mylocationCount,
    distance,
    type_order,
    items,
    auto_accipt,
    tips
  ) {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        delevary_price: offer,
        cost_delevaryFrom: cost_delevaryFrom,
        number_order: number_order,
        customer_name: customer_name,
        from_plase: from_plase,
        from_latitude: from_latitude,
        from_longitude: from_longitude,
        to_plase: to_plase,
        to_latitude: to_latitude,
        to_longitude: to_longitude,
        mylocationCount,
        distance: distance,
        type_order: type_order,
        items: items,
        auto_accipt: auto_accipt,
        Action: Action,
        tips: tips,
      };
      // return console.log(post);

      await new Promise((resolve) => {
        localStorage.setItem("OrderAddOffer", JSON.stringify({ post }));
        resolve();
      });

      navigate(`../OrderAddOffer`);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  async function SetWarking() {
    try {
      var warking__ = warking_;
      if (warking_ == "يعمل") {
        Setwarking_("متوقف");
        warking__ = "متوقف";
      } else {
        Setwarking_("يعمل");
        warking__ = "يعمل";
      }
      const post = {
        Name: Username,
        ID: UserID,
        Action: "warking",
        warking: warking__,
      };
      const result = await fetch(API.DellerAccount, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await result.json();
      console.log(data);
      if (data.msg == "success") {
        Cookies.set("userinfo", JSON.stringify(data.data), { expires: 365 }); // Sets a cookie for 365 days
        userinfo = JSON.parse(Cookies.get("userinfo"));
        warking = userinfo[0].warking;
        console.log(data.data[0].warking);
        Setwarking_(data.data[0].warking);
      }
    } catch (error) {
      return error;
    }
  }
  //========================================================

  // دالة حساب المسافة بين نقطتين
  const calculateDistance = (pickup, delivery) => {
    const R = 6371e3; // نصف قطر الأرض بالأمتار
    const φ1 = (pickup.lat * Math.PI) / 180; // تحويل درجة العرض الأولى إلى راديان
    const φ2 = (delivery.lat * Math.PI) / 180; // تحويل درجة العرض الثانية إلى راديان
    const Δφ = ((delivery.lat - pickup.lat) * Math.PI) / 180; // فرق العرض بالراديان
    const Δλ = ((delivery.lng - pickup.lng) * Math.PI) / 180; // فرق الطول بالراديان

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceInMeters = R * c; // المسافة بالأمتار
    const distanceInKilometers = distanceInMeters / 1000; // المسافة بالكيلومترات

    // تحديد الوحدات وعرض النتيجة
    let result;
    if (distanceInKilometers >= 1) {
      result = `${distanceInKilometers.toFixed(2)} كم`; // إذا كانت المسافة بالكيلومترات
    } else {
      result = `${distanceInMeters.toFixed(0)} م`; // إذا كانت المسافة بالأمتار
    }

    //  /   console.log("Distance: " + result);
    // setDistance(result); // تخزين المسافة
    // حفظ اdistance__لبيانات في localStorage
    // localStorage.setItem("distance", JSON.stringify({ distance: result }));
    return result; // النتيجة مع الوحدات
  };

  //----------------------------------------

  return (
    <>
      <CheckAccount />
      {/* <Chat /> */}
      <div className=" row  m-auto " style={{ maxWidth: "500px" }}>
        <div className="col-1 text-center p-2">
          <img
            style={{ width: "22px", marginTop: "15px" }}
            src="../Images/kas.png"
            alt=""
          />
        </div>
        <div className="col-6 text-start p-2">
          <h6 style={{ fontSize: "14px" }}>الرئيسية</h6>
          <UpdateLocatin />
          <h6 className="text-green p-0 m-0" style={{ fontSize: "11px" }}>
            التنبيهات مفعلة
          </h6>
        </div>
        <div className="col-5 text-center p-2">
          <button
            onClick={SetWarking}
            style={{ fontSize: "12px" }}
            className="btn btn-danger"
          >
            ايقاف اسناد الطلبات
          </button>
        </div>

        <div className="col-12 esnad py-2">
          <h6 style={{ fontSize: "12px" }} className="p-0 fw-bold">
            جرب اسناد الطلبات الجديد للحصول على الطلبات بسرعة وسهولة
          </h6>
        </div>
        <div className="col-12 text-end bg-light p-2">
          {PlaceName && (
            <>
              <small className="text-syan" style={{ fontSize: "10px" }}>
                {PlaceName}
              </small>
              <br />
            </>
          )}

          <small> {Orders.length} طلبات * جميع الخدمات*</small>
        </div>
        <div className="col-master col-card-alert col-12 mb-4 p-1">
          {warking_ == "يعمل" ? <CardItem /> : <NoORDER />}
          <br />
          <br />
        </div>
      </div>

      <ButtomTab />
      {/* <Chat /> */}
      <Notifications account_type="مندوب" />
    </>
  );
  function CardItem() {
    return Orders.map((order, index) => {
      const pickupLocation_ = {
        lat: order.from_latitude,
        lng: order.from_longitude,
      };
      const cost_delevaryFrom = parseInt(order.safee) - parseInt(20);
      const mylocationCount = calculateDistance(
        pickupLocation_,
        currentLocation
      );
      return (
        <>
          <div
            key={order.number_order}
            className="row card-content "
            style={{ maxWidth: "1000px" }}
          >
            <div className="col-12 p-0">
              <small className="float-start">
                <small>{"#" + order.number_order}</small>
                {order.tips != "0" && (
                  <>
                    <br />
                    <label className="bg-danger0 p-0 px-2" style={{background:"#FCECE8",borderRadius:"10px"}}>
                     <small className="text-danger p-1">
                        {`+ ${order.tips}  جنية`}
                      </small>
                    
                      <img
                        src="/hdia.png"
                        alt=""
                        style={{ width: "15px" }}
                        className="ms-0"
                      />
                     
                    </label>
                  </>
                )}
              </small>
              <img
                style={{
                  height: "38px",
                  width: "38px",
                  borderRadius: "19px",
                }}
                src={order.logo || "../../../Images/gps.png"}
                alt=""
                loading="lazy"
                onError={(e) => {
                  e.target.src = "../../../Images/gps.png";
                }}
              />
              <span
                className="p-0 m-0 pe-2"
                style={{
                  fontSize: "1.1em",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {order.type_order}
              </span>

              <br />
              <small
                className="p-0 m-0 pe-2"
                style={{
                  fontSize: "12px",
                  color: "#337BC4",
                  fontWeight: "bold",
                }}
              >
                {order.to_plase}
              </small>
            </div>

            <div className="col-12 mt-2 p-0 ">
              <h6
                style={{
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                تفاصيل الطلب
              </h6>

              <h6
                style={{
                  fontSize: "11px",
                  color: "#666",
                }}
              >
                {order.items}
              </h6>
            </div>
            {/* ------------------------- */}
            <div className="col-12 text-center p-0 pt-2 bg-light shadow0">
              <div className="row text-center location-img p-0 mt-2 ">
                <div className="col-3 p-0 ">
                  <span>
                    <img src="../../../Images/location1.jpg" alt="" />
                    <br />
                    <small style={{ fontSize: "11px" }}>موقعك الحالى</small>
                  </span>
                </div>
                <div className="col-2 p-0">
                  <small style={{ fontSize: "13px" }}> {mylocationCount}</small>
                </div>

                <div className="col-2 p-0">
                  <span>
                    <img src="../../../Images/location2.jpg" alt="" />
                    <br />
                    <small style={{ fontSize: "11px" }}>موقع استلام</small>
                  </span>
                </div>
                <div className="col-2 p-0">
                  <small style={{ fontSize: "13px" }}> {order.distance}</small>
                </div>
                <div className="col-2 p-0">
                  <span>
                    <img src="../../../Images/location3.jpg" alt="" />
                    <br />
                    <small style={{ fontSize: "11px" }}>موقع تسليم</small>
                  </span>
                </div>
              </div>
            </div>

            {/* ------------------------ */}
            <div className="col-12 text-center mt-2">
              <button
                className="btn btn-warning p-2 px-3 me-3 fw-bold px-2"
                style={{ fontSize: "13px" }}
                onClick={() =>
                  AddOfer(
                    "other",
                    order.safee,
                    cost_delevaryFrom,
                    order.number_order,
                    order.customer_name,
                    order.from_plase,
                    order.from_latitude,
                    order.from_longitude,
                    order.to_plase,
                    order.to_latitude,
                    order.to_longitude,
                    mylocationCount,
                    order.distance,
                    order.type_order,
                    order.items,
                    order.auto_accipt,
                    order.tips
                  )
                }
              >
                عرض اخر
              </button>
              <button
                onClick={() =>
                  AddOfer(
                    "sam",
                    order.safee,
                    cost_delevaryFrom,
                    order.number_order,
                    order.customer_name,
                    order.from_plase,
                    order.from_latitude,
                    order.from_longitude,
                    order.to_plase,
                    order.to_latitude,
                    order.to_longitude,
                    mylocationCount,
                    order.distance,
                    order.type_order,
                    order.items,
                    order.auto_accipt,
                    order.tips
                    
                  )
                }
                className="btn btn-green p-2 me-3 fw-bold px-3"
                style={{ fontSize: "13px" }}
              >
                {`   قدم عرض ${order.safee} جنية `}
              </button>

              <h6 style={{ fontSize: "12px", marginTop: "5px" }}>
                {`من ${parseInt(cost_delevaryFrom)} جنية الى ${parseFloat(
                  order.safee
                )} جنية`}
              </h6>
            </div>
          </div>
        </>
      );
    });
  }

  //======================================

  //============================================
  //============================================
  function NoORDER() {
    return (
      <div className="card">
        <div className="card-body text-center " style={{ height: "650px" }}>
          <br /> <br /> <br />
          <div className="StartpageDiv0">
            <img
              style={{ width: "150px" }}
              src="../../../Images/no_order2.png"
              alt="no_alert"
            />
          </div>
          <br /> <br /> <br />
          <h4>
           
            انت الان على وضع ايقاف اسناد الطلب <br /> اضغط على ابداء العمل
            لمشاهدة الاوردرات{" "}
          </h4>
          <button
            onClick={SetWarking}
            className="btn btn-green mt-2 w-50 fw-bold"
          >
            {"  ابداء العمل الان"}
          </button>
          <br />
          <br /> <br />
        </div>
      </div>
    );
  }
}
