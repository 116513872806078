// import Link from "next/link";
import { useState, useEffect } from "react";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import LoginCheck from "../../../LoginCheck/page";
import { AppValues, API, ApiAdmin } from "../../../Values";
import Cookies from "js-cookie";
import { Modal, Button } from "react-bootstrap";

export default function CustomerShoppingAnyThing() {
  const navigate = useNavigate();
  const [Errors, SetErrors] = useState("");
  //---------ShowModalErrors----------------------------
  const [ModalError, SetModalError] = useState(false);
  const handleCloseModalError = () => SetModalError(false);
  const handleShowModalError = () => SetModalError(true);

  //------------------------------------------
  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var CustomerID = "";
  var City = "";
  var State = "";
  var Address = "";
  var Mobail = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    CustomerID = userinfo[0].ID;
    City = userinfo[0].City;
    State = userinfo[0].State;
    Address = userinfo[0].Address;
    Mobail = userinfo[0].Mobail;
  }

  //--------------------------------------------
  //-------------------------------------
  const [KobonCod, SetKobonCod] = useState("0");
  const [KobonStatue, SetKobonStatue] = useState("");
  const [KobonDescount, SetKobonDescount] = useState(0);
  const [TypeOrder, SetTypeOrder] = useState(0);
  const [logo, Setlogo] = useState("");
  //--------- ShowModal ----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);

  //------------------------  -------------------------
  const [pickupLocation, setPickupLocation] = useState({
    lat: "",
    lng: "",
  }); // نقطة الاستلام
  const [pickupName, setPickupName] = useState(null); // نقطة الاستلام
  const [deliveryLocation, setDeliveryLocation] = useState({
    lat: "",
    lng: "",
  }); // نقطة التسليم
  const [deliveryName, setDeliveryName] = useState(null); // نقطة التسليم
  const [distance, setDistance] = useState(null); // المسافة بين نقطتي الاستلام والتسليم

  const [shoppingType, setshoppingType] = useState(null);
  const [HedePaner, setHedePaner] = useState("");
  const [TextPaner, setTextPaner] = useState("");
  useEffect(() => {
    const SaveshoppingType = JSON.parse(localStorage.getItem("shoppingType"));
    if (SaveshoppingType) {
      setshoppingType(SaveshoppingType);
    }
  }, []);

  useEffect(() => {
    const savedPickupLocation = JSON.parse(
      localStorage.getItem("pickupLocation")
    );
    if (savedPickupLocation) {
      setPickupLocation(savedPickupLocation.location);
      setPickupName(savedPickupLocation.name);
    }
  }, []);

  const params = useParams();
  useEffect(() => {
    console.log("shoppingType " + shoppingType);
    switch (shoppingType) {
      case "anything":
        setHedePaner("أطلب أى حاجة");
        setTextPaner("أطلب توصيل اى حاجة");
        SetTypeOrder("أطلب أى حاجة");
        Setlogo("../../../Images/gps.png");
        break;
      case "restaurant":
        setHedePaner("توصيل اطعمة ومشروبات");
        setTextPaner("اطلب من اى مطعم");
        SetTypeOrder(pickupName);
        Setlogo(localStorage.getItem("logo"));

        break;
      case "supermarket":
        setHedePaner("سوبر ماركت");
        setTextPaner("اطب من اى سوبر ماركت");
        SetTypeOrder(pickupName);
        Setlogo(localStorage.getItem("logo"));
        break;
      case "store":
        setHedePaner("محلات تجارية");
        setTextPaner("اطلب من المجلات التجارية");
        SetTypeOrder(pickupName);
        Setlogo(localStorage.getItem("logo"));
        break;
      default:
        setHedePaner("أطلب أى حاجة");
        setTextPaner("أطلب توصيل اى حاجة");
        SetTypeOrder("أطلب أى حاجة");
        Setlogo("../../../Images/gps.png");
        Setlogo(localStorage.getItem("logo"));
    }

    console.log(shoppingType);
  }, [shoppingType]);

  useEffect(() => {
    //--------------------------------------
    const savedDeliveryLocation = JSON.parse(
      localStorage.getItem("deliveryLocation")
    );
    if (savedDeliveryLocation) {
      setDeliveryLocation(savedDeliveryLocation.location);
      setDeliveryName(savedDeliveryLocation.name);
    }
  }, []);

  useEffect(() => {
    const savedDistance = JSON.parse(localStorage.getItem("distance"));
    if (savedDistance) {
      setDistance(savedDistance.distance);
      CalculateCost(savedDistance.distance);
    }
  }, []);
  //------------------------------------
  //-------------------------------------------------------------
  const [Cost, SetCost] = useState([]);
  async function CalculateCost(distance_) {
    try {
      const post = {
        Action: "CalculateDestanse",
        Distance: convertArabicNumbersToEnglish(distance_),
        KobonDescount: KobonDescount,
      };
      console.log(post);
      const response = await fetch(ApiAdmin + "/Destanse.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetCost(data);
    } catch (error) {
      return error;
    }
  }

  const convertArabicNumbersToEnglish = (input) => {
    if (!input) return "";

    // استبدال الأرقام
    const arabicToEnglishMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };

    // تحويل الأرقام والفواصل
    return input
      .replace(/[٠-٩]/g, (digit) => arabicToEnglishMap[digit]) // استبدال الأرقام
      .replace(/،/g, ","); // استبدال الفاصلة
  };

  const CheckKobon = async () => {
    try {
      const post = {
        nameID: userinfo[0].ID,
        name: userinfo[0].Name,
        cod: sessionStorage.getItem("KobonCode"),
        Action: "check_Kobon",
      };

      if (post.cod == "") {
        return alert("الرجاء كتابة رقم الكوبون");
      }
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await response.json();
      console.log(body);
      SetKobonStatue(body.msg);
      if (body.msg == "تم اضافة كوبون") {
        handleCloseModal();
        SetKobonCod(sessionStorage.getItem("KobonCode"));
        SetKobonDescount(body.data[0].Descount);
        CalculateCost(distance);
      }
    } catch (error) {
      return error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    //  return console.log(data_);

    try {
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(data_),
      });
      const data = await response.json();

      if (data.errors != "") {
        SetErrors(data.errors);
        handleShowModalError();
      }
      if (data.msg == "success") {
        localStorage.removeItem("pickupLocation");
        localStorage.removeItem("distance");
        localStorage.removeItem("deliveryLocation");

        return navigate(`../Customer/OrderWaiting/${data.number_order}/0`);
        SetErrors(data.msg);
        handleShowModalError();
      }
      if (response.ok) {
      } else if (response.status === 400) {
        return alert("Errorr 400");
      } else {
        return alert("Can Not Save ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "JSIO Express جى سيو للنقل الذكى",
          text: "شركة متخصصه للنقل السريع ونقل اى شىء فى جميع انحاء الجمهورية",
          url: "https://www.j-sio.com", // الرابط الذي تريد مشاركته
        });
        console.log("تمت المشاركة بنجاح!");
      } catch (error) {
        console.error("فشل في المشاركة:", error);
      }
    } else {
      alert("خاصية المشاركة غير مدعومة في هذا المتصفح.");
    }
  };
  return (
    <>
      <LoginCheck />
      <form method="POST" onSubmit={handleSubmit}>
        <div
          className="row-master0 row m-auto p-1"
          style={{ maxWidth: "1000px" }}
        >
          <div className={`col-12 head  ${shoppingType}`}>
            <Link className="back0" to="../Customer/Shopping">
              <img
                className="iconBack"
                src="../../../../Images/2.png"
                alt="iconBack"
              />
            </Link>
            <img
              className="iconshar"
              src="../../../../Images/download.png"
              alt=""
              onClick={handleShare}
            />
          </div>
          <div className="col-md-1 col-2">
            <img
              style={{ width: "60px" }}
              src="../../../../Images/gps.png"
              alt=""
            />
          </div>
          <div className="col-md-9 col-6 p-2">
            <h3 style={{ fontSize: "16px" }} className="fw-bold  m-0">
              {HedePaner}
            </h3>
            <small style={{ fontSize: "12px" }}>{TextPaner}</small>
          </div>
          <div className="col-md-2 col-4 text-center ">
            <img
              style={{ width: "20px" }}
              src="../../../../Images/star1.jpg"
              alt=""
            />
            <span style={{ fontSize: "15px" }} className="fw-bold  mt-4 ">
              {"4.9 "}
            </span>
            <br />
            <small style={{ fontSize: "12px" }}> مشاركات {"4646K"}</small>
          </div>
        </div>
        <div className="col-12">
          <input type="hidden" name="Action" value="NewOrder" />
          <input type="hidden" name="TypeOrder" value={TypeOrder} />
          <input type="hidden" name="CustomerID" value={CustomerID} />
          <input type="hidden" name="CustomerName" value={Username} />
          <input type="hidden" name="City" value={City} />
          <input type="hidden" name="State" value={State} />
          <input type="hidden" name="Address" value={Address} />
          <input type="hidden" name="Mobail" value={Mobail} />
          <input type="hidden" name="logo" value={logo} />
          <input type="hidden" name="Distance_ok" value={`${Cost.Distance}`} />
          <input
            type="hidden"
            name="delevary_price_"
            value={`${Cost.delevary_price}`}
          />
          <input
            type="hidden"
            name="nesba_company"
            value={`${Cost.nesba_company}`}
          />
          <input type="hidden" name="descount" value={`${Cost.descount}`} />
          <input type="hidden" name="total" value={`${Cost.total}`} />
          <input type="hidden" name="tax" value={`${Cost.tax}`} />
          <input type="hidden" name="safee" value={`${Cost.safee}`} />

          <input
            type="hidden"
            name="from_latitude"
            value={pickupLocation.lat}
          />
          <input
            type="hidden"
            name="from_longitude"
            value={pickupLocation.lng}
          />
          <input
            type="hidden"
            name="to_latitude"
            value={deliveryLocation.lat}
          />
          <input
            type="hidden"
            name="to_longitude"
            value={deliveryLocation.lng}
          />
          <input type="hidden" name="Distance" value={distance} />
          <input
            type="hidden"
            name="Description"
            value={sessionStorage.getItem("Description")}
          />
          <input
            type="hidden"
            name="DelevaryPrice"
            value={Cost.PRICE_TOTAL}
          />

          <input
            type="hidden"
            name="Rassed"
            value="0"

          />
        </div>
        <div
          className="col-master row m-auto p-1"
          style={{ maxWidth: "1000px", maxHeight: "480px" }}
        >
          <div className="col-12 mt-1">
            <label style={{ fontSize: "13px" }} className="col-form-label">
              اكتب طلبك
            </label>
            <textarea
              style={{ fontSize: "13px" }}
              name="Items"
              className="form-control bg-light"
              placeholder="اكتب هنا تفاصيل طلبك من المحل , مثلا : 1 هامبرجر بالجبنه , نرجوا منك كتابة القيمة التقديرية للطلب حتى يتسنى للمندوب خدمتك"
              autoComplete="off"
            />
          </div>
          <div className="col-12 mt-1">
            <label style={{ fontSize: "13px" }} className="col-form-label">
              {"موقع استلام الشحنة "}
            </label>
            <div className="form-control bg-light goto-Location p-1">
              <Link to="../Customer/ShoppingFrom">
                <i className="fa-solid fa-location-dot fs-6 pt-1 ms-2"></i>
              </Link>
              <input
                style={{ fontSize: "13px" }}
                type="text"
                name="From"
                placeholder="اختيار الموقع"
                className=" text-green0 fw-bold0  bg-light locationtext"
                autoComplete="off"
                value={pickupName}
              />
            </div>
          </div>
          <div className="col-12 mt-1">
            <label style={{ fontSize: "13px" }} className="col-form-label">
              {" موقع تسليم الشحنة "}
            </label>
            <div className="form-control bg-light goto-Location p-1">
              <Link to="../Customer/ShoppingTo">
                <i className="fa-solid fa-home fs-6 pt-1 ms-1"></i>
              </Link>
              <input
                style={{ fontSize: "13px" }}
                type="text"
                name="To"
                placeholder="اختيار الموقع"
                className=" text-green0 fw-bold0  bg-light locationtext"
                autoComplete="off"
                value={deliveryName}
              />
            </div>
          </div>

          <div className="col-12 mt-1">
            <label style={{ fontSize: "13px" }} className="col-form-label">
              {" المسافة "}
            </label>

            <input
              style={{ fontSize: "13px" }}
              type="text"
              name="Distance_"
              className="form-control bg-light "
              placeholder="0"
              autoComplete="off"
              value={convertArabicNumbersToEnglish(distance)}
              disabled
            />
          </div>


          {/* <div className="col-12 mt-1">
            <label style={{ fontSize: "13px" }} className="col-form-label">
              {"المحفظة "}
            </label>
            <input
              style={{ fontSize: "13px" }}
              type="number"
              name="Rassed"
              value="0"
              className="form-control bg-light "
              placeholder="0 جنية"
              autoComplete="off"
            />
          </div> */}
          <div className="col-12 mt-2">
            <label style={{ fontSize: "13px" }} className="col-form-label">
              {"طريقة الدفع "}
            </label>
            <select
              name="PaymentType"
              className="form-control bg-light text-success fw-bold"
            >
              <option value=""></option>
              <option value="كاش">كاش</option>
              <option value="بطاقة بنكية">بطاقة بنكية</option>
            </select>
          </div>


          <div className="col-12 mt-0">
            <label style={{ fontSize: "13px" }} className="col-form-label ms-3">
              {"الكوبونات "}

              <i
                onClick={handleShowModal}
                className="fa-solid fa-circle-plus text-green fs-5 mx-2"
              ></i>
              <input type="hidden" name="KobonCod" value={KobonCod} />
              <input type="hidden" name="KobonDescount" value={KobonDescount} />
              {KobonDescount > 0 && (
                <>
                  <div className="mt-2">
                    <span className="mx-1 text-green">{KobonCod} </span>
                    <span className="text-danger">
                      {"خصم  " + KobonDescount + " من قيمة الشحن"}
                    </span>
                  </div>
                </>
              )}
            </label>
          </div>

          <br /><br /><br /><br />
          <br /><br /><br /><br />
        </div>


        <div className="row buttom-div p-1">
          <div className="col-12 bg-light px-3 py-2">
            <span className="fw-bold">تكلفة التوصيل</span>

            <span className="float-start mx-1 fw-bold">{Cost.PRICE_TOTAL || "0"} جنية</span>
            <br />

            <small className="text-secondary">سعر التوصيل المتوقع يعتمد على العروض المقدمة لكم بواسطة المندوبين بالاضافة الى المسافة بين موقع استلام الطلب وموقع تسليمة</small>
          </div>
          <div className="col-12 text-center pt-2 mb-2">
            <button
              className="btn btn-green w-75 p-2 fw-bold radius"
              style={{ borderRadius: "50px" }}
            >
              ارسل الطلب
            </button>
          </div>
        </div>

      </form>
      <MSG />
      <AddKobon />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ModalError} onHide={handleCloseModalError}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">تنبية </h4>
                <hr />
                <h5 className=" text-center ">{Errors}</h5>
                {Errors == "success" && (
                  <button
                    onClick={() => handleCloseModalError()}
                    className="btn btn-green w-50 m-3"
                  >
                    OK
                  </button>
                )}
                {Errors != "success" && (
                  <button
                    onClick={() => handleCloseModalError()}
                    className="btn btn-danger w-50 m-3"
                  >
                    OK
                  </button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  function AddKobon() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-11 text-center">
                <h5> أضافة كوبون</h5>
              </div>
              <div className="col-1">
                <i
                  onClick={handleCloseModal}
                  className="fa-solid fa-xmark fs-4"
                ></i>
              </div>
              <hr />

              <div className="col-12 text-center">
                <label className="col-form-label"> أدخل رقم الكوبون </label>
                <input
                  className="form-control w-50 text-center text-green fw-bold mb-4 fs-4 m-auto shadow"
                  type="text"
                  name="SdadMony"
                  autoComplete="off"
                  // onChange={(e) => SetKobonCod(e.target.value)}
                  onChange={(e) =>
                    sessionStorage.setItem("KobonCode", e.target.value)
                  }
                />
                <span className="text-danger"> {KobonStatue}</span>
              </div>
              <div className="col-12 mt-4 text-center">
                <button
                  onClick={CheckKobon}
                  type="button"
                  className="btn btn-green w-100"
                >
                  تحقق
                </button>
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
