import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { API, ApiAdmin, MAPS_API_KEY } from "../../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Iconlocation1 from "../../../Images/location1.png";
import Iconlocation2 from "../../../Images/location2.png";
import Iconlocation3 from "../../../Images/location3.png";
import LoginCheck from "../../LoginCheck/page";
import {
  Reviews0,
  Reviews1,
  Reviews2,
  Reviews3,
  Reviews4,
  Reviews5,
} from "../../Reviews/page";

import { Modal, Button, Dropdown } from "react-bootstrap";
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 30.033, // الموقع الافتراضي
  lng: 31.233,
};

export default function AdminOrderInfo() {
  const params = useParams();
  const imgstyle = {
    height: "100%",
  };

  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const [Reviews, SetReviews] = useState("0");
  const [Address, SetAddress] = useState("");
  const [messages, Setmessages] = useState([]);
  const [DellerMosthkat, SetDellerMosthkat] = useState("0");
  const [farkMony, SetfarkMony] = useState("0");

  //--------- CHECK LOGIN --------------------
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }

  const [DelevaryInfo, setDelevaryInfo] = useState([
    {
      Mobail: "",
      Mony: "",
      orders: "0",
      reviews: "",
    },
  ]);
  const [OrderInfo, setOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const navigate = useNavigate();
  useEffect(() => {
    GetOrderInfo();
  }, []);
  //-------------------------------------------------------------
  async function GetOrderInfo() {
    try {
      const post = {
        number_order: params.order,
        Action: "OrderInfoAdmin",
      };
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await response.json();
      setOrderInfo(body.OrderInfo);
      setDelevaryInfo(body.DelevaryInfo);
      Setmessages(body.chat);
      var Address = body.OrderInfo[0].address + " - ";
      Address += body.OrderInfo[0].state + " - ";
      Address += body.OrderInfo[0].city;
      SetAddress(Address);
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    if (DelevaryInfo.length > 0) {
      const Mosthkat =
        parseInt(OrderInfo[0].items_price) +
        parseInt(OrderInfo[0].delevary_price);
      const fark = parseInt(DelevaryInfo[0].Mony) + parseInt(Mosthkat);
      console.log(Mosthkat);
      SetDellerMosthkat(Mosthkat);
      SetfarkMony(fark);
    }
  }, [DelevaryInfo]);

  //=============================================
  //-------------------------------------------------------------
  async function handelOrderAction() {
    if (window.confirm("هل تريد  تغيير الحالة")) {
      try {
        const post = {
          Action: "DellerUpdateOrederStatue",
          statue: OrderInfo[0].statue,
          number_order: OrderInfo[0].number_order,
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        GetOrderInfo();
      } catch (error) {
        return error;
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //====================================
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());

    if (window.confirm("هل تريد تصفيه الحساب")) {
      try {
        const response = await fetch(ApiAdmin + "/deller.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });

        const body = await response.json();
        console.log(body);
        GetOrderInfo();

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //-----------------------------------------

  let classStatu = "";
  // if (OrderInfo[0].statue == "جارى التاكيد") {
  //   classStatu = "bg-primary";
  // }
  // if (OrderInfo[0].statue == "تم الموافقة") {
  //   classStatu = "bg-danger ";
  // }
  // if (OrderInfo[0].statue == "جارى التنفيذ") {
  //   classStatu = "bg-orange";
  // }

  // if (OrderInfo[0].statue == "جارى التوصيل") {
  //   classStatu = "bg-info";
  // }
  // if (OrderInfo[0].statue == "تم التوصيل") {
  //   classStatu = "bg-green";
  // }

  if (OrderInfo[0].statue == "بانتظار العروض") {
    classStatu = "bg-warning";
  }
  if (OrderInfo[0].statue == "بانتظار التاكيد") {
    classStatu = "bg-warning";
  }
  if (OrderInfo[0].statue == "انتظار الموافقة") {
    classStatu = "bg-warning";
  }

  if (OrderInfo[0].statue == "تم الموافقة") {
    classStatu = "bg-blue";
  }
  if (OrderInfo[0].statue == "ملغى") {
    classStatu = "bg-secondary text-white";
  }
  if (OrderInfo[0].statue == "جارى التاكيد") {
    classStatu = "bg-info text-white";
  }
  if (OrderInfo[0].statue == "جارى التنفيذ") {
    classStatu = "bg-orangeDark text-white";
  }
  if (OrderInfo[0].statue == "جارى التوصيل") {
    classStatu = "bg-blue2 text-white";
  }
  if (OrderInfo[0].statue == "تم التوصيل") {
    classStatu = "bg-green text-white";
  }

  return (
    <>
      <div
        className="p-1 row  text-center m-auto "
        style={{ maxWidth: "800px" }}
      >
        <div className="col-12 py-2 text-center bg-light mb-0">
          <Link to="../admin/Orders" className="float-end">
            <i className="fa-solid fa-xmark text-danger fs-4 "></i>
          </Link>
          <span className="fs-6 fw-bold"> {OrderInfo[0].type_order} </span>
          <br />
          <small className={`px-1 mx-2 rounded text-white ${classStatu}`}>
            {OrderInfo[0].statue}
          </small>
          <small dir="ltr" className="text-secondary">
            #{OrderInfo[0].number_order}
          </small>
        </div>
        <div className="col-8 py-2 bg-light0 mb-0 text-end">
          <i className="fa-solid fa-user fs-1 text-info mx-2"></i>
          <span className="text-syan">{OrderInfo[0].customer_name} </span>
          <br />
        </div>

        <div className="col-4 py-2 bg-light0 mb-0 text-start">
          <Link to={`tel:${OrderInfo[0].mobail}`} target="_blank">
            <button className="btn btn-green mx-1 fs-6 fw-bold btn-call">
              <i className="fa-solid fa-phone"></i>
            </button>
          </Link>
        </div>

        <div className="col-12 py-2 bg-light0 mb-0 text-end">
          <span>{Address} </span>
        </div>

        <div
          style={{ borderTop: "1px solid #ccc" }}
          className="col-12 py-1 mb-0 text-end"
        >
          <div
            style={{ borderBottom: "1px solid #ccc" }}
            className="row p-0 m-0"
          >
            <div className="col-12 p-0 m-0">
              <small className="fw-bold text-syan"> تفاصيل الطلب</small>
              <br />
              <span className="text-red">{OrderInfo[0].items} </span>
            </div>

            <div className="col-12 p-0 py-1">
              <small className="mx-2">
                {`   سعر الطلب : ${OrderInfo[0].items_price}`}
              </small>

              <small className="mx-2">
                {` اجرة الطلب : ${OrderInfo[0].delevary_price}`}
              </small>

              <br />
              <small className="mx-2">
                {`كوبون خصم : ${OrderInfo[0].koboon_descount}`}
              </small>
              <small className="mx-2">
                {`اجمالى خصم : ${OrderInfo[0].descount}`}
              </small>
              <br />
              <small className="mx-2">
                {` نسبة شركة : ${OrderInfo[0].nesba_company}`}
              </small>
              <small className="mx-2">{`اجمالى : ${OrderInfo[0].total}`}</small>
              <small className="mx-2"> {`ضريبة : ${OrderInfo[0].tax}`} </small>
              <br />
              <small className="mx-2">
                {` اجمالى كلى : ${OrderInfo[0].safee} `}
              </small>
              <small className="mx-2">
                {` توصيل + طلبات : ${
                  parseInt(OrderInfo[0].safee) +
                  parseInt(OrderInfo[0].items_price)
                } `}
              </small>
              <br />
              <small className="mx-1 fw-bold">{` طريقة الدفع : `}</small>

              {OrderInfo[0].payment_type == "بطاقة بنكية" ? (
                <>
                  <span className="mx-1 fw-bold">فيزا</span>
                  <img
                    src="../../../Images/visa.png"
                    alt="visa"
                    style={{ width: "30px" }}
                  />

                  {OrderInfo[0].sdad == "no" ? (
                    <small className="fw-bold text-danger mx-2">
                      لم يتم الدفع
                    </small>
                  ) : (
                    <small className="fw-bold text-green mx-2">تم الدفع</small>
                  )}
                </>
              ) : (
                <>
                  <span className="mx-1 fw-bold">كاش</span>
                  <img
                    src="../../../Images/mony.png"
                    alt="cash"
                    style={{ width: "30px" }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-6 py-2  mb-0 text-end">
          <Link
            to={`https://www.google.com/maps?q=${OrderInfo[0].from_latitude},${OrderInfo[0].from_longitude}`}
          >
            <i className="fa-solid fa-home bg-green text-white p-1 mx-1"></i>
            <span>{OrderInfo[0].delevary_distanse_start} </span>
            <br />
            <small className="fw-bold">موقع الاستلام</small>
            <br />
            <small>{OrderInfo[0].from_plase} </small>
          </Link>
        </div>
        {/* ==========HGJ,WDG========================== */}
        <div className="col-6 py-1 mb-0 text-end">
          <Link
            to={`https://www.google.com/maps?q=${OrderInfo[0].to_latitude},${OrderInfo[0].to_longitude}`}
          >
            <i className="fa-solid fa-flag bg-info text-white p-1 mx-1"></i>
            <span>{OrderInfo[0].distance} </span>
            <br />
            <small className="fw-bold"> التوصيل </small>
            <br />
            <small>{OrderInfo[0].to_plase} </small>
          </Link>
        </div>
        {/* ========= المندوب================*/}
        <hr />
        {OrderInfo[0].delevary_name && (
          <>
            <div className="col-12 text-end">
              <small className="fw-bold text-syan"> المندوب : </small>

              <span>{OrderInfo[0].delevary_name}</span>
              <br />
              <small className="text-danger fw-bold ms-1">{`( ${DelevaryInfo[0].orders} )`}</small>
              {DelevaryInfo.reviews == "0" && <Reviews0 />}
              {DelevaryInfo[0].reviews == "1" && <Reviews1 />}
              {DelevaryInfo[0].reviews == "2" && <Reviews2 />}
              {DelevaryInfo[0].reviews == "3" && <Reviews3 />}
              {DelevaryInfo[0].reviews == "4" && <Reviews4 />}
              {DelevaryInfo[0].reviews == "5" && <Reviews5 />}

              <br />
              <small> موبايل : {DelevaryInfo[0].Mobail} </small>
              <br />
              <span>
                {" رصيد سابق : "}
                {DelevaryInfo ? (
                  <>
                    {parseInt(DelevaryInfo[0].Mony) > 0 ? (
                      <span className="text-green">{DelevaryInfo[0].Mony}</span>
                    ) : (
                      <span className="text-danger">
                        {DelevaryInfo[0].Mony}
                      </span>
                    )}
                  </>
                ) : (
                  <>0</>
                )}
              </span>

              {OrderInfo[0].delevary_mosthkat == "no" &&
                OrderInfo[0].statue == "تم التوصيل" && (
                  <>
                    <small className="mx-2  fw-bold0 fs-6">
                      {` مستحقات الاوردر   : `}
                    </small>
                    <small className="mx-1 text-danger fw-bold fs-6">
                      {DellerMosthkat}
                    </small>

                    <span className="ms-1"> صافى رصيد : </span>
                    {farkMony > 0 ? (
                      <span className="text-green bold">{farkMony}</span>
                    ) : (
                      <span className="text-danger fw-bold">{farkMony}</span>
                    )}
                    <br />

                    <form method="POST" onSubmit={handleSubmit}>
                      <input type="hidden" name="Action" value="SdadMony" />

                      <input
                        type="hidden"
                        name="ID"
                        value={OrderInfo[0].delevary_id}
                      />
                      <input
                        type="hidden"
                        name="Name"
                        value={OrderInfo[0].delevary_name}
                      />
                      <input
                        type="hidden"
                        name="number_order"
                        value={OrderInfo[0].number_order}
                      />

                      <input type="number" name="Mony" />
                      <button className="btn btn-green me-4 p-0 px-2">
                        {"  تصفية حساب "}
                      </button>
                    </form>
                  </>
                )}
              <br />
              <br />
            </div>
            {/* ==========CHAT========================== */}
            <div className="col-12 text-end bg-gray chat_msg">
              {messages.map((msg, index) => {
                const timestampDate = new Date(msg.created_at);
                const currentDate = new Date();
                // حساب الفرق بين الوقت الحالي والتاريخ الموجود في timestamp
                const timeDifference =
                  (currentDate - timestampDate) / (1000 * 60 * 60); // الفرق بالساعة
                // تحديد ما إذا كان الوقت أكبر من 24 ساعة
                const created_at =
                  timeDifference >= 24
                    ? timestampDate.toLocaleDateString()
                    : timestampDate.toLocaleTimeString();

                const class_ =
                  msg.sender_id === OrderInfo[0].delevary_id
                    ? "text-chatSender"
                    : "text-chatReciver";
                const class_show =
                  msg.statue != "no" ? "text-green" : "text-secondary";

                return (
                  <>
                    {msg.type == "fatora" && (
                      <div key={index}>
                        <div className="text-start">
                          <label
                            className="bg-white p-2 text-end "
                            style={{
                              maxHeight: "300px",
                              border: "2px solid #7FD7EB",
                              marginTop: "10px",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <small className="fw-bold">
                              {"    تم اصدار الفاتورة بواسطة "}
                              {OrderInfo[0].delevary_name}
                            </small>
                            <hr />
                            <small>
                              {" قيمة الطلب : "}
                              {parseFloat(OrderInfo[0].items_price, 2)}
                              {" جنية "}
                            </small>
                            <br />
                            <small>
                              {" قيمة التوصيل : "}
                              {parseFloat(OrderInfo[0].safee, 2)}
                              {" جنية "}
                            </small>
                            <br />
                            <span className="fw-bold">
                              {" المبلغ المستحق : "}
                              {parseFloat(OrderInfo[0].items_price, 2) +
                                parseFloat(OrderInfo[0].safee, 2)}
                              {" جنية "}
                            </span>
                          </label>
                        </div>
                        <div className="text-start w-100">
                          <img
                            src={API.Images + msg.message}
                            alt=""
                            style={{
                              width: "80%",
                              maxHeight: "300px",
                              border: "2px solid #7FD7EB",
                              marginTop: "10px",
                              borderRadius: "10px",
                              padding: "5px",
                            }}
                          />
                          <br />

                          <i
                            style={{ fontSize: "12px" }}
                            className={`fa-solid fa-check  ${class_show}`}
                          ></i>
                          <small
                            dir="ltr"
                            className="mb-4 px-1"
                            style={{ fontSize: "12px" }}
                          >
                            {created_at}
                          </small>
                          <i
                            style={{ fontSize: "12px" }}
                            className={`fa-solid fa-user  text-info`}
                          ></i>
                          <br />
                        </div>
                      </div>
                    )}

                    {msg.type == "uploadImage" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {msg.type == "text" && (
                      <div key={index}>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </div>
                    )}
                    {msg.type == "audio" && (
                      <div key={index}>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <audio
                              controls
                              src={API.Images + "orders/" + msg.message}
                            ></audio>

                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </div>
                    )}
                  </>
                );
              })}

              {/* العنصر المخفي للتمرير التلقائي */}
            </div>
          </>
        )}
        <div className="col-12">
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
