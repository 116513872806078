import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API } from "../../../Values";
import axios from "axios";
import Cookies from "js-cookie";
import Chat from "../../../compoenents/Chat";

import LoginCheck from "../../../LoginCheck/page";
import { Modal, Button, Dropdown } from "react-bootstrap";
export default function CustomerOrderWaiting() {
  const navigate = useNavigate();
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const [ModalRegisterType, SetModalRegisterType] = useState(false);

  const [ShowModaltips, SetShowModaltips] = useState(false);
  const handleCloseModaltips = () => SetShowModaltips(false);
  const handleShowModaltips = () => SetShowModaltips(true);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const [Shakwa, SetShakwa] = useState("");
  //-------------------------------------

  const [tips_, settips] = useState("0");

  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }
  //--------------------------------------------
  const [AutoAccipt, SetAutoAccipt] = useState("false");
  const [OrderInfo, SetOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const [Offers, SetOffers] = useState([]);

  useEffect(() => {
    GetOrderInfo();
  }, []);

  // جلب الرسائل بشكل دوري
  useEffect(() => {
    if (!ShowModaltips) {
      var fetchIntervalRef = setInterval(() => {
        GetOrderInfo();
      }, 3000);

      return () => clearInterval(fetchIntervalRef);
    }
  }, [ShowModaltips]);

  //-------------------------------------------------------------
  const Params = useParams();
  async function GetOrderInfo() {
    if (ShowModaltips) {
      return false;
    }

    try {
      const post = {
        number_order: Params.number_order,
        Action: "OrderInfoWitting",
      };

      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await response.json();
      if (body.Offers.length != Offers.length) {
        console.log(body);
        SetOffers(body.Offers);
      }

      ///  if (body.OrderInfo.length != OrderInfo.length) {
      SetOrderInfo(body.OrderInfo);
      SetAutoAccipt(body.OrderInfo[0].auto_accipt);
      //  }
    } catch (error) {
      return error;
    }
  }

  async function handelReOrder() {
    if (window.confirm("هل تريد  اعادة طلب الاوردر")) {
      try {
        const post = {
          number_order: Params.number_order,
          Action: "ReOrder",
        };

        const response = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await response.json();
        console.log(body);
        if (body.msg == "SUCCESS") {
          console.log(body.msg);
          navigate("../Customer/Orders");
        }
      } catch (error) {
        return error;
      }
    }
  }

  async function handelUpdateAutoAccipt(x) {
    try {
      SetAutoAccipt(x);
      const post = {
        number_order: Params.number_order,
        auto_accipt: x,
        Action: "UpdateActiveAccipt",
      };
      const result = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await result.json();
      console.log(body);
      AutoAccipt(body);
    } catch (error) {
      return error;
    }
  }
  const handleChange = (e) => {
    SetShakwa(e);
  };

  async function handelHelp() {
    handleCloseModal();
    if (window.confirm("هل تريد ارسال شكوى للدعم")) {
      try {
        const post = {
          account_type: "عميل",
          name: Username,
          name_id: UserID,
          number_order: Params.number_order,
          type_shakwa: Shakwa,
          type_order: OrderInfo[0].type_order,
          Action: "Help",
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        navigate("../Customer/Orders");
      } catch (error) {
        return error;
      }
    }
  }

  async function CancelOrder() {
    if (
      OrderInfo[0].statue == "بانتظار العروض" ||
      OrderInfo[0].statue == "بانتظار التاكيد" ||
      OrderInfo[0].statue == "انتظار الموافقة"
    ) {
      if (window.confirm("هل تريد  الغاء الطلب")) {
        try {
          const post = {
            number_order: Params.number_order,
            description: "لست بحاجة للطلب",
            statue: "ملغى",
            Action: "CancelOrder",
          };
          const result = await fetch(API.Orders, {
            method: "POST",
            body: JSON.stringify(post),
          });
          const body = await result.json();
          console.log(body);
          navigate("../Customer/Orders");
        } catch (error) {
          return error;
        }
      }
    }
  }

  async function DenayOffer(number_order, delevary_id, delevary_name, mony) {
    if (window.confirm(" هل تريد  رفض عرض " + delevary_name)) {
      try {
        const post = {
          number_order: Params.number_order,
          delevary_id: delevary_id,
          delevary_name: delevary_name,
          mony: mony,
          Action: "DenayOffer",
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        GetOrderInfo();
      } catch (error) {
        return error;
      }
    }
  }

  async function ChangeOffer(number_order, delevary_id, delevary_name, mony) {
    if (window.confirm(" هل تريد  قبول عرض " + delevary_name)) {
      try {
        const post = {
          number_order: Params.number_order,
          delevary_id: delevary_id,
          delevary_name: delevary_name,
          mony: mony,
          Action: "ChangeOffer",
        };

        //  return console.log(post);
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        // return  console.log(body);

        navigate(`../Customer/OrderPinding/${number_order}`);
      } catch (error) {
        return error;
      }
    }
  }

  //-------------------------------------------------------------
  async function ChangeTips() {

    if (window.confirm(" هل تريد تقديم مكافاة للمندوب ")) {
      try {
        const post = {
          number_order: OrderInfo[0].number_order,
          tips: tips_,
          Action: "ChangeTips",
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();

        handleCloseModaltips();
        return console.log(body);
      } catch (error) {
        return error;
      }

    }

  }

  return (
    <>
      <LoginCheck />
      <SHOWTIPS />
      <div
        dir="rtl"
        className="row mt-0 text-center0 m-auto bg-light"
        style={{ maxWidth: "1000px" }}
      >
        <div className="col-12 py-1 bg-light ">
          <i
            onClick={() => navigate("../Customer/Orders")}
            className="fa-solid fa-chevron-right  mt-2 fs-4"
          ></i>
          <label className="text-center" style={{ width: "65%" }}>
            <img
              src="../../../../Images/gps.png"
              alt=""
              style={{ width: "20px" }}
            />
            <span className="fw-bold fs-5 w-50 mx-2">
              {OrderInfo[0].type_order}
            </span>
          </label>
          <span className="float-start mt-2">
            <Dropdown>
              <Dropdown.Toggle variant="warning"> المساعدة </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="" onClick={() => handleShowModal()}>
                  الدعم
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <span onClick={() => CancelOrder()} className="text-danger">
                    الغاء الطلب
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>

        <div className="col-master0 col-12 m-0 mt-3 mb-4 p-2">
          <div className="row shadow0 m-0 p-1">
            <div className="col-12 p-1 text-secondary bg-white">
              <h6>
                تفاصيل الطلب : <small>{OrderInfo[0].number_order}#</small>
              </h6>
              <small>{OrderInfo[0].items}</small>
            </div>
          </div>
          {Offers.length < 1 ? (
            <ORDER_WITING />
          ) : Offers.length < 1 ? (
            <ORDER_WITING />
          ) : (
            <OFFERS_FOUND />
          )}
          {/* {Offers.length < 1 && <ORDER_WITING />}
          {Offers.length > 0 && <OFFERS_FOUND />} */}
          <MSG />
          <br />
        </div>
      </div>
      {/* <Chat /> */}
    </>
  );

  function ReOrder() {
    return (
      <>
        <button
          onClick={() => handelReOrder()}
          className="btn btn-green shadow mt-4 ps-4 mb-4 text-center w-50 m-auto"
        >
          {"اعادة الطلب "}
        </button>
      </>
    );
  }
  //========================================

  function DisableAutoAccipt() {
    return (
      <>
        <div
          style={{ height: "135px" }}
          className="col-12 mt-4 bg-light p-2 text-center mb-2"
        >
          <h4 className="fw-bold ">الرجاء الانتظار ...</h4>
          <span>سيبدا التوصيل بعد قبول عرض التوصيل من احد المندوبين </span>
        </div>
        <div className="col-12 mt-2">
          <h5 className="fw-bold text-center">
            {"  القبول التلقائى "}
            <span className="text-white bg-secondary p-0">؟</span>
            <br />
            <button
              onClick={() => handelUpdateAutoAccipt("true")}
              className="btn bg-light shadow mt-4 ps-4 text-end"
            >
              <span className="bg-green p-1 ms-3">OK</span>
              فعل الان
            </button>

            <div style={{ height: "10px" }}></div>
          </h5>
        </div>
      </>
    );
  }
  //========================================
  function EnableAutoAccipt() {
    return (
      <>
        <div
          style={{ height: "135px" }}
          className="col-12 mt-4 p-2 text-center mb-2 bg-alert-success"
        >
          <h4 className="fw-bold "> القبول التلقائى للعروض مفعل </h4>
          <span>سيقوم بقبول افضل عرض ضمن السعر المتوقع !</span>
        </div>

        <div className="col-12 mt-2">
          <h5 className="fw-bold text-center">
            {"  القبول التلقائى "}
            <span className="text-white bg-secondary p-0">؟</span>
            <br />
            <button
              onClick={() => handelUpdateAutoAccipt("false")}
              style={{ width: "140px" }}
              className="btn bg-green shadow mt-4 pe-4 text-start"
            >
              مفعل
              <span className="bg-white text-danger p-1 me-3">X</span>
            </button>

            <div style={{ height: "10px" }}></div>
          </h5>
        </div>
      </>
    );
  }
  //===============================
  function ORDER_WITING() {
    return (
      <>
        <div className="row shadow0 p-1 m-0 mt-2 mb-4 bg-white">
          <div className="col-12 p-2 m-0 text-secondary pb-4">
            <img
              src="../../../../Images/loding4.gif"
              alt=""
              style={{ width: "35px" }}
            />
            <span className="me-2 fw-bold"> {OrderInfo[0].statue} </span>
            <button className="btn btn-syan shadow p-1 px-2 float-start">
              <img
                src="../../../../Images/mony.png"
                alt=""
                style={{ width: "20px" }}
              />

              {OrderInfo[0].tips != "0" ? (
                <span
                  className="me-2  px-0"
                  onClick={() => handleShowModaltips()}
                >
                  {OrderInfo[0].tips + " جنية"}
                </span>
              ) : (
                <span
                  className="me-2  px-0"
                  onClick={() => handleShowModaltips()}
                >
                  مكافاة
                </span>
              )}
            </button>
          </div>
          <div className="col-12 mt-4 bg-light p-2">
            <small className="ms-4 fw-bold">{" سعر التوصيل المتوقع "}</small>
            <small className="fw-bold ">
              {`${parseInt(OrderInfo[0].safee - 20)} - ${OrderInfo[0].safee
                }  جنية `}
            </small>
          </div>

          {/* ==================================== */}

          {AutoAccipt == "true" ? <EnableAutoAccipt /> : null}
          {AutoAccipt == "false" ? <DisableAutoAccipt /> : null}
          {Params.re_order == "re_order" ? <ReOrder /> : null}
        </div>
      </>
    );
  }
  //===============================

  function OFFERS_FOUND() {
    return (
      <>
        <div className="row shadow0 p-1 m-0 mt-2 mb-4 bg-white">
          <div className="col-12 p-2 m-0 text-secondary pb-4">
            {Offers.map((x, index) => {
              return (
                <>
                  <div className="card p-0">
                    <div className="card-body p-0 m-0">
                      <i className="fa-solid fa-user fs-1 text-secondary ms-2"></i>
                      <span className="fs-4 fw-bold">{x.delevary_name}</span>
                      <span className="float-start">
                        {"المسافة "}
                        {OrderInfo[0].distance}
                      </span>
                      <br />
                      <h6 className="text-center">
                        {OrderInfo[0].safee != x.mony && (
                          <span className="text-decoration-line-through mx-2">
                            {OrderInfo[0].safee} {"جنية "}
                          </span>
                        )}

                        <span className="text-blue fs-3 fw-bold">
                          {x.mony} جنية
                        </span>
                      </h6>
                      <div className="text-center w-100 mt-2">
                        <button
                          className="btn btn-light mx-2 px-4 fw-bold"
                          onClick={() =>
                            DenayOffer(
                              OrderInfo[0].number_order,
                              x.delevary_id,
                              x.delevary_name,
                              x.mony
                            )
                          }
                        >
                          رفض
                        </button>
                        <button
                          className="btn btn-green mx-2 px-4 fw-bold "
                          onClick={() =>
                            ChangeOffer(
                              OrderInfo[0].number_order,
                              x.delevary_id,
                              x.delevary_name,
                              x.mony
                            )
                          }
                        >
                          <i className="fa-solid fa-check mx-1"></i>
                          {" قبول العرض  "}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  //===============================
  function SHOWTIPS() {
    return (
      <>
        <Modal show={ShowModaltips} onHide={handleCloseModaltips}>
          <Modal.Body>
            <span className="fs-4 text-danger" onClick={handleCloseModaltips}>
              X
            </span>
            <div className=" row  m-auto " style={{ maxWidth: "500px" }}>
              <div className="col-12  text-center">
                <h5 className="m-2 text-center text-secondary">
                  لو كان طلبك معقد او صعب{" "}
                </h5>
                <img src="/tips.jpg" alt="" style={{ width: "100px" }} />
                <h5 className="m-2 text-center  text-secondary">
                  قدم مكافاه للمندوب للحصول على خدمه متميزة
                </h5>
                <label
                  className={`tips  ${tips_ == "10" && " tipschange"}`}
                  onClick={() => settips("10")}
                >
                  10 جنية
                </label>
                <label
                  className={`tips  ${tips_ == "20" && " tipschange"}`}
                  onClick={() => settips("20")}
                >
                  20 جنية
                </label>
                <label
                  className={`tips  ${tips_ == "30" && " tipschange"}`}
                  onClick={() => settips("30")}
                >
                  30 جنية
                </label>
                <label
                  className={`tips  ${tips_ == "50" && " tipschange"}`}
                  onClick={() => settips("50")}
                >
                  50 جنية
                </label>
                <br />
                <span>
                  مكافاة المندوب تحفزه انة يقدم لك خدمه متميزه على طلباتك الصعبه
                </span>
                <i className="fa-solid fa-bolt text-warning"></i> <br />
                <span>
                  {" "}
                  اذا قمت بانقر على حسنا , لن نتمكن من الغاء المكافاة
                </span>
                <br />
                <button
                  className="btn btn-green my-2 w-75 fw-bold fs-5 p-1"
                  onClick={() => ChangeTips()}
                >
                  حسنا
                </button>
                <br />
                <br />
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </>
    );
  }

  //===============================
  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h5 className="text-danger text-center p-0 m-0">
                  كيف يمكننا مساعدتك{" "}
                </h5>
                <hr />
                <h5
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                  onClick={() => handleChange("اين طلبى")}
                >
                  اين طلبى
                </h5>
                <h5
                  onClick={() => handleChange("الطلب اتاخر كتير ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1 "
                >
                  {"الطلب اتاخر كتير "}
                </h5>
                <h5
                  onClick={() => handleChange("الغاء الطلب لست بحاجة لة ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                >
                  {"الغاء الطلب لست بحاجة لة "}
                </h5>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={() => handelHelp()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
