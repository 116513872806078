import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { API, MAPS_API_KEY } from "../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 30.033, // الموقع الافتراضي
  lng: 31.233,
};
export default function UpdateLocation() {
  const [LoginType, SetLoginType] = useState("");
  //--------- CHECK LOGIN -----------------------
  var userinfo = [];
  var Username = "";
  var UserID = "";
  useEffect(() => {
    if (Cookies.get("userinfo")) {
      userinfo = JSON.parse(Cookies.get("userinfo"));
      Username = userinfo[0].Name;
      UserID = userinfo[0].ID;
    }

    if (Cookies.get("LoginType")) {
      const LoginType_ = Cookies.get("LoginType");
      // console.log(Cookies.get("LoginType"));
      SetLoginType(LoginType_);
    }
  }, []);
  const [curentLocation, setCurentLocation] = useState(center);
  const [PlaceName, setPlaceName] = useState(null);
  //-------------------------------------------------------------
  const [error, setError] = useState(null);
  const [locationMessage, setLocationMessage] = useState(""); // لحفظ الرسائل
  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      // مراقبة الموقع باستمرار
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          localStorage.removeItem("CurentLocation");
          setCurentLocation({ lat: latitude, lng: longitude });
          getPlaceName(latitude, longitude);
          const location = {
            lat: latitude,
            lng: longitude,
          };
          console.log("Current location:", location);
          setLocationMessage(""); // إزالة أي رسالة خطأ
        },
        (error) => {
          // تحديد سبب الخطأ بناءً على الكود
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert(
                "لم يتم السماح بالوصول إلى الموقع. يرجى تفعيل الموقع من الإعدادات."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("الموقع غير متوفر حاليًا. حاول لاحقًا.");
              break;
            case error.TIMEOUT:
              console.error(
                "انتهت مهلة محاولة الحصول على الموقع. حاول مجددًا."
              );
              break;
            default:
              console.log("حدث خطأ غير متوقع أثناء محاولة تحديد الموقع.");
          }
          console.error("Error getting location:", error);
        },
        {
          enableHighAccuracy: false, // استخدم دقة أقل إذا أمكن
          maximumAge: 20000, // يسمح باستخدام القيم المخزنة لمدة تصل إلى 10 ثوانٍ
          timeout: 10000, // ضبط مهلة لجلب الموقع
        }
      );
    } else {
      alert("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
      console.error("خاصية تحديد الموقع غير مدعومة في هذا المتصفح.");
    }

    // تنظيف عند إلغاء المكون
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  const getPlaceName = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
      );
      const locationName = response.data.display_name;
      setPlaceName(response.data.display_name);
      const location = {
        lat: lat,
        lng: lng,
      };

      localStorage.setItem(
        "CurentLocation",
        JSON.stringify({ location, name: locationName })
      );
      UpdateLocatin_(lat, lng, locationName);
      setCurentLocation(location);
    } catch (error) {
      console.error("خطأ في جلب اسم الموقع:", error);
    }
  };
  //-------------------------------------------------------------
  async function UpdateLocatin_(latitude, longitude, locationName) {
    try {
      const LoginType_ = Cookies.get("LoginType");
      if (LoginType_ == "deller") {
        const post = {
          LoginType: LoginType_,
          Name: Username,
          ID: UserID,
          latitude: latitude,
          longitude: longitude,
          locationName: locationName,
          Action: "UpdateLocatin",
        };
        // console.log("UpdateLocatin");
        // console.log(post);
        const result = await fetch(API.updateLocatin, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        // console.log(body);
      }
    } catch (error) {
      return error;
    }
  }

  //-----------------------------------------
  // return (
  //   <>
  //     <small>{PlaceName}</small>
  //   </>
  // );
}
