//============== التحويل بين الصفحات بعد الحفظ
import { ReactNode, use, useCallback, useEffect, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";

import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import { AppValues, API, ApiAdmin } from "./Values";
import Cookies from "js-cookie";
export default function Spalesh() {
  const navigate = useNavigate();
  const [isMounted, setisMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => setisMounted(true), 1000);
    if (isMounted == true) {
      // navigate("./Login");
      console.log(1);
    }
  }, [isMounted]);
  const [Data, SetData] = useState([{Address:""}]);
  useEffect(() => {
    GetData();
  }, []);
  async function GetData() {
    try {
      const post = {
        Action: "GetCompanyInfo",
      };
      const response = await fetch(ApiAdmin + "/CompanyInfo.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data);
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    Login();

  }, []);

  const Login = () => {
    if (Cookies.get("LoginType")) {
      const LoginType = Cookies.get("LoginType");
      if (LoginType != "") {
        const StartPage = Cookies.get("StartPage");
        if (LoginType == "customers") {
          if (StartPage == "1") {
            return navigate("../Customer/StartPage");
          } else if (StartPage == "0") {
            return navigate("../Customer/Shopping");
          } else {
            Cookies.set("StartPage", "1", { expires: 365 });
            return navigate("../Customer/StartPage");
          }
        }
        if (LoginType == "deller") {
          return navigate("../Deller/home");
        }
        if (LoginType == "admin") {
          return navigate("../admin");
        }
      }
    }
  };

  return <>{isMounted ? <Index /> : <SpaleshSereen />}</>;

  function Index() {
    return (
      <div className="row text-center0 m-0 p-0">
        <div className="col-2 bg-green py-0" style={{ height: "60px" }}>
          <img src="./ic_launcher.png" alt="" style={{ width: "55px" }} />
        </div>
        <div className="col-10 bg-green py-2" style={{ height: "60px" }}>
          <span className="float-start mt-2">
            <Dropdown>
              <Dropdown.Toggle variant="">
                <i className="fa-regular fa-user fs-5 text-white"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="">
                  <span
                    onClick={() => navigate("../Login")}
                    className="fw-bold fs-6"
                  >
                    تسجيل الدخول
                  </span>
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <span
                    onClick={() => navigate("../Customer/Register")}
                    className="fw-bold"
                  >
                    انشاء حساب عميل
                  </span>
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <span
                    onClick={() => navigate("../Deller/Register")}
                    className="fw-bold"
                  >
                    انشاء حساب مندوب
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>

          <h3 className="text-center"> جى سيو اكسبريس </h3>
        </div>
        <div className="col-12">
          <h1 className="text-green text-center">J-SIO EXPRESS</h1>
          <h2 className="text-syan stc fw-bold"> جى سيو اكسبريس للنقل الذكى</h2>
          <h4>
               هى عبارة عن منصة إلكترونية تسمح للمندوبين بتقديم
            خدمات الشراء نيابة عن العملاء والتوصيل إلى منازلهم أو في المواقع
            التي يتم تحديدها مسبقاً من العملاء، حيث يقوم جى سيو اكسبريس بإتاحة
            الفرصة للعميل لاختيار المندوب المناسب للقيام بعملية التوصيل .
          </h4>
        </div>
        <div className="col-lg-6 col-12">
          <div className="card bg-light m-1 shadow">
            <div className="card-body">
              <img
                src="../Images/anything.jpeg"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
              <h3>اطلب اى شىء</h3>
              <span>
                مع شركة جى سيو اكسبريس مش محتاج تتعب نفسك , بقول لك اطلب اى شى
                واحنا هنوصلة لحد بيتك فى اسرع وقت
                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="card bg-light m-1 shadow">
            <div className="card-body">
              <img
                src="../Images/food.jpg"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />

              <h3> ماكولات ومشروبات </h3>
              <span>
                مع شركة جى سيو اكسبريس لو نفسك تاكل اى شى او تشرب ونفسك فى حاجة
                ومش قادر تنزل تشترى من المطاعم احنا هنوصل لك اللى نفسك فية مش
                هنخليك جعان هنوصل لك الاكل سخن لان عندنا مندوبين توصيل منتشرين
                فى جميع انهاء الجمهورية
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="card bg-light m-1 shadow">
            <div className="card-body">
              <img
                src="../Images/suppermarket.jpg"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
              <h3>سوبر ماركت</h3>
              <span>
                مع شركة جى سيو اكسبريس مش محتاج تتعب نفسك وتنزل تشترى من السوبر
                ماركت احنا هجيب لك اللى انت عايزة بدوب تعب منك احنا هنا فى خدمتك
                ,
                <br />
                <br />
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-12">
          <div className="card bg-light m-1 shadow">
            <div className="card-body">
              <img
                src="../Images/image1.jpeg"
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
              <h3> المحلات التجارية </h3>
              <span>
                لو انت زهقان من المواصلات ونفسك تشترى اى مستلزمات لمنزلك او
                شركتك ومش قادر تنزل من المواصلات وتعبها مع شركة جى سيو اكسبريس
                مش محتاج تتعب نفسك , واحنا هنشترى لك اللى انت عايزة وهنوصلة لك
                فى اسرع وقت
              </span>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="col-12 bg-dark text-white py-4">
          <div className="row">
            <div className="col-lg-3 col-12 text-center">
              <img src="./ic_launcher.png" alt="" style={{ width: "155px" }} />
              <h3 >JSIO EXPRESS</h3>
              <span>
                يمكن ل جسيو اكسبريس أن يحصل لك على أي شيء من أي مكان تريدة
              </span>
            </div>
            <div className="col-lg-5 col-12 text-center">
              <h3 className="text-green">معلومات الاتصال</h3>
              <h6>{Data[0].Address}</h6>
              <h6>موبايل : {Data[0].Mobil}</h6>
              <h6>البريد الالكترونى : {Data[0].Email}</h6>

            </div>

            <div className="col-lg-4 col-12 text-center">
              <h3  className="text-green">تحميل التطبيق</h3>
              <br />
              <img className="mx-1" src="./playstore.png" alt="" style={{ width: "155px" }} />
              <img className="mx-1" src="./appstore.png" alt="" style={{ width: "155px" }} />
            </div>
            
            <div className="col-lg-12 col-12 text-center mt-4" >
              <small  className="text-light"> جميع الحقوق محفوظة لشركة الاوائل للبرمجيات </small>
             
            </div>
          </div>
        </div>
      </div>
    );
  }

  function SpaleshSereen() {
    return (
      <div
        className="row text-center m-0 p-0"
        style={{ width: "100vw", height: "100vh", background: "#00BF6F" }}
      >
        <img className="welcomScreenImg" src="./welecome.png" alt="welecome" />
      </div>
    );
  }
}
