import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useMemo, useEffect, useRef } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheck/page";
import Header_ from "../compoenents/Header_";

import {
  Reviews0,
  Reviews1,
  Reviews2,
  Reviews3,
  Reviews4,
  Reviews5,
} from "../../Reviews/page";
export default function AdminCustomrs() {
  //---------ShowModal----------------------------
  const [TypeModal, SetTypeModal] = useState("");
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const navigate = useNavigate();
  const [Inputs, setInputs] = useState([]);
  const [SearchText, SetSearchText] = useState("");
  const [CustomerName, SetCustomerName] = useState("");
  const [CustomerID, SetCustomerID] = useState("");
  const [Reviews, SetReviews] = useState("0");

  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetData();
  }, []);

  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  // جلب الرسائل بشكل دوري
  useEffect(() => {
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        GetData();
      }, 5000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive]);

  //-------------------------------------------------------------
  async function GetData() {
    try {
      const post = {
        Action: "AdminCustomrs",
        SearchText: SearchText,
      };
      // SetData([]);
      const response = await fetch(ApiAdmin + "/Customrs.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data_ = await response.json();
      // console.log(data_);
      const datanew = JSON.stringify(data_);
      const dataold = JSON.stringify(Data);
      // if (datanew.length != dataold.length) {
      // if (Orders.length !== data.length) {
      if (datanew !== dataold) {
        SetData(data_);
      }
    } catch (error) {
      return error;
    }
  }

  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------------------------
  const handleTextClear = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };

  //-------------------------------------------------------------
  const handleChangeSearchText = (e) => {
    setIntervalActive(false);
    SetSearchText(e.target.value);
    console.log(SearchText);
  };
  const handleTextClearSearchText = (e) => {
    SetSearchText("");
  };
  //-------------------------------------------------------------
  async function Active(ID, Name, Statue) {
    setIntervalActive(false);
    var Statue_ = "";
    if (Statue == "disable") {
      Statue_ = "enable";
    }
    if (Statue == "enable") {
      Statue_ = "disable";
    }
    try {
      const post = {
        Action: "ActiveCustomer",
        ID: ID,
        Name: Name,
        Statue: Statue_,
      };

      const response = await fetch(ApiAdmin + "/Customrs.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      // console.log(data);
      SetData(data);
      setIntervalActive(true);
    } catch (error) {
      return error;
    }
  }
  //--------------------------------------------------

  async function DeleteDeller(ID, Name) {
    if (window.confirm(`هل تريد حذف  ${Name} `)) {
      try {
        const post = {
          Action: "Delete",
          ID: ID,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/Customrs.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        // console.log(data);
        SetData(data);
      } catch (error) {
        return error;
      }
    }
  }

  //-------------------------------------------------------------
  async function ChangeKobone(ID, Name) {
    setIntervalActive(false);
    SetTypeModal("Kobon");
    SetCustomerID(ID);
    SetCustomerName(Name);
    handleShowModal();
  }
  //-------------------------------------------------------------
  const [Datakobon, SetDatakobon] = useState([]);
  async function Getkobon(NameID, Name) {
    try {
      setIntervalActive(false);
      ChangeKobone(NameID, Name);
      const post = {
        Action: "GetkobonToName",
        account_type: "عميل",
        NameID: NameID,
        Name: Name,
      };
      SetDatakobon([]);
      const response = await fetch(ApiAdmin + "/kobon.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      // console.log(data);
      SetDatakobon(data);
    } catch (error) {
      return error;
    }
  }
  //----------------------
  async function DeleteKobon(ID, Descount, Name, NameID) {
    setIntervalActive(false);
    if (window.confirm(`هل تريد حذف كوبون  ${Name} `)) {
      setIntervalActive(false);
      try {
        const post = {
          Action: "DeleteKobon_",
          ID: ID,
          Descount: Descount,
          account_type: "عميل",
          NameID: NameID,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/kobon.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        // console.log(data);
        Getkobon(NameID, Name);
        GetData();
        setIntervalActive(true);
      } catch (error) {
        return error;
      }
    }
  }
  //----------------------

  const handleSubmitAddKobon = async (e) => {
    setIntervalActive(false);
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد اضافة كوبون  ${CustomerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/kobon.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg == "Success") {
          // console.log(data.msg);
          handleCloseModal();
          // alert("تم اضافة الكوبون");
          GetData();
          setIntervalActive(true);
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //----------------------
  //-------------------------------------------------------------
  async function ChangeAlert(ID, Name) {
    setIntervalActive(false);
    SetTypeModal("Alert");
    SetCustomerID(ID);
    SetCustomerName(Name);
    handleShowModal();
  }
  //----------------------
  const handleSubmitAlert = async (e) => {
    setIntervalActive(false);
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد ارسال تنبية  ${CustomerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/Alerts.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg == "Success") {
          console.log(data.msg);
          handleCloseModal();
          setIntervalActive(true);
          alert("تم ارسال التنبية ");
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //==================================================
  async function ChangeAlertAll(ID, Name) {
    setIntervalActive(false);
    SetTypeModal("AlertAll");
    handleShowModal();
  }
  //---------------------------
  const handleSubmitAlertAll = async (e) => {
    setIntervalActive(false);
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد ارسال تنبية الى كل العملاء  `)) {
      try {
        const response = await fetch(ApiAdmin + "/Alerts.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg == "Success") {
          console.log(data.msg);
          handleCloseModal();
          setIntervalActive(true);
          alert("تم ارسال التنبية ");
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //======================================================
  //-------------------------------------------------------------
  async function ChangeSubScribe(ID, Name, reviews) {
    setIntervalActive(false);
    SetTypeModal("SubScribe");
    SetCustomerID(ID);
    SetCustomerName(Name);
    SetReviews(reviews);
    handleShowModal();
  }

  //---------------------------
  const handleSubmiSubScribe = async (e) => {
    e.preventDefault();
    setIntervalActive(false);
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد تقييم  ${CustomerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/Reviews.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg != "") {
          console.log(data.msg);
          handleCloseModal();
          setIntervalActive(true);
          GetData();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Header_ />
      <div dir="rtl" className="row p-0 m-0 mt-0 text-center">
        <h3 className="text-orange fw-bold py-1">العملاء</h3>
        <div className="col-lg-2  col-4 my-2">
          <button
            className="btn btn-info text-white p-1 px-1 m-0 fw-bold"
            onClick={() => ChangeAlertAll()}
          >
            تنبية الى العملاء
          </button>
        </div>
        <div className="col-lg-10  col-8 my-2">
          <div className="div-SearchText">
            <input
              type="text"
              name="SearchText"
              className="SearchText"
              onChange={handleChangeSearchText}
              onClick={handleTextClearSearchText}
              value={SearchText}
              autoComplete="off"
              // placeholder="بحث برقم تليفون او رقم اوردر او اسم مندوب"
            />
            <button className="btn btn-warning m-0" onClick={GetData}>
              Search
            </button>
          </div>
        </div>
        <div className="col-12 mt-0 bg-light0 text-center">
          <div className="table-responsive">
            <table
              dir="rtl"
              className="table table-striped0  m-auto"
              style={{ minWidth: "920px" }}
            >
              <thead>
                <tr>
                  <th width="65px"> الحالة</th>
                  <th width="30px">كوبون</th>
                  <th width="30px"> تنبية</th>
                  <th width="120px">الاسم</th>
                  <th width="200px">عنوان</th>
                  <th width="80px">الميلاد</th>
                  <th width="80px">موبايل</th>
                  <th width="80px"> الرصيد</th>
                  <th width="100px">التقييم</th>
                  <th width="30px">حذف</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((x, index) => {
                  var classStatu = "";

                  var classRassed = "";
                  var mony_ = parseFloat(x.Mony);
                  if (mony_ > 0) {
                    classRassed = "text-green";
                  } else {
                    classRassed = "text-danger";
                  }

                  if (x.Statue == "Active") {
                    classStatu = "bg-green";
                  }
                  if (x.Statue != "Active") {
                    classStatu = "bg-warning text-white";
                  }

                  var classStatu = "";
                  var Statu = "";
                  if (x.Statue == "enable") {
                    classStatu = "bg-green";
                    Statu = "نشط";
                  }
                  if (x.Statue != "enable") {
                    classStatu = "bg-danger text-white";
                    Statu = "غير نشط";
                  }

                  return (
                    <tr key={index}>
                      <td className="text-center px-1">
                        <label
                          onClick={() => Active(x.ID, x.Name, x.Statue)}
                          className={`p-0 w-100 fw-bold rounded ${classStatu}`}
                        >
                          {Statu}
                        </label>
                      </td>

                      <td className="text-center text-green fw-bold">
                        <span
                          className="text-center text-green fw-bold"
                          onClick={() => Getkobon(x.ID, x.Name)}
                        >
                          كوبون
                        </span>
                      </td>
                      <td className="text-center text-green fw-bold">
                        <i
                          onClick={() => ChangeAlert(x.ID, x.Name)}
                          className="fa-solid fa-bell m-0 p-0  text-secondary fs-6"
                        ></i>
                      </td>
                      <td>{x.Name}</td>
                      <td>{x.State + " - " + x.Address}</td>
                      <td className="text-center">{x.BirthDate}</td>
                      <td className="text-center">{x.Mobail}</td>
                      <td className="text-center">
                        <span className={`fw-bold  ${classRassed}`}>
                          {x.Mony}
                        </span>
                      </td>

                      <td
                        className="text-center"
                        onClick={() => ChangeSubScribe(x.ID, x.Name, x.reviews)}
                      >
                        {x.reviews == "0" && <Reviews0 />}
                        {x.reviews == "1" && <Reviews1 />}
                        {x.reviews == "2" && <Reviews2 />}
                        {x.reviews == "3" && <Reviews3 />}
                        {x.reviews == "4" && <Reviews4 />}
                        {x.reviews == "5" && <Reviews5 />}
                      </td>

                      <td
                        className="text-center"
                        onClick={() => DeleteDeller(x.ID, x.Name)}
                      >
                        <i className=" text-danger fs-6 fw-bold fa-regular fa-xmark p-0"></i>
                      </td>
                      {/* <td className="text-center">{x.Description}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalSHOW />
    </>
  );

  function ModalSHOW() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <h6 className="text-danger" onClick={handleCloseModal}>
              X
            </h6>

            {TypeModal == "Kobon" && <KobonForm />}
            {TypeModal == "Alert" && <AlertForm />}
            {TypeModal == "AlertAll" && <AlertFormAll />}
            {TypeModal == "SubScribe" && <SubScribe />}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  function KobonForm() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitAddKobon}>
          <input type="hidden" name="Name" value={CustomerName} />
          <input type="hidden" name="NameID" value={CustomerID} />
          <input type="hidden" name="Action" value="Add_Kobon" />
          <input type="hidden" name="account_type" value="عميل" />
          <input type="hidden" name="Top" value="0" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <h4 className="text-green">اضافة كوبون </h4>
              <h6> الاسم :{CustomerName}</h6>
            </div>
            <div className="col-12">
              <label htmlFor="">نسبة خصم الكوبون</label>
              <input
                type="number"
                name="Descount"
                className="form-control text-center mt-1"
              />
            </div>
            {/* <div className="col-12">
              <label htmlFor="">نسبة خصم بحد اقصى </label>
              <input
                type="number"
                name="Top"
                className="form-control text-center"
              />
            </div> */}

            <div className="col-6">
              <label htmlFor=""> انتهاء الصلاحية</label>
              <input type="date" name="Expir" className="form-control  mt-1" />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-green fw-bold fs-5 w-100">
                اضافة كوبون
              </button>
            </div>
            <br />
          </div>
        </form>
        {Datakobon.length > 0 && (
          <>
            <h4>الكوبونات السابقة </h4>
            <table className="table" cellspacing="1">
              <thead>
                <tr>
                  <th>الكوبون</th>
                  <th className="text-center">القيمة</th>
                  <th>حذف</th>
                </tr>
              </thead>
              <tbody>
                {Datakobon.map((x, index) => {
                  return (
                    <tr key={index}>
                      <td className=" p-0">{x.Info}</td>
                      <td className="text-center p-0">{x.Descount}</td>
                      <td
                        className="text-center"
                        onClick={() =>
                          DeleteKobon(x.ID, x.Descount, x.Name, x.NameID)
                        }
                      >
                        <i className=" text-danger fs-6 fw-bold fa-regular fa-xmark p-0"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <br />
          </>
        )}
        ;
      </>
    );
  }

  //========================================
  function AlertForm() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitAlert}>
          <input type="hidden" name="Name" value={CustomerName} />
          <input type="hidden" name="NameID" value={CustomerID} />
          <input type="hidden" name="Action" value="AddAlert" />
          <input type="hidden" name="account_type" value="عميل" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <h4 className="text-green"> ارسال تنبيه </h4>
              <h6> الاسم :{CustomerName}</h6>
            </div>
            <div className="col-12">
              <label htmlFor=""> رسالة التنبيه</label>
              <input
                type="text"
                name="AlertText"
                className="form-control text-center"
                autoComplete="off"
              />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-green fw-bold fs-5 w-100">
                ارسال التنبية
              </button>
            </div>
            <br />
          </div>
        </form>
      </>
    );
  }

  //========================================
  function AlertFormAll() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitAlertAll}>
          <input type="hidden" name="Action" value="AddAlertAll" />
          <input type="hidden" name="account_type" value="عميل" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <h4 className="text-green"> ارسال تنبية الى كل العملاء </h4>
            </div>
            <div className="col-12">
              <label htmlFor=""> رسالة التنبيه</label>
              <input
                type="text"
                name="AlertText"
                className="form-control text-center mt-1"
                autoComplete="off"
              />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-danger fw-bold fs-6 w-100">
                ارسال التنبية
              </button>
            </div>

            <br />
          </div>
        </form>
      </>
    );
  }

  //========================================
  function SubScribe() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmiSubScribe}>
          <input type="hidden" name="account_type" value="عميل" />
          <input type="hidden" name="Name" value={CustomerName} />
          <input type="hidden" name="NameID" value={CustomerID} />
          <input type="hidden" name="Action" value="AddReviews" />
          <input type="hidden" name="Reviews" value={Reviews} />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center mb-4">
              <h4 className="text-green"> تقييم العملاء </h4>
              <h6> الاسم : {CustomerName}</h6>

              <h6> التقييم : {Reviews}</h6>
            </div>

            <div className="col-12 text-center">
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("0")}
              >
                0
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("1")}
              >
                1
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("2")}
              >
                2
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("3")}
              >
                3
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("4")}
              >
                4
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("5")}
              >
                5
              </button>
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-danger fw-bold fs-6 w-100">
                تقييم
              </button>
            </div>

            <br />
          </div>
        </form>
      </>
    );
  }
}
