import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheck/page";
import Chat from "../../Forms/Chat";
import { Modal, Button, Dropdown } from "react-bootstrap";
import ErrorAudio from "../../../sound/2.wav";
import axios from "axios";
import { ApiUrl, API, MAPS_API_KEY } from "../../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import UpdateLocatin from "../../UpdateLocation/page";

const mapContainerStyle = {
  width: "188px",
  height: "150px",
};

const center = {
  lat: 30.033,
  lng: 31.233,
};

export default function DellerCHat() {
  const params = useParams();
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const [ShowModalHelep, SetShowModalHelep] = useState(false);
  const [ModalRegisterType, SetModalRegisterType] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const handleCloseModalHelep = () => SetShowModalHelep(false);
  const handleShowModalHelep = () => SetShowModalHelep(true);
  const [Shakwa, SetShakwa] = useState("");
  const [Total, SetTotal] = useState("");

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  const [msgType, setmsgType] = useState("text");
  const audioRef = useRef(new Audio("../../sound/5.wav")); // استبدل المسار الفعلي لملف الصوت
  const chatInfo = JSON.parse(localStorage.getItem("chatInfo"));
  const libraries = useMemo(() => ["places", "geometry"], []);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    console.log("libraries success");
    setIsLoaded(true);
  };

  //========================================
  const [curentLocation, setCurentLocation] = useState(null);
  const [PlaceName, setPlaceName] = useState(null);
  const [CheckLocation, SetCheckLocation] = useState(0);
  const [pickupLocation, setPickupLocation] = useState(center); // نقطة الاستلام
  const [distance, setDistance] = useState("0"); // المسافة بين نقطتي الاستلام والتسليم
  const [intervalActive1, setIntervalActive1] = useState(true);
  const fetchIntervalRef1 = useRef(null);
  useEffect(() => {
    if (intervalActive1) {
      fetchIntervalRef1.current = setInterval(() => {
        GetMyLocation();
        SetCheckLocation(parseInt(CheckLocation) + parseInt(1));
      }, 1000);

      function GetMyLocation() {
        const savedCurentLocation = JSON.parse(
          localStorage.getItem("CurentLocation")
        );

        if (savedCurentLocation) {
          console.log("savedCurentLocation" + savedCurentLocation);
          setCurentLocation(savedCurentLocation.location);
          setPlaceName(savedCurentLocation.name);
          calculateDistance(pickupLocation, savedCurentLocation.location);
        }
      }
    }
    return () => clearInterval(fetchIntervalRef1.current);
  }, [intervalActive1, CheckLocation]);

  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  const [OrderInfo, setOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const navigate = useNavigate();
  let [audio] = useState(new Audio(ErrorAudio));
  let [hasInteracted, setHasInteracted] = useState(false);
  let handleUserInteraction = () => {
    setHasInteracted(true);
    audio.load(); // Preload the sound to ensure playback is ready
  };

  useEffect(() => {
    fetchMessages();
  }, []);
  //-------------------------------------------------------------

  useEffect(() => {
    audio.muted = true;
    if (intervalActive && ShowModal === false) {
      fetchIntervalRef.current = setInterval(() => {
        fetchMessages();
      }, 3000); // التكرار كل 3 ثوانٍ
    } else {
      clearInterval(fetchIntervalRef.current);
    }

    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive, ShowModal]);

  const fetchMessages = async () => {
    if (ShowModal === true) {
      return;
    }
    const post = {
      order_id: params.number_order,
      chat_type: "order",
      Action: "delevary",
    };
    const response = await fetch(`${ApiUrl}/orders/GetMessage.php`, {
      method: "POST",
      body: JSON.stringify(post),
    });
    const data = await response.json();
    setOrderInfo(data.OrderInfo);
    setPickupLocation({
      lat: parseFloat(data.OrderInfo[0].from_latitude),
      lng: parseFloat(data.OrderInfo[0].from_longitude),
    });
    const total_ =
      parseInt(data.OrderInfo[0].safee) +
      parseInt(data.OrderInfo[0].items_price) +
      parseInt(data.OrderInfo[0].tips);
    SetTotal(total_);
    //-------------------------
    const datanew = JSON.stringify(data.dataMessage);
    const dataold = JSON.stringify(messages);
    if (datanew != dataold) {
      setMessages(data.dataMessage);
      if (hasInteracted) {
        setHasInteracted(false);
        // audio.muted = false;
        audio
          .play()
          .catch((error) => console.error("Audio playback failed:", error));
      }
    } else {
      console.log("old data");
    }
  };

  // دالة حساب المسافة بين نقطتين
  const calculateDistance = (pickup, delivery) => {
    const R = 6371e3; // نصف قطر الأرض بالأمتار
    const φ1 = (pickup.lat * Math.PI) / 180; // تحويل درجة العرض الأولى إلى راديان
    const φ2 = (delivery.lat * Math.PI) / 180; // تحويل درجة العرض الثانية إلى راديان
    const Δφ = ((delivery.lat - pickup.lat) * Math.PI) / 180; // فرق العرض بالراديان
    const Δλ = ((delivery.lng - pickup.lng) * Math.PI) / 180; // فرق الطول بالراديان

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceInMeters = R * c; // المسافة بالأمتار
    const distanceInKilometers = distanceInMeters / 1000; // المسافة بالكيلومترات

    // تحديد الوحدات وعرض النتيجة
    let result;
    if (distanceInKilometers >= 1) {
      result = `${distanceInKilometers.toFixed(2)} كم`; // إذا كانت المسافة بالكيلومترات
    } else {
      result = `${distanceInMeters.toFixed(0)} م`; // إذا كانت المسافة بالأمتار
    }

    // console.log("Distance: " + result);
    setDistance(result); // تخزين المسافة مع الوحدات

    return result; // النتيجة مع الوحدات
  };

  //========================================

  const sendMessage = async () => {
    // audioRef.current.play();
    // handleUserInteraction();
    const post = {
      order_id: params.number_order,
      sender_id: OrderInfo[0].delevary_id,
      sender_name: OrderInfo[0].delevary_name,
      receiver_id: OrderInfo[0].customer_id,
      receiver_name: OrderInfo[0].customer_name,
      type: "text",
      chat_type: "order",
      message: message,
      Action: "SaveMessage",
    };

    if (message) {
      const response = await fetch(`${ApiUrl}/orders/SaveMessages.php`, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      fetchMessages();
      setMessage("");
      setAudioBlob(null);
    }
  };
  //----------------------------------------
  const [isRecord, setisRecord] = useState(false);
  const Recording = async () => {
    if (isRecord) {
      setisRecord(false);
      stopRecording();
    } else {
      setisRecord(true);
      startRecording();
    }
  };

  // حالات التسجيل الصوتي
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("التسجيل الصوتي غير مدعوم في هذا المتصفح.");
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
        audioBitsPerSecond: 64000, // تقليل معدل البت لتحسين السرعة
      });
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      // mediaRecorderRef.current.ondataavailable = async (event) => {
      //   if (event.data.size > 0) {
      //     const chunkBlob = new Blob([event.data], { type: "audio/webm" });
      //     await sendAudioChunk(chunkBlob);
      //   }
      // };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/webm" });
        setAudioBlob(audioBlob); // حفظ البيانات الصوتية
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      console.log("بدأ التسجيل...");
    } catch (error) {
      console.error("خطأ أثناء تسجيل الصوت:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      sendAudioMessage();
      console.log("تم إيقاف التسجيل.");
    }
  };

  const sendAudioMessage = async () => {
    if (!audioBlob) {
      alert("لا يوجد ملف صوتي لإرساله.");
      return;
    }

    const formData = new FormData();
    formData.append("Action", "SaveMessage");
    formData.append("order_id", params.number_order);
    formData.append("sender_id", OrderInfo[0].delevary_id);
    formData.append("sender_name", OrderInfo[0].delevary_name);
    formData.append("receiver_id", OrderInfo[0].customer_id);
    formData.append("receiver_name", OrderInfo[0].customer_name);
    formData.append("type", "audio");
    formData.append("audio_file", audioBlob, "recording.webm");
    formData.append("message", "audio");
    formData.append("chat_type", "order");
    try {
      const response = await axios.post(API.SaveMessagesSound, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Progress: ${progress}%`);
        },
      });

      // const data = await response.json();

      console.log(response.data.success);
      if (response.data.success) {
        fetchMessages(); // تحديث الرسائل
        // alert(response.data.error);
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleScroll = (event) => {
    const target = event.target;
    const atBottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    setIsAtBottom(atBottom);

    if (!atBottom) {
      setIntervalActive(false);
    } else {
      setIntervalActive(true);
    }
  };

  //-----------------------------------------
  // دالة لاختيار الكلمة الأولى من النص
  function change_first_word(inputText) {
    let firstWord = inputText;
    if (inputText) {
      firstWord = inputText.split(" ")[0];
    }
    return firstWord;
  }
  const handleChange = (e) => {
    SetShakwa(e);
  };
  async function handelHelp() {
    handleCloseModal();
    if (window.confirm("هل تريد ارسال شكوى للدعم")) {
      try {
        const post = {
          account_type: "عميل",
          name: Username,
          name_id: UserID,
          number_order: params.number_order,
          type_shakwa: Shakwa,
          type_order: OrderInfo[0].type_order,
          Action: "Help",
        };
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        navigate("../Customer/Orders");
      } catch (error) {
        return error;
      }
    }
  }

  async function CancelOrder() {
    if (
      OrderInfo[0].statue == "بانتظار العروض" ||
      OrderInfo[0].statue == "بانتظار التاكيد" ||
      OrderInfo[0].statue == "انتظار الموافقة"
    ) {
      if (window.confirm("هل تريد  الغاء الطلب")) {
        try {
          const post = {
            number_order: params.number_order,
            description: "لست بحاجة للطلب",
            statue: "ملغى",
            Action: "CancelOrder",
          };
          const result = await fetch(API.Orders, {
            method: "POST",
            body: JSON.stringify(post),
          });
          const body = await result.json();
          console.log(body);
          navigate("../Customer/Orders");
        } catch (error) {
          return error;
        }
      }
    }
  }
  //-------------------------------------------------------------
  async function EditeOrder(Edite) {
    // if (admininfo[0].add != "on") {
    //   var Edite_ = `هل تريد  ${Edite}  الاوردر`;
    //   if (window.confirm(Edite_)) {
    //     try {
    //       const post = {
    //         number_order: number_order,
    //         Edite: Edite,
    //         Action: "EditeOrder",
    //       };
    //       const response = await fetch(ApiAdmin + "/orders.php", {
    //         method: "POST",
    //         body: JSON.stringify(post),
    //       });
    //       const data = await response.json();
    //       console.log(data);
    //       handleCloseModal();
    //       GetOrders();
    //     } catch (error) {
    //       return error;
    //     }
    //   }
    // } else {
    //   alert("عفوا ليس لك الصلاحية بهذة العملية");
    // }
  }

  function GoToPayment() {
    const Vesanumber_order = {
      number_order: params.number_order,
      type: "sdadOrder",
    };
    Cookies.set("Vesanumber_order", JSON.stringify(Vesanumber_order), {
      expires: 1,
    }); // Sets a cookie for 365 days

    navigate(`../Payment/${Total}`);
  }

  const imgstyle = {
    height: "100%",
  };
  let classStatu = "";
  if (OrderInfo[0].statue == "انتظار الموافقة") {
    classStatu = "bg-orange";
  }
  if (OrderInfo[0].statue == "تم الموافقة") {
    classStatu = "bg-danger";
  }

  if (OrderInfo[0].statue == "جارى التاكيد") {
    classStatu = "bg-primary";
  }

  if (OrderInfo[0].statue == "جارى التنفيذ") {
    classStatu = "bg-orange";
  }
  if (OrderInfo[0].statue == "جارى التوصيل") {
    classStatu = "bg-syan";
  }
  if (OrderInfo[0].statue == "تم التوصيل") {
    classStatu = "bg-green";
  }
  function uploadImage() {
    var params_ = OrderInfo[0].number_order + `/`;
    params_ += userinfo[0].ID + `/`;
    params_ += userinfo[0].Name + `/`;
    params_ += OrderInfo[0].customer_id + `/`;
    params_ += OrderInfo[0].customer_name + `/`;
    params_ += "order";
    //  return console.log(params_);
    navigate(`../UploadImage/` + params_);
  }

  //=============================================
  //-------------------------------------------------------------
  async function handelOrderAction() {
    if (window.confirm("هل تريد  تغيير الحالة")) {
      try {
        const post = {
          Name: Username,
          ID: UserID,
          latitude: curentLocation.lat,
          longitude: curentLocation.lng,
          delevary_distanse_start: distance,
          Action: "DellerUpdateOrederStatue",
          statue: OrderInfo[0].statue,
          number_order: OrderInfo[0].number_order,
        };
        //  return console.log(post);
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        if (body.error != "") {
          return alert(body.error);
        }
        localStorage.setItem(
          "Vesanumber_order",
          JSON.stringify({ number_order: OrderInfo[0].number_order })
        );

        fetchMessages();
        if (body[0].statue == "جارى التوصيل") {
          handleShowModal();
        }
      } catch (error) {
        return error;
      }
    }
  }

  return (
    <>
      {/* {!ShowModal && <UpdateLocatin />} */}
      <div
        className="p-1 row  text-center m-auto"
        style={{ maxWidth: "600px" }}
      >
        <div className="col-1 py-0 text-center">
          <Link
            to={`../Deller/Orders`}
            // to={`../Deller/OrderInfo/${params.number_order}`}
            className="float-end"
          >
            <i className="fa-solid fa-angle-right mt-3 fs-4 text-info"></i>
          </Link>
        </div>

        <div className="col-10 py-2 text-center">
          <span className="fs-6 fw-bold"> {OrderInfo[0].type_order}</span>
          <br />

          {CheckLocation < 2 ? (
            <img
              src="../../../Images/loding2.gif"
              alt=""
              style={{ width: "30px" }}
            />
          ) : (
            OrderInfo[0].statue != "تم التوصيل" && (
              <>
                <small
                  className={`rounded px-2 mx-1 text-white ${classStatu}`}
                  onClick={() => handelOrderAction()}
                >
                  {OrderInfo[0].statue}
                </small>

                {/* <small>{PlaceName}</small> */}
              </>
            )
          )}

          <small dir="ltr" className="text-secondary">
            #{OrderInfo[0].number_order}
          </small>
        </div>

        <div className="col-1 px-1 text-center">
          <i
            className="fa-solid fa-list  fs-4 text-info mt-3 mx-2"
            onClick={handleShowModal}
          ></i>
          {/* 
          <Dropdown>
            <Dropdown.Toggle variant="">
              <i className="fa-solid fa-list  fs-4 text-info mt-3 mx-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <h6
                  className="text-green fw-bold fs-5 text-center"
                  onClick={() =>
                    navigate(`../CreateFatora/` + OrderInfo[0].number_order)
                  }
                >
                  اصدار فاتورة
                </h6>
              </Dropdown.Item>
              <Dropdown.Item href="#">
                <h6
                  className="text-green fw-bold fs-5 text-center"
                  onClick={() =>
                    navigate("../Deller/OrderInfo/" + OrderInfo[0].number_order)
                  }
                >
                  بيان الطلب
                </h6>
              </Dropdown.Item>

              <Dropdown.Item href="#">
                <h6
                  className="text-green fw-bold fs-5 text-center"
                  onClick={() => navigate("../DellerCHatShkawa")}
                >
                  المساعدة
                </h6>
              </Dropdown.Item>
              <Dropdown.Item href="#">
                <h6
                  className=" fw-bold fs-5 text-center"
                  onClick={() => handleCloseModal()}
                >
                  تراجع
                </h6>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
 */}
        </div>

        <div className="col-1 bg-syan-light p-1 text-center">
          <i className="fa-solid fa-user fs-2 text-syan mt-3"></i>
        </div>
        <div className="col-10 py-2 bg-syan-light mb-0 text-end">
          <span>
            {change_first_word(OrderInfo[0].customer_name)}
            <br />
            <small className="text-syan fw-bold">
              {`المجموع ` + Total + " جنية "}
            </small>

            {OrderInfo[0].payment_type == "بطاقة بنكية" &&
              OrderInfo[0].creat_fatora != "no" && (
                <img
                  src="../../../Images/visa.png"
                  alt="visa"
                  className="me-1"
                  style={{ width: "40px" }}
                  onClick={() => GoToPayment(Total)}
                />
              )}
          </span>
        </div>
        <div className="col-1 bg-syan-light p-0 pt-3 text-center">
          <Link to={`tel:${OrderInfo[0].mobail}`} target="_blank">
            <button className="btn btn-green mx-1 fs-6 fw-bold btn-call mt-1">
              <i className="fa-solid fa-phone"></i>
            </button>
          </Link>
        </div>

        <div className="col-12 px-2 mt-1 text-center">
          <div className="row">
            {/* {audioBlob && (
              <audio controls src={URL.createObjectURL(audioBlob)}></audio>
            )} */}

            <div
              className="col-12 text-end bg-gray chat_msg"
              onScroll={handleScroll}
            >
              <div className="text-end">
                <LoadScript
                  googleMapsApiKey={MAPS_API_KEY}
                  libraries={libraries}
                  onLoad={handleLoad}
                >
                  <label
                    className="bg-white p-1 text-end "
                    style={{
                      // minHeight: "300px",
                      width: "200px",
                      border: "2px solid #7FD7EB",
                      marginTop: "10px",
                      borderRadius: "10px",
                      padding: "2px",
                    }}
                  >
                    <small>الاستلام من</small>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={{
                        lat: parseFloat(OrderInfo[0].from_latitude),
                        lng: parseFloat(OrderInfo[0].from_longitude),
                      }}
                      zoom={14}
                      // mapTypeId="satellite"
                      onLoad={(mapInstance) => {}}
                    >
                      <Marker
                        position={{
                          lat: parseFloat(OrderInfo[0].from_latitude),
                          lng: parseFloat(OrderInfo[0].from_longitude),
                        }}
                      />
                    </GoogleMap>

                    <small style={{ fontSize: "11px" }}>
                      {OrderInfo[0].from_plase}
                    </small>
                    <br />
                    <Link
                      to={`https://www.google.com/maps?q=${OrderInfo[0].from_latitude},${OrderInfo[0].from_longitude}`}
                    >
                      <label
                        className="my-1 text-center fw-bold text-syan w-100 "
                        style={{ fontSize: "14px" }}
                      >
                        التوجة لمكان الاستلام
                      </label>
                    </Link>
                  </label>

                  <br />

                  <label
                    className="bg-white p-1 text-end "
                    style={{
                      // minHeight: "300px",
                      width: "200px",
                      border: "2px solid #7FD7EB",
                      marginTop: "10px",
                      borderRadius: "10px",
                      padding: "2px",
                    }}
                  >
                    <small>التوصيل إلى</small>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={{
                        lat: parseFloat(OrderInfo[0].to_latitude),
                        lng: parseFloat(OrderInfo[0].to_longitude),
                      }}
                      zoom={14}
                      // mapTypeId="satellite"
                      onLoad={(mapInstance) => {}}
                    >
                      <Marker
                        position={{
                          lat: parseFloat(OrderInfo[0].to_latitude),
                          lng: parseFloat(OrderInfo[0].to_longitude),
                        }}
                      />
                    </GoogleMap>

                    <small style={{ fontSize: "11px" }}>
                      {OrderInfo[0].to_plase}
                    </small>
                    <br />
                    <Link
                      to={`https://www.google.com/maps?q=${OrderInfo[0].to_latitude},${OrderInfo[0].to_longitude}`}
                    >
                      <label
                        className="my-1 text-center fw-bold text-syan w-100 mb-2"
                        style={{ fontSize: "14px" }}
                      >
                        التوجة لمكان التسليم
                      </label>
                    </Link>
                  </label>
                </LoadScript>
              </div>

              {messages.map((msg, index) => {
                const timestampDate = new Date(msg.created_at);
                const currentDate = new Date();
                // حساب الفرق بين الوقت الحالي والتاريخ الموجود في timestamp
                const timeDifference =
                  (currentDate - timestampDate) / (1000 * 60 * 60); // الفرق بالساعة
                // تحديد ما إذا كان الوقت أكبر من 24 ساعة
                const created_at =
                  timeDifference >= 24
                    ? timestampDate.toLocaleDateString()
                    : timestampDate.toLocaleTimeString();

                const class_ =
                  msg.sender_id === OrderInfo[0].delevary_id
                    ? "text-chatSender"
                    : "text-chatReciver";
                const class_show =
                  msg.statue != "no" ? "text-green" : "text-secondary";

                return (
                  <div key={index}>
                    {msg.type == "fatora" && (
                      <>
                        <div className="text-start">
                          <label
                            className="bg-white p-2 text-end "
                            style={{
                              maxHeight: "300px",
                              border: "2px solid #7FD7EB",
                              marginTop: "10px",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <small className="fw-bold">
                              {"    تم اصدار الفاتورة بواسطة "}
                              {OrderInfo[0].delevary_name}
                            </small>
                            <hr />
                            <small>
                              {" قيمة الطلب : "}
                              {parseFloat(OrderInfo[0].items_price, 2)}
                              {" جنية "}
                            </small>
                            <br />
                            <small>
                              {" قيمة التوصيل : "}
                              {parseFloat(OrderInfo[0].safee, 2)}
                              {" جنية "}
                            </small>
                            <br />
                            <span className="fw-bold">
                              {" المبلغ المستحق : "}
                              {parseFloat(OrderInfo[0].items_price, 2) +
                                parseFloat(OrderInfo[0].safee, 2)}
                              {" جنية "}
                            </span>
                          </label>
                        </div>
                        <div className="text-start w-100">
                          <img
                            src={API.Images + msg.message}
                            alt=""
                            style={{
                              width: "80%",
                              maxHeight: "300px",
                              border: "2px solid #7FD7EB",
                              marginTop: "10px",
                              borderRadius: "10px",
                              padding: "5px",
                            }}
                          />
                          <br />

                          <i
                            style={{ fontSize: "12px" }}
                            className={`fa-solid fa-check  ${class_show}`}
                          ></i>
                          <small
                            dir="ltr"
                            className="mb-4 px-1"
                            style={{ fontSize: "12px" }}
                          >
                            {created_at}
                          </small>
                          <i
                            style={{ fontSize: "12px" }}
                            className={`fa-solid fa-user  text-info`}
                          ></i>
                          <br />
                        </div>
                      </>
                    )}
                    {msg.type == "text" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <label
                              className={`${class_} p-0 p-2 m-1 rounded shadow`}
                            >
                              {msg.message}
                            </label>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {msg.type == "uploadImage" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <img
                              src={API.Images + msg.message}
                              alt=""
                              style={{
                                width: "80%",
                                maxHeight: "300px",
                                border: "2px solid #7FD7EB",
                                marginTop: "10px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            />
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            {/* <small className="fw-bold">
                            {change_first_word(receiver_name)}
                          </small> */}
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}

                    {msg.type == "audio" && (
                      <>
                        {msg.sender_id === OrderInfo[0].delevary_id ? (
                          <div className="text-start w-100">
                            <audio
                              controls
                              src={API.Images + msg.message}
                            ></audio>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-check  ${class_show}`}
                            ></i>

                            {/* <small className="fw-bold"> أنا </small> */}
                            <small
                              dir="ltr"
                              className="mb-4 px-1"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-info`}
                            ></i>
                            <br />
                          </div>
                        ) : (
                          <>
                            <audio
                              controls
                              src={API.Images + msg.message}
                            ></audio>
                            <br />
                            <i
                              style={{ fontSize: "12px" }}
                              className={`fa-solid fa-user  text-secondary`}
                            ></i>
                            <small
                              dir="ltr"
                              className="mb-4"
                              style={{ fontSize: "12px" }}
                            >
                              {created_at}
                            </small>
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
              {/* العنصر المخفي للتمرير التلقائي */}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-buttom">
              {isRecording && (
                <div className="recording-animation">
                  <span>🔴 جاري التسجيل...</span>
                </div>
              )}
              <label className="text-chat-div mx-1">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="اكتب رسالتك هنا..."
                  className="textchat"
                />
                <i
                  className="fa-solid fa-paper-plane ms-1"
                  onClick={() => {
                    setmsgType("text");
                    sendMessage();
                  }}
                ></i>
              </label>
              <i
                className={`fa-solid fa-microphone mx-2 fs-3 ${
                  isRecording ? "text-danger" : "text-syan"
                }`}
                onClick={() => Recording()}
                // onMouseDown={() => startRecording()}
                // onMouseUp={() => stopRecording()}
                // onTouchStart={() => startRecording()}
                // onTouchEnd={() => stopRecording()}
              ></i>
              <i
                className={`fa-solid fa-camera mx-2 fs-3 ${
                  isRecording ? "text-danger" : "text-syan"
                }`}
                onClick={() => uploadImage()}
              ></i>
            </div>
          </div>

          {/* <Link to={"../Deller/OrderInfo/" + OrderInfo[0].number_order}>
            <label className="btn-review-order"> تحقق من تفاصيل الطلب</label>
          </Link> */}
        </div>
      </div>
      <List />
    </>
  );

  //===============================

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModalHelep}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h5 className="text-danger text-center p-0 m-0">
                  {"كيف يمكننا مساعدتك "}
                </h5>
                <hr />
                <h5
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                  onClick={() => handleChange("اين طلبى")}
                >
                  اين طلبى
                </h5>
                <h5
                  onClick={() => handleChange("الطلب اتاخر كتير ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1 "
                >
                  {"الطلب اتاخر كتير "}
                </h5>
                <h5
                  onClick={() => handleChange("الغاء الطلب لست بحاجة لة ")}
                  style={{ fontSize: "15px" }}
                  className="bg-light text-center p-1"
                >
                  {"الغاء الطلب لست بحاجة لة "}
                </h5>
                <button
                  style={{ fontSize: "14px" }}
                  onClick={() => handelHelp()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  function Help__() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row mt-0 px-0">
              <div className="col-12 text-center">
                <>
                  <h4
                    onClick={() =>
                      navigate(`../CreateFatora/` + OrderInfo[0].number_order)
                    }
                    className="text-green list p-2 mt-4"
                  >
                    {"  اصدار فاتورة "}
                  </h4>

                  <h4
                    onClick={() =>
                      navigate(
                        "../Deller/OrderInfo/" + OrderInfo[0].number_order
                      )
                    }
                    className="text-green list p-2"
                  >
                    توصيل الطلب
                  </h4>

                  <h4
                    onClick={() => EditeOrder("حذف")}
                    className="text-green list p-2"
                  >
                    المساعدة
                  </h4>
                  <h4 onClick={() => handleCloseModal()} className=" list p-2">
                    تراجع
                  </h4>
                </>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  function List() {
    return (
      <Modal show={ShowModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="row mt-0 px-0">
            <div className="col-12 text-center">
              <>
                <h4
                  onClick={() =>
                    navigate(`../CreateFatora/` + OrderInfo[0].number_order)
                  }
                  className="text-green list p-2 mt-4"
                >
                  {"  اصدار فاتورة "}
                </h4>

                <h4
                  onClick={() =>
                    navigate("../Deller/OrderInfo/" + OrderInfo[0].number_order)
                  }
                  className="text-green list p-2"
                >
                  توصيل الطلب
                </h4>

                <h4
                  onClick={() =>
                    navigate("../DellerCHatShkawa/" + params.number_order)
                  }
                  className="text-green list p-2"
                >
                  المساعدة
                </h4>
                <h4 onClick={() => handleCloseModal()} className=" list p-2">
                  تراجع
                </h4>
              </>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
