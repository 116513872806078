import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import { API, MAPS_API_KEY } from "../../Values";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Iconlocation1 from "../../../Images/location1.png";
import Iconlocation2 from "../../../Images/location2.png";
import Iconlocation3 from "../../../Images/location3.png";
import LoginCheck from "../../LoginCheck/page";
import axios from "axios";

import { Modal, Button, Dropdown } from "react-bootstrap";
import {
  Reviews0,
  Reviews1,
  Reviews2,
  Reviews3,
  Reviews4,
  Reviews5,
} from "../../Reviews/page";
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 30.033,
  lng: 31.233,
};

export default function DellerOrderInfo() {
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const [Reviews, SetReviews] = useState("0");
  const [LoginType, SetLoginType] = useState("");
  //---------CHECK LOGIN------------------------------
  LoginCheck();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  useEffect(() => {
    if (Cookies.get("userinfo")) {
      userinfo = JSON.parse(Cookies.get("userinfo"));
      Username = userinfo[0].Name;
      UserID = userinfo[0].ID;
    }

    if (Cookies.get("LoginType")) {
      const LoginType_ = Cookies.get("LoginType");
      console.log(Cookies.get("LoginType"));
      SetLoginType(LoginType_);
    }
  }, []);

  const [OrderInfo, setOrderInfo] = useState([
    {
      statue: "",
      type_order: "",
      number_order: "",
      date_add: "",
    },
  ]);
  const [offer, setoffer] = useState("");
  const [pickupLocation, setPickupLocation] = useState(center); // نقطة الاستلام
  const [PickupName, setPickupName] = useState("");
  const [deliveryLocation, setDeliveryLocation] = useState(center);
  const [deliveryName, setDeliveryName] = useState("");
  const [curentLocation, setCurentLocation] = useState(null);

  const [CheckLocation, SetCheckLocation] = useState(0);

  const [PlaceName, setPlaceName] = useState(null);
  const [delevary_distanse_start, setdelevary_distanse_start] = useState("0");
  const [isLoaded, setIsLoaded] = useState(false); // حالة تحميل الخريطة
  const [distance, setDistance] = useState("0"); // المسافة بين نقطتي الاستلام والتسليم
  const [map, setMap] = useState(null);
  const searchInputRef = useRef();
  const autocompleteRef = useRef();
  const navigate = useNavigate();
  const libraries = useMemo(() => ["places", "geometry"], []);
  const [Golatitude, setGolatitude] = useState(null); // نقطة الاستلام
  const [Golongitude, setGolongitude] = useState(null);
  useEffect(() => {
    GetOrderInfo();
  }, []);
  //-------------------------------------------------------------
  async function GetOrderInfo() {
    try {
      const post = {
        number_order: params.order,
        Action: "OrderInfo",
      };
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await response.json();
      // console.log(body);
      setOrderInfo(body);
      setPickupLocation({
        lat: parseFloat(body[0].from_latitude),
        lng: parseFloat(body[0].from_longitude),
      });
      setDeliveryLocation({
        lat: parseFloat(body[0].to_latitude),
        lng: parseFloat(body[0].to_longitude),
      });
      setGolatitude(parseFloat(body[0].from_latitude));
      setGolongitude(parseFloat(body[0].from_longitude));
      setGolatitude(parseFloat(body[0].to_latitude));
      setGolongitude(parseFloat(body[0].to_longitude));
    } catch (error) {
      return error;
    }
  }

  const [error, setError] = useState(null);
  const [locationMessage, setLocationMessage] = useState(""); // لحفظ الرسائل

  const [intervalActive, setIntervalActive] = useState(true);
  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    GetMyLocation();
  }, []);

  useEffect(() => {
    if (intervalActive) {
      fetchIntervalRef.current = setInterval(() => {
        if (ShowModal) {
          return;
        }
        GetMyLocation();
        SetCheckLocation(parseInt(CheckLocation) + parseInt(1));
      }, 1000);
    }
    return () => clearInterval(fetchIntervalRef.current);
  }, [intervalActive, curentLocation]);

  function GetMyLocation() {
    const savedCurentLocation = JSON.parse(
      localStorage.getItem("CurentLocation")
    );
    if (savedCurentLocation) {
      setCurentLocation(savedCurentLocation.location);
      setPlaceName(savedCurentLocation.name);
      calculateDistance(pickupLocation, savedCurentLocation.location);
    }
  }

  const calculateDistance0 = (pickup, delivery) => {
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [pickup],
        destinations: [delivery],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        if (status === "OK") {
          const distance_ = response.rows[0].elements[0].distance.text;
          setDistance(distance_); // تخزين المسافة
        } else {
          alert("حدث خطأ أثناء حساب المسافة.");
        }
      }
    );
  };

  // دالة حساب المسافة بين نقطتين
  const calculateDistance = (pickup, delivery) => {
    const R = 6371e3; // نصف قطر الأرض بالأمتار
    const φ1 = (pickup.lat * Math.PI) / 180; // تحويل درجة العرض الأولى إلى راديان
    const φ2 = (delivery.lat * Math.PI) / 180; // تحويل درجة العرض الثانية إلى راديان
    const Δφ = ((delivery.lat - pickup.lat) * Math.PI) / 180; // فرق العرض بالراديان
    const Δλ = ((delivery.lng - pickup.lng) * Math.PI) / 180; // فرق الطول بالراديان

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceInMeters = R * c; // المسافة بالأمتار
    const distanceInKilometers = distanceInMeters / 1000; // المسافة بالكيلومترات

    // تحديد الوحدات وعرض النتيجة
    let result;
    if (distanceInKilometers >= 1) {
      result = `${distanceInKilometers.toFixed(2)} كم`; // إذا كانت المسافة بالكيلومترات
    } else {
      result = `${distanceInMeters.toFixed(0)} م`; // إذا كانت المسافة بالأمتار
    }

    console.log("Distance: " + result);
    setDistance(result); // تخزين المسافة مع الوحدات

    return result; // النتيجة مع الوحدات
  };
  //=========================================================
  //=========================================================
  // تحميل الخريطة وتغيير حالة isLoaded عند الانتهاء
  const handleLoad = () => {
    console.log(`curentLocation`);
    console.log(curentLocation);
    if (pickupLocation) {
      calculateDistance(pickupLocation, curentLocation);
    }
    setIsLoaded(true); // الخريطة أصبحت جاهزة
  };

  //=============================================
  //-------------------------------------------------------------
  async function handelOrderAction() {
    if (window.confirm("هل تريد  تغيير الحالة")) {
      try {
        const post = {
          Name: Username,
          ID: UserID,
          latitude: curentLocation.lat,
          longitude: curentLocation.lng,
          delevary_distanse_start: distance,
          Action: "DellerUpdateOrederStatue",
          statue: OrderInfo[0].statue,
          number_order: OrderInfo[0].number_order,
        };
        // console.log(post);
        const result = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(post),
        });
        const body = await result.json();
        console.log(body);
        if (body.error != "") {
          return alert(body.error);
        }
        localStorage.setItem(
          "Vesanumber_order",
          JSON.stringify({ number_order: OrderInfo[0].number_order })
        );

        GetOrderInfo();
        if (body[0].statue == "جارى التوصيل") {
          handleShowModal();
        }
      } catch (error) {
        return error;
      }
    }
  }

  //---------------------------
  const handleSubmiSubScribe = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد تقييم العميل  `)) {
      try {
        const response = await fetch(API.Orders, {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg != "") {
          console.log(data.msg);
          handleCloseModal();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  // دالة لاختيار الكلمة الأولى من النص
  function change_first_word(inputText) {
    let firstWord = inputText;
    if (inputText) {
      firstWord = inputText.split(" ")[0];
    }
    return firstWord;
  }

  //-----------------------------------------
  const params = useParams();
  const imgstyle = {
    height: "100%",
  };
  let classStatu = "";
  if (OrderInfo[0].statue == "جارى التاكيد") {
    classStatu = "bg-primary";
  }
  if (OrderInfo[0].statue == "تم الموافقة") {
    classStatu = "bg-danger ";
  }
  if (OrderInfo[0].statue == "جارى التنفيذ") {
    classStatu = "bg-orange";
  }

  if (OrderInfo[0].statue == "جارى التوصيل") {
    classStatu = "bg-info";
  }
  if (OrderInfo[0].statue == "تم التوصيل") {
    classStatu = "bg-green";
  }

  return (
    <>
      <div
        className="p-1 row  text-center m-auto "
        style={{ maxWidth: "500px" }}
      >
        <div className="col-12 py-2 text-center bg-light mb-0">
          <span className="fs-6 fw-bold"> {OrderInfo[0].type_order} </span>
          {LoginType == "customers" ? (
            <Link
              to={`../CustomerCHat/${OrderInfo[0].number_order}`}
              className="float-end"
            >
              <i className="fa-solid fa-angle-right mt-0 fs-4 text-info"></i>
            </Link>
          ) : (
            <Link
              to={`../DellerCHat/${OrderInfo[0].number_order}`}
              className="float-end"
            >
              <i className="fa-solid fa-angle-right mt-0 fs-4 text-info"></i>
            </Link>
          )}
        </div>
        <div className="col-7 py-2 bg-light0 mb-0 text-end">
          <i className="fa-solid fa-user fs-1 text-info mx-2"></i>
          <span>{change_first_word(OrderInfo[0].customer_name)} </span>
        </div>

        {OrderInfo[0].statue == "تم التوصيل" && (
          <div className="col-5 py-2 bg-light0 mb-0 text-start">
            <i
              className="text-green fa-solid fa-thumbs-up mx-1"
              onClick={() => handleShowModal()}
            ></i>
          </div>
        )}

        {OrderInfo[0].statue != "تم التوصيل" && (
          <div className="col-5 py-2 bg-light0 mb-0 text-start">
            <Link to={`../DellerCHat/${OrderInfo[0].number_order}`}>
              <button className="btn btn-syan mx-1 fs-6 fw-bold btn-call">
                <i className="fa-solid fa-comment"></i>
              </button>
            </Link>

            <Link to={`tel:${OrderInfo[0].mobail}`} target="_blank">
              <button className="btn btn-green mx-1 fs-6 fw-bold btn-call">
                <i className="fa-solid fa-phone"></i>
              </button>
            </Link>
          </div>
        )}

        <div
          style={{ borderTop: "1px solid #ccc" }}
          className="col-12 py-1 mb-0 text-end"
        >
          <div
            style={{ borderBottom: "1px solid #ccc" }}
            className="row p-0 m-0"
          >
            <div className="col-9 p-0 m-0">
              <small className="fw-bold"> تفاصيل الطلب</small>
              <br />
              <span>{OrderInfo[0].items} </span>
            </div>
            <div className="col-3 text-center p-0">
              <small dir="ltr" className="text-secondary">
                #{OrderInfo[0].number_order}
              </small>
              <br />
              <small className={`px-1 rounded text-white ${classStatu}`}>
                {OrderInfo[0].statue}
              </small>
            </div>
            <div className="col-12 p-0">
              <small className="mx-2">
                سعر الطلب : {OrderInfo[0].items_price}{" "}
              </small>
              <small className="mx-2"> التوصيل : {OrderInfo[0].total} </small>

              {/* <small className="mx-2"> : {OrderInfo[0].tax} </small>
              <br /> */}
              <small className="mx-2">الاجمالى : {OrderInfo[0].safee} </small>
            </div>
          </div>
        </div>

        <div className="col-12 p-1 div-mapaddOffers bg-light mb-0">
          <LoadScript
            googleMapsApiKey={MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleLoad} // تعيين isLoaded على true بعد تحميل المكتبة
          >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{
                  lat: deliveryLocation.lat,
                  lng: deliveryLocation.lng,
                }}
                zoom={1} // بدء التكبير من المستوى 1
                onLoad={(mapInstance) => {
                  // البدء بتكبير الخريطة تدريجياً
                  let currentZoom = 1; // مستوى التكبير المبدئي
                  const maxZoom = 14; 
                  const interval = setInterval(() => {
                    if (currentZoom < maxZoom) {
                      currentZoom += 1;
                      mapInstance.setZoom(currentZoom); // ضبط مستوى التكبير
                    } else {
                      clearInterval(interval); // إيقاف المؤقت عند الوصول إلى المستوى المطلوب
                    }
                  }, 50); // تحديث كل ثانية (يمكنك تغيير المدة حسب الحاجة)
                }}
              >
                {curentLocation && (
                  <Marker
                    position={curentLocation}
                    icon={{
                      url: Iconlocation1,
                      scaledSize: new window.google.maps.Size(50, 30),
                    }}
                    label={{
                      text: "أنا",
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  />
                )}

                {pickupLocation && (
                  <Marker
                    position={pickupLocation}
                    icon={{
                      url: Iconlocation2,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    label={{
                      text: "استلام",
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  />
                )}
                {deliveryLocation && (
                  <Marker
                    position={deliveryLocation}
                    icon={{
                      url: Iconlocation3,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    label={{
                      text: "تسليم",
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  />
                )}
              </GoogleMap>
            ) : (
              <div>الخريطة في مرحلة التحميل... يرجى الانتظار</div>
            )}
          </LoadScript>
        </div>

        <div className="col-6 py-2  mb-0 text-end">
          <Link
            to={`https://www.google.com/maps?q=${pickupLocation.lat},${pickupLocation.lng}`}
          >
            <i className="fa-solid fa-home bg-green text-white p-1 mx-1"></i>
            <span>{distance} </span>
            <br />
            <small className="fw-bold">موقع الاستلام</small>
            <br />
            <small>{OrderInfo[0].from_plase} </small>
          </Link>
        </div>

        <div className="col-6 py-1 mb-0 text-end">
          <Link
            to={`https://www.google.com/maps?q=${deliveryLocation.lat},${deliveryLocation.lng}`}
          >
            <i className="fa-solid fa-flag bg-info text-white p-1 mx-1"></i>
            <span>{OrderInfo[0].distance} </span>
            <br />
            <small className="fw-bold"> التوصيل</small>
            <br />
            <small>{OrderInfo[0].to_plase} </small>
          </Link>
        </div>

        <div className="col-12 text-center mt-4">
          {CheckLocation < 3 ? (
            <div className="col-12 text-center">
              <img
                src="../../../Images/loding2.gif"
                alt=""
                style={{ width: "50px" }}
              />
              <h5>جارى العثور على موقعك الحالى</h5>
            </div>
          ) : (
            OrderInfo[0].statue != "تم التوصيل" && (
              <>
                <small className="text-syan mb-4" style={{ fontSize: "13px" }}>
                  {PlaceName}
                </small>
                <br />
                <h6
                  onClick={() => handelOrderAction()}
                  className="w-75 btn btn-green p-2 mt-4"
                >
                  تغيير الحالة
                </h6>
              </>
            )
          )}
        </div>
      </div>
      <CustomerLike />
    </>
  );

  function CustomerLike() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <h6 className="text-danger" onClick={handleCloseModal}>
              X
            </h6>
            <>
              <form method="POST" onSubmit={handleSubmiSubScribe}>
                <input
                  type="hidden"
                  name="NameID"
                  value={OrderInfo[0].number_order}
                />
                <input type="hidden" name="Action" value="CustomerLike" />
                <input type="hidden" name="Reviews" value={Reviews} />
                <div className="row mt-4 px-4">
                  <div className="col-12 text-center mb-4">
                    <h4 className="text-green"> تقييم العميل </h4>
                    <h6> التقييم : {Reviews}</h6>
                  </div>

                  <div className="col-12 text-center">
                    <button
                      className="btn btn-warning px-2 me-1"
                      onClick={() => SetReviews("0")}
                    >
                      0
                    </button>
                    <button
                      className="btn btn-warning px-2 me-1"
                      onClick={() => SetReviews("1")}
                    >
                      1
                    </button>
                    <button
                      className="btn btn-warning px-2 me-1"
                      onClick={() => SetReviews("2")}
                    >
                      2
                    </button>
                    <button
                      className="btn btn-warning px-2 me-1"
                      onClick={() => SetReviews("3")}
                    >
                      3
                    </button>
                    <button
                      className="btn btn-warning px-2 me-1"
                      onClick={() => SetReviews("4")}
                    >
                      4
                    </button>
                    <button
                      className="btn btn-warning px-2 me-1"
                      onClick={() => SetReviews("5")}
                    >
                      5
                    </button>
                  </div>
                  <div className="col-12 text-center mx-2 mt-4 ">
                    <button className="btn btn-danger fw-bold fs-6 w-100">
                      تقييم
                    </button>
                  </div>

                  <br />
                </div>
              </form>
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
